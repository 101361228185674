import { connect } from 'react-redux';

import { ConsentsContainer } from 'Component/Consents/Consents.container';
import {
    ContactFormContainer as SourceContactFormContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/ContactForm/ContactForm.container';

export { mapStateToProps, mapDispatchToProps };

/** @namespace Pwa/Component/ContactForm/Container/ContactFormContainer */
export class ContactFormContainer extends SourceContactFormContainer {
    onFormSubmit(form, fields) {
        const { sendMessage } = this.props;
        const filteredFields = {};
        const consents = ConsentsContainer.getAllConsents(fields);
        const acceptedConsents = ConsentsContainer.getAcceptedConsents(fields);

        fields.forEach(({ name, value }) => {
            if (consents?.includes(name)) {
                return null;
            }

            filteredFields[name] = value;
        });

        const input = {
            ...filteredFields,
            consentIds: acceptedConsents,
        };

        sendMessage({ form, fields: input });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactFormContainer);
