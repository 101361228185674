import { SORT_DIRECTION_TYPE } from 'Route/CategoryPage/CategoryPage.config';
import { ALL_PRODUCTS } from 'Route/SearchingPage/SearchingPage.config';
import { NONE_SORT_OPTION_VALUE } from 'Route/SearchPage/SearchPage.config';
import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { isCrawler } from 'Util/Browser/Browser';
import BrowserDatabase from 'Util/BrowserDatabase';
import { Field, Fragment } from 'Util/Query';
/** @namespace Pwa/Query/ProductList/Query/ProductListQuery */
export class ProductListQuery extends SourceProductListQuery {
    getQuery(options) {
        if (!options) {
            throw new Error('Missing argument `options`');
        }

        this.options = options;

        return this._getProductsField();
    }

    _getProductsField() {
        const { alias, addedCategories = false } = this.options;

        const products = new Field('products').addFieldList(this._getProductFields());

        if (alias) {
            products.setAlias(alias);
        }

        this._getProductArguments().forEach((arg) => products.addArgument(...arg));

        if (addedCategories) {
            products.addArgument('autocomplete', 'Boolean', true);
        }

        return products;
    }

    _getItemsField() {
        const { isSingleProduct, isSearchbar = false } = this.options;

        if (isSearchbar) {
            return new Field('items').addFieldList(this._getProductSearchbarFields());
        }

        const items = new Field('items').addFieldList(this._getProductInterfaceFields());

        if (isSingleProduct) {
            items.addField(this._getDownloadableProductFields());
        } else {
            items.addField(this._getDownloadableProductLinksRequired());
        }

        return items;
    }

    _getProductFields() {
        const { requireInfo, isSingleProduct, notRequireInfo, onlyTotalCount, addedCategories = false } = this.options;

        if (onlyTotalCount) {
            return ['total_count'];
        }

        // do not request total count for PDP
        if (isSingleProduct || notRequireInfo) {
            return [this._getItemsField()];
        }

        // for filters only request
        if (requireInfo) {
            return [this._getSortField(), this._getAggregationsField()];
        }

        if (addedCategories) {
            return ['total_count', this._getItemsField(), this._getPageInfoField(), this._getCategoriesField()];
        }

        return ['total_count', this._getItemsField(), this._getPageInfoField()];
    }

    _getProductSearchbarFields() {
        return ['uid', 'id', 'sku', 'name', 'type_id', 'url', this._getProductThumbnailField()];
    }

    _getFilterArgumentMap() {
        return {
            ...super._getFilterArgumentMap(),
            productsIDArray: (id) => ({ id: { in: id } }),
            builtProducts: (condition) => ({ type_id: { [condition]: 'built' } }),
        };
    }

    _getArgumentsMap() {
        const { requireInfo } = this.options;
        const filterArgumentMap = this._getFilterArgumentMap();

        return {
            currentPage: { type: 'Int!' },
            pageSize: {
                type: 'Int!',
                handler: (option) => (requireInfo ? 1 : option),
            },
            search: {
                type: 'String!',
                handler: (option) => {
                    if (option === ALL_PRODUCTS) return '';
                    return option.replace(/\+/g, ' ');
                },
            },
            sort: {
                type: 'ProductAttributeSortInput!',
                handler: ({ sortKey, sortDirection }) => {
                    if (sortKey === NONE_SORT_OPTION_VALUE) {
                        return {};
                    }

                    return { [sortKey]: sortDirection || SORT_DIRECTION_TYPE.asc };
                },
            },
            filter: {
                type: 'ProductAttributeFilterInput!',
                handler: (initialOptions = {}) => {
                    // add customer group by default to all requests
                    const { group_id } = BrowserDatabase.getItem(CUSTOMER) || {};

                    const options = {
                        ...initialOptions,
                        customerGroupId: group_id || '0',
                    };

                    const { customFilters: { category_id } = {} } = options;

                    /**
                     * Remove category ID from select, if there is a custom filter
                     * of category already selected in filtering options.
                     */
                    if (category_id) {
                        // eslint-disable-next-line fp/no-delete
                        options.categoryIds = undefined;
                    }

                    const parsedOptions = Object.entries(options).reduce((acc, [key, option]) => {
                        // if there is no value, or if the key is just not present in options object
                        if (!option || !filterArgumentMap[key]) {
                            return acc;
                        }

                        return { ...acc, ...filterArgumentMap[key](option) };
                    }, {});

                    return parsedOptions;
                },
            },
        };
    }

    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const {
            isPlp = false,
            isSingleProduct,
            isArrangementCategory = false,
            isArrangementWidget = false,
            isCategoryPage = false,
            isWithoutHotspotsWidget = false,
            onlyAttributesAndReviews = false,
            noAttributes = false,
            noProductUnit = false,
            noSalableQty = false,
            noVariantAttributes = false,
            noVariants = false,
            noVpItems = false,
        } = this.options;

        // set option to always request images for product variants if they're requested for wishlist
        if (isForWishlist) {
            this.options.isForWishlist = true;
        }

        if (onlyAttributesAndReviews && !isForWishlist) {
            return [this._getAttributesField(), this._getReviewsField()];
        }

        // Basic fields returned always
        const fields = [
            'uid',
            'id',
            'sku',
            'name',
            'listing_name',
            'type_id',
            'stock_status',
            !noSalableQty && 'salable_qty',
            'lowest_price',
            'deliverer_available',
            'total_saves',
            'url',
            !noProductUnit && 'product_unit',
            !noVpItems && 'vp_items',
            'discount_display',
            'show_as_set',
            this._getStockItemField(),
            this._getStockAvailability(),
            this._getPriceRangeField(),
            this._getLabelsField(),
        ];

        if (isSingleProduct || (isPlp && !isArrangementWidget && !isArrangementCategory && !isCrawler())) {
            fields.push(this._getGroup());
        }

        if (isPlp) {
            fields.push(
                'special_from_date',
                'special_to_date',
                this._getAlternativeHoverImage(),
                this._getRecommendationImage(),
                this._getProductSmallField(),
                this._getProductImageField(),
                this._getRatingSummaryField(),
                this._getReviewCountField()
            );
            if (!isVariant) {
                if (!noVariants) {
                    fields.push(this._getBuiltProductItems(!isArrangementWidget));
                }
            }

            if (isArrangementWidget && isWithoutHotspotsWidget) {
                fields.push(
                    this._getBuiltProductImageField('built_product_big_image'),
                    this._getBuiltProductImageField('built_product_small_image'),
                    this._getBuiltProductImageField('built_product_small_image_2')
                );
            }

            if (!isCategoryPage) {
                fields.push(this._getShortDescriptionField());
            }

            return fields;
        }

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (!(isPlp && isVariant) || isForWishlist) {
            fields.push(
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date',
                'glue_calculator',
                'glue_volume',
                'is_virtual_design',
                this._getAlternativeHoverImage(),
                this._getTierPricesField(),
                this._getCheapestDelivery(),
                this._getAttachments(),
                this._getRelatedBuiltProductIds()
            );
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                this._getUrlRewritesFields(),
                this._getReviewCountField(),
                this._getRatingSummaryField(),
                this._getCustomizableProductFragment()
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment(),
                    this._getGroupedProductItems(),
                    this._getBuiltProductItems()
                );
            }
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                this._getSocialProofNotifications(),
                this._getRelatedBuiltProductIds(),
                this._getGroup(),
                this._getInstalmentField()
            );

            if (!noAttributes) {
                fields.push(this._getAttributesField());
            }

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    'canonical_url',
                    'meta_title',
                    'meta_keyword',
                    'meta_description',
                    'advantages',
                    this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment(),
                    this._getProductLinksField()
                );
            }
        }

        return fields;
    }

    _getAlternativeHoverImage() {
        const { noAlternativeHoverImage = false } = this.options;

        if (noAlternativeHoverImage) {
            return null;
        }

        return new Field('alternative_hover_image').addFieldList(super._getProductThumbnailFields());
    }

    _getCategoriesField() {
        const { noCategories = false } = this.options;

        if (noCategories) {
            return null;
        }

        return new Field('categories').addFieldList(this._getCategoryFields());
    }

    _getRecommendationImage() {
        return new Field('recommandation_image').addFieldList(super._getProductThumbnailFields());
    }

    _getLabelsField() {
        return new Field('labels').addFieldList(this._getLabelsFields());
    }

    _getLabelsFields() {
        return ['code', 'label_type', 'image', 'url', 'class', 'text', 'text_color', 'text_background_color'];
    }

    _getDiscountDisplayView() {
        const fields = new Field('discount_display_view').addFieldList(this._getDiscountDisplayViewFields());

        return fields;
    }

    _getDiscountDisplayViewFields() {
        return ['discount_type', 'fixed_discount', 'fixed_price_text', 'percentage_discount'];
    }

    _getQuantityAlertField() {
        return new Field('quantityAlert').addFieldList(this._getQuantityAlertFields());
    }

    _getQuantityAlertFields() {
        return ['alertActive', 'alertMessage'];
    }

    _getCartProductInterfaceFields() {
        return [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'url',
            'salable_qty',
            'deliverer_available',
            'product_unit',
            'vp_items',
            this._getCategoriesField(),
            this._getCheapestDelivery(),
            this._getStockItemField(),
            this._getProductThumbnailField(),
            this._getCartConfigurableProductFragment(),
            this._getAttributesField(false, true),
            this._getProductLinksField(),
            this._getQuantityAlertField(),
            this._getPriceRangeField(),
        ];
    }

    _getStockAvailability() {
        return new Field('stock_availability').addFieldList(this._getStockAvailabilityFields());
    }

    _getStockAvailabilityFields() {
        return ['label', 'qty'];
    }

    _getAggregationsFields() {
        return [
            new Field('label').setAlias('name'),
            new Field('attribute_code').setAlias('request_var'),
            'is_boolean',
            'has_swatch',
            'position',
            'count',
            'frontend_input',
            'frontend_class',
            'slider_display_pattern',
            'facet_max_size',
            this._getAggregationsOptionsField(),
        ];
    }

    _getRelatedBuiltProductIds() {
        return new Field('related_built_product_ids');
    }

    _getAttachments() {
        return new Field('attachments').addFieldList(this._getAttachmentsFields());
    }

    _getAttachmentsFields() {
        return ['name', 'description', 'url'];
    }

    _getAttributeFields(isVariant = false, isCart = false) {
        return [
            'attribute_id',
            'attribute_value',
            'attribute_code',
            'attribute_label',
            'searchable_pdp',
            ...this._getAdditionalAttributeFields(isCart),
            ...this._getAttributeOptionsFields(isVariant),
        ];
    }

    _getProductLinksField() {
        const { noProductLinks = false } = this.options;

        if (noProductLinks) {
            return null;
        }

        return new Field('product_links').addFieldList(this._getProductLinkFields());
    }

    _getReviewsField() {
        return (
            new Field('reviews')
                // Hard-coded pages, it will be very hard to
                // paginate using current implementation
                // eslint-disable-next-line no-magic-numbers
                .addArgument('pageSize', 'Int', 3)
                .addArgument('currentPage', 'Int', 1)
                .addFieldList(this._getReviewsFields())
        );
    }

    _getGroup() {
        const { noGroup = false } = this.options;

        if (noGroup) {
            return null;
        }

        return new Field('group').addFieldList([this._getProductOptionsField(), this._getProductVariantsField()]);
    }

    _getProductOptionsField() {
        return new Field('options').addFieldList(this._getProductOptions());
    }

    _getProductOptions() {
        return ['attribute_code', this._getProductOptionValuesField()];
    }

    _getProductOptionValuesField() {
        return new Field('values').addFieldList(this._getProductOptionsValues());
    }

    _getProductOptionsValues() {
        return ['label', 'value'];
    }

    _getProductVariantsField() {
        const { isPlp, variantFilters } = this.options;

        const field = new Field('variants').addFieldList([
            this._getCurrentProductDataFields(),
            this._getCurrentProductAttributesFields(),
        ]);

        if (isPlp && variantFilters?.variantsByParentColor) {
            field.addArgument('variantsByParentColor', 'Boolean', true);
        }

        return field;
    }

    _getCurrentProductDataFields() {
        return new Field('product').addFieldList(this._getCurrentProductData());
    }

    _getCurrentProductAttributesFields() {
        return new Field('attributes').addFieldList(this._getCurrentProductAttributes());
    }

    _getCurrentProductData() {
        return ['id', 'url', 'thumbnail'];
    }

    _getCurrentProductAttributes() {
        return ['attribute_code', 'attribute_label', 'label', 'frontend_input', 'is_swatch', 'swatch_value'];
    }

    _getCheapestDelivery() {
        return new Field('cheapest_delivery_info').addFieldList(this._getCheapestDeliveryFields());
    }

    _getCheapestDeliveryFields() {
        return ['shipping_code', 'shipping_name', 'shipping_price'];
    }

    _getCustomFilters(filters = {}) {
        return Object.entries(filters).reduce((acc, [key, attribute]) => {
            if (!attribute.length) {
                return acc;
            }

            const isPriceRange = attribute[0].includes('_');

            if (isPriceRange) {
                return {
                    ...acc,
                    ...this._getPriceFilter(key, attribute),
                };
            }

            if (key === 'weight' || key === 'text' || key === 'textarea' || key === 'seria') {
                return {
                    ...acc,
                    [key]: { match: attribute[0] },
                };
            }

            return {
                ...acc,
                [key]: { in: attribute },
            };
        }, {});
    }

    _getProductImageField() {
        return new Field('image').addFieldList(this._getProductThumbnailFields());
    }

    _getProductThumbnailFields() {
        return Array.from(super._getProductThumbnailFields());
    }

    _getProductImageHotspotsFields() {
        return [
            'is_default',
            'position_x',
            'position_y',
            'product_name_for_listing',
            'short_description',
            'small_image',
            'product_url',
            'lowest_price',
            'regular_price',
            'final_price',
            'product_unit',
            this._getDiscountDisplayView(),
        ];
    }

    _getBuiltProductItems(withoutItems = false) {
        return new Fragment('BuiltProduct').addFieldList(this._getBuiltProductItemsFields(withoutItems));
    }

    _getBuiltProductItemsFields(withoutItems) {
        const { isPlp = false, isWithoutHotspotsWidget = false } = this.options;

        return [
            !isPlp && 'built_discount',
            !withoutItems && this._getBuiltProductItem(),
            !isWithoutHotspotsWidget && this._getTagsField(),
            ...(isPlp ? [] : [this._getDesignerField()]),
        ];
    }

    _getBuiltProductItem() {
        return new Field('items').addFieldList(this._getBuiltProductItemFields());
    }

    _getBuiltProductItemFields() {
        const { isPlp = false } = this.options;

        return [
            'position',
            'qty',
            'required',
            ...(isPlp ? [] : [this._getBuiltSubstitutesField()]),
            this._getArrangementProductField(),
        ];
    }

    _getBuiltSubstitutesField() {
        return new Field('substitutes').addFieldList(this._getBuiltSubstitutesFields());
    }

    _getBuiltSubstitutesFields() {
        return ['id', this._getPriceRangeField()];
    }

    _getArrangementProductField() {
        return new Field('product').addFieldList(this._getArrangementProductInterfaceFields());
    }

    _getArrangementProductInterfaceFields() {
        const { isPlp = false } = this.options;

        if (isPlp) {
            return [
                'product_unit',
                'vp_items',
                'product_unit',
                'vp_items',
                'name',
                'url',
                this._getPriceRangeField(),
                this._getProductThumbnailField(),
            ];
        }

        return [
            'uid',
            'id',
            'sku',
            'name',
            'listing_name',
            'stock_status',
            'salable_qty',
            'deliverer_available',
            'product_unit',
            'vp_items',
            'url',
            'glue_calculator',
            'glue_volume',
            'type_id',
            'show_as_set',
            this._getStockAvailability(),
            this._getPriceRangeField(),
            this._getLabelsField(),
            this._getProductThumbnailField(),
        ];
    }

    _getBuiltProductImageField(type) {
        return new Field(type).addFieldList(super._getProductThumbnailFields());
    }

    _getDesignerField() {
        return new Field('designer').addFieldList(this._getDesignerFields());
    }

    _getDesignerFields() {
        return ['entity_id', 'first_name', 'last_name', 'image', 'description'];
    }

    _getTagsField() {
        return new Field('tags').addFieldList(this._getTagsFields());
    }

    _getTagsFields() {
        return ['attribute_code'];
    }

    _getSocialProofNotifications() {
        return new Field('domneo_notification').addFieldList(this._getSocialProofNotificationsFields());
    }

    _getSocialProofNotificationsFields() {
        return ['bought_qty', 'date_last_purchase', 'popularity', 'qty_left'];
    }

    _getMinimalPriceFields() {
        return [this._getDiscountField(), this._getFinalPriceField(), this._getRegularPriceField()];
    }

    _getCategoryFields() {
        const { isPlp, isSearchbar = false } = this.options;

        if (isPlp || isSearchbar) {
            return ['name', 'url'];
        }

        return ['id', 'name', 'url', this._getBreadcrumbsField()];
    }

    getImageHotspots(imagePath) {
        if (!imagePath) {
            return null;
        }

        return new Field('getHotspotsByImagePath')
            .addArgument('path', 'String', imagePath)
            .addFieldList(this._getProductImageHotspotsFields());
    }

    _getInstalmentField() {
        const { noInstallment = false } = this.options;

        if (noInstallment) {
            return null;
        }

        return new Field('instalment').addFieldList(this._getInstalmentFields());
    }

    _getInstalmentFields() {
        return ['value', 'currency'];
    }
}

export default new ProductListQuery();
