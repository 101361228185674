/* eslint-disable prettier/prettier */
/* eslint-disable indent */

import isArray from 'lodash/isArray';
import { connect } from 'react-redux';

import {
  mapDispatchToProps as sourceMapDispatchToProps,
  mapStateToProps as sourceMapStateToProps,
  MetaContainer as SourceMetaContainer,
} from 'SourceComponent/Meta/Meta.container';

/** @namespace Pwa/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
  ...sourceMapStateToProps(state),
  lang: state.MetaReducer.lang,
  hreflangs: state.UrlRewritesReducer?.hreflangs,
  alternateLinks: state.ConfigReducer?.alternate_links,
  product_category: state.MetaReducer.product_category,
  product_original_price_amount:
    state.MetaReducer.product_original_price_amount,
  product_original_price_currency:
    state.MetaReducer.product_original_price_currency,
  product_retailer_part_no: state.MetaReducer.product_retailer_part_no,
  synerise_enable: state.ConfigReducer.synerise_enable,
  synerise_opengraph: state.ConfigReducer.synerise_opengraph,
});

/** @namespace Pwa/Component/Meta/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
  ...sourceMapDispatchToProps(dispatch),
});

/** @namespace Pwa/Component/Meta/Container/MetaContainer */
export class MetaContainer extends SourceMetaContainer {
  static propTypes = {
    ...this.propTypes,
  };

  static defaultProps = {
    ...this.defaultProps,
  };

  _generateMetaFromMetadata(metadata, param = 'name') {
    return Object.entries(metadata).reduce((acc, [key, value]) => {
      if (isArray(value)) {
        return [...acc, { [param]: key, content: value }];
      }

      return value ? [...acc, { [param]: key, content: `${value}` }] : acc;
    }, []);
  }

  _getLang() {
    const { lang } = this.props;

    return lang;
  }

  _getOriginalPriceAmount = () => this.props.product_original_price_amount;

  _getOriginalPriceCurrency = () => this.props.product_original_price_currency;

  _getRetailerPartNo = () => this.props.product_retailer_part_no;

  _getProductCategory = () => this.props.product_category;

  _getMetadata() {
    const { synerise_enable, synerise_opengraph } = this.props;

    // eslint-disable-next-line fp/no-let
    let syneriseMeta = {};

    if (synerise_enable && synerise_opengraph) {
      syneriseMeta = {
        'product:original_price:amount': this._getOriginalPriceAmount(),
        'product:original_price:currency': this._getOriginalPriceCurrency(),
        'product:retailer_part_no': this._getRetailerPartNo(),
        categories: this._getProductCategory(),
      };
    }

    const meta = {
      title: this._getTitle(),
      description: this._getDescription(),
      keywords: this._getKeywords(),
      robots: this._getRobots(),
      'render:status_code': this._getStatusCode(),
      lang: this._getLang(),
    };

    return [
      ...this._generateMetaFromMetadata(meta),
      ...this._generateMetaFromMetadata(syneriseMeta, 'property'),
    ];
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
