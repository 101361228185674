import { createRef } from 'react';
import { v4 as uuid } from 'uuid';

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM } from 'Component/ChevronIcon/ChevronIcon.config';
import { FieldNumberContainer } from 'Component/FieldNumber/FieldNumber.container';
import Icons from 'Component/Icons';
import { ACCOUNT_VAT_ID, VAT_ID_FIELD_NAME } from 'Component/MyAccountAddressForm/MyAccountAddressForm.config';
import { Field as SourceField } from 'SourceComponent/Field/Field.component';
import { noopFn } from 'Util/Common';
import { isValidVatId } from 'Util/Validator/Validator';

import { FIELD_TYPE } from './Field.config';

import './Field.override.style';

/** @namespace Pwa/Component/Field/Component/Field */
export class Field extends SourceField {
    fieldWrapper = createRef();

    renderDefaultInput() {
        const { type, setRef, attr, events, isDisabled, label, attr: { name } = {} } = this.props;

        return (
            <>
                <input ref={(elem) => setRef(elem)} disabled={isDisabled} type={type} {...attr} {...events} />
                <label block="Field" elem="Label SiblingLabel" htmlFor={name || `input-${type}`}>
                    {label}
                    {this.renderRequiredTag()}
                </label>
            </>
        );
    }

    renderLabel() {
        const { type, label, attr: { name } = {}, mix: { block = '' } = {} } = this.props;

        if (!label || block === 'ProductAttributeValue') {
            return null;
        }

        return (
            <div block="Field" elem="LabelContainer">
                <label block="Field" elem="Label" htmlFor={name || `input-${type}`}>
                    {label}
                    {this.renderRequiredTag()}
                </label>
            </div>
        );
    }

    renderNumber() {
        const {
            attr,
            events,
            setRef,
            isDisabled = false,
            isCartField,
            isCartOverlay,
            isProductWithCalculator,
            productQuantity,
            isRmaProduct,
        } = this.props;

        return (
            <FieldNumberContainer
                attr={attr}
                events={events}
                setRef={setRef}
                isDisabled={isDisabled}
                isCartField={isCartField}
                isCartOverlay={isCartOverlay}
                isProductWithCalculator={isProductWithCalculator}
                productQuantity={productQuantity}
                isRmaProduct={isRmaProduct}
            />
        );
    }

    renderVatErrorMessage() {
        const errorMessage = __('Please enter a valid VAT ID!');

        return (
            <div block="Field" elem="ErrorMessages">
                {this.renderErrorMessage.call(this, errorMessage)}
            </div>
        );
    }

    renderVatIdField() {
        const {
            type,
            validationResponse,
            mix,
            attr: { name, id },
            fieldRef: { value = '' } = {},
        } = this.props;
        const inputRenderer = this.renderMap[type];
        const elementsWithLabel = [
            FIELD_TYPE.checkbox,
            FIELD_TYPE.radio,
            FIELD_TYPE.number,
            FIELD_TYPE.textarea,
            FIELD_TYPE.text,
            FIELD_TYPE.select,
        ];

        return (
            <div
                block="Field"
                elem="Wrapper"
                mods={{
                    type,
                    name,
                    hasError: validationResponse !== true || (validationResponse === true && !isValidVatId(value)),
                }}
            >
                <div
                    block="Field"
                    mods={{
                        type,
                        isValid: validationResponse === true && isValidVatId(value),
                        hasError:
                            (validationResponse !== true && Object.keys(validationResponse || {}).length !== 0) ||
                            (id !== ACCOUNT_VAT_ID && validationResponse === true && !isValidVatId(value)),
                    }}
                    mix={mix}
                >
                    {elementsWithLabel.includes(type) && this.renderLabel()}
                    <div block="Field" elem="InputWrapper">
                        {inputRenderer && inputRenderer()}
                        {(validationResponse !== true && Object.keys(validationResponse || {}).length !== 0) ||
                            (id !== ACCOUNT_VAT_ID && validationResponse === true && !isValidVatId(value) && (
                                <Icons
                                    mix={{
                                        block: 'Field',
                                        elem: 'Icon ErrorIcon',
                                    }}
                                    name="warning"
                                    fill="#BA1B1B"
                                />
                            ))}
                        {validationResponse === true && isValidVatId(value) && (
                            <Icons mix={{ block: 'Field', elem: 'Icon ValidIcon' }} name="tick" fill="#006F00" />
                        )}
                    </div>
                </div>
                {(id !== ACCOUNT_VAT_ID && validationResponse === true && !isValidVatId(value)) ||
                (id === ACCOUNT_VAT_ID && value?.length && !isValidVatId(value))
                    ? this.renderVatErrorMessage()
                    : this.renderErrorMessages()}
                {this.renderSubLabel()}
            </div>
        );
    }

    renderButtonIcon() {
        return <ChevronIcon direction={BOTTOM} />;
    }

    renderShowMoreButton() {
        return (
            <button
                block="Field"
                elem="ShowMore"
                type="button"
                onClick={() => {
                    this.fieldWrapper?.current?.classList?.toggle('visible');
                }}
            >
                {this.renderButtonIcon()}
            </button>
        );
    }

    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr,
            attr: { id = '', name = '' } = {},
            mix: { block = '' } = {},
            events: { onChange },
            events,
            isDisabled,
            label,
            isGDPRPopup,
        } = this.props;

        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn,
        };
        const isConsent = block === 'Consents';
        const elemLabel = `${id || name}`.replace(/[^a-zA-Z ]/g, ' ').trim();
        const needAriaLabel = isConsent || block === 'ProductCard' || block === 'GroupedProductsItem';

        if (isGDPRPopup) {
            return (
                <label htmlFor={elemLabel} block="Field" elem={`${elem}Label`}>
                    <span>{label}</span>
                    {this.renderShowMoreButton()}
                    <input
                        ref={(elem) => setRef(elem)}
                        disabled={isDisabled}
                        type={type}
                        {...attr}
                        {...inputEvents}
                        id={uuid()}
                        name={isConsent || isGDPRPopup ? name : elemLabel}
                        aria-label={needAriaLabel ? 'Checkbox' : ''}
                    />
                    <div block="Field" elem="Slider" />
                </label>
            );
        }

        return (
            <label htmlFor={elemLabel} block="Field" elem={`${elem}Label`}>
                <input
                    ref={(elem) => setRef(elem)}
                    disabled={isDisabled}
                    type={type}
                    {...attr}
                    {...inputEvents}
                    id={uuid()}
                    name={isConsent || isGDPRPopup ? name : elemLabel}
                    aria-label={needAriaLabel ? 'Checkbox' : ''}
                />
                <div block="input-control" />
                {label}
            </label>
        );
    }

    render() {
        const {
            type,
            validationResponse,
            mix,
            attr: { name },
        } = this.props;
        const inputRenderer = this.renderMap[type];
        const elementsWithLabel = [
            FIELD_TYPE.checkbox,
            FIELD_TYPE.radio,
            FIELD_TYPE.number,
            FIELD_TYPE.textarea,
            FIELD_TYPE.text,
            FIELD_TYPE.select,
        ];

        if (name === VAT_ID_FIELD_NAME) {
            return this.renderVatIdField();
        }

        return (
            <div
                block="Field"
                elem="Wrapper"
                mods={{ type, name, hasError: validationResponse !== true }}
                ref={this.fieldWrapper}
            >
                <div
                    block="Field"
                    mods={{
                        type,
                        isValid: validationResponse === true,
                        hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0,
                    }}
                    mix={mix}
                >
                    {elementsWithLabel.includes(type) && this.renderLabel()}
                    <div block="Field" elem="InputWrapper">
                        {inputRenderer && inputRenderer()}
                        {validationResponse !== true && Object.keys(validationResponse || {}).length !== 0 && (
                            <Icons
                                mix={{
                                    block: 'Field',
                                    elem: 'Icon ErrorIcon',
                                }}
                                name="warning"
                                fill="#BA1B1B"
                            />
                        )}
                        {validationResponse === true && (
                            <Icons mix={{ block: 'Field', elem: 'Icon ValidIcon' }} name="tick" fill="#006F00" />
                        )}
                    </div>
                </div>
                {this.renderErrorMessages()}
                {this.renderSubLabel()}
            </div>
        );
    }
}

export default Field;
