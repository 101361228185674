import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Icons from 'Component/Icons';

import './SliderCounterNav.style';

/** @namespace Pwa/Component/SliderCounterNav/Component/SliderCounterNav */
export class SliderCounterNav extends PureComponent {
    static propTypes = {
        goToPrevSlide: PropTypes.func.isRequired,
        goToNextSlide: PropTypes.func.isRequired,
        currentSlide: PropTypes.number.isRequired,
        numberOfSlides: PropTypes.number.isRequired,
    };

    render() {
        const { goToPrevSlide, goToNextSlide, currentSlide, numberOfSlides } = this.props;
        return (
            <div block="SliderCounterNav">
                <button onClick={goToPrevSlide} block="SliderCounterNav" elem="Arrow" aria-label={__('Previous')}>
                    <Icons name="arrow_left" />
                </button>
                <span block="SliderCounterNav" elem="Counter">
                    {currentSlide}/{numberOfSlides}
                </span>
                <button onClick={goToNextSlide} block="SliderCounterNav" elem="Arrow" aria-label={__('Next')}>
                    <Icons name="arrow_right" />
                </button>
            </div>
        );
    }
}

export default SliderCounterNav;
