import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ProductStockAvailability.style';

/** @namespace Pwa/Component/ProductStockAvailability/Component/ProductStockAvailability */
export class ProductStockAvailability extends PureComponent {
    static propTypes = {
        text: PropTypes.func.isRequired,
        status: PropTypes.string.isRequired,
        stock_availability: PropTypes.object,
        isProductWithCalculator: PropTypes.bool,
    };

    static defaultProps = {
        stock_availability: {},
        isProductWithCalculator: false,
    };

    renderProductQuantitySection() {
        const { stock_availability, isProductWithCalculator } = this.props;

        if (isProductWithCalculator) {
            return (
                <span block="ProductStockAvailability" elem="QuantityWrapper">
                    ({stock_availability?.qty} m<sup>2</sup>)
                </span>
            );
        }

        return (
            <span block="ProductStockAvailability" elem="QuantityWrapper">
                ({stock_availability?.qty} <span>{__('pcs')})</span>
            </span>
        );
    }

    renderDeliveryTime() {
        const { text, status, isProductPage } = this.props;

        if (!text) {
            return null;
        }

        if (isProductPage) {
            return (
                <div block="ProductStockAvailability">
                    {status === 'onrequest' ? (
                        <span block="ProductStockAvailability" elem="Wrapper">
                            <span>{__('Availability')}</span>
                            <span block="ProductStockAvailability" elem="Value">
                                <b block="ProductStockAvailability" elem="OnRequest">
                                    {__('On request')}
                                </b>
                            </span>
                        </span>
                    ) : (
                        <span block="ProductStockAvailability" elem="Wrapper">
                            <span block="ProductStockAvailability" elem="SubWrapper">
                                <span block="ProductStockAvailability" elem="LabelWrapper">
                                    {__('Availability')}
                                </span>
                            </span>
                            <span
                                block="ProductStockAvailability"
                                elem="Value"
                                mods={{
                                    unavailable: status === 'onrequest',
                                    fast: status === '24h',
                                    days: status !== '24h' && status !== 'onrequest',
                                }}
                            >
                                {text}
                                {status === '24h' ? '!' : text === '1' ? ` ${__('day')}` : ` ${__('days')}`}
                                {this.renderProductQuantitySection()}
                            </span>
                        </span>
                    )}
                </div>
            );
        }

        return (
            <div block="ProductStockAvailability">
                {status !== 'onrequest' ? (
                    <span>
                        <span>{__('Availability')} </span>
                        <b
                            block="ProductStockAvailability"
                            elem="Value"
                            mods={{ unavailable: status === 'onrequest', fast: status === '24h' }}
                        >
                            {text}
                            {status === '24h' ? '!' : text === '1' ? ` ${__('day')}` : ` ${__('days')}`}
                        </b>
                    </span>
                ) : null}
            </div>
        );
    }

    render() {
        return <>{this.renderDeliveryTime()}</>;
    }
}

export default ProductStockAvailability;
