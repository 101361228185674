import { CONFIRMATION_REQUIRED } from 'Component/MyAccountCreateAccount/MyAccountCreateAccount.config';
import MyAccountQuery from 'Query/MyAccount.query';
import {
    CartDispatcher,
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ProductCompareDispatcher,
    WishlistDispatcher,
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import GDPRDispatcher from 'Store/GDPR/GDPR.dispatcher';
import { updateCustomerDetails, updateCustomerSignInStatus, updateIsLoading } from 'Store/MyAccount/MyAccount.action';
import { goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { clearComparedProducts } from 'Store/ProductCompare/ProductCompare.action';
import { deleteAuthorizationToken, isSignedIn, setAuthorizationToken } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId, getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { removeUid } from 'Util/Compare';
import { fetchMutation, getErrorMessage } from 'Util/Request';

export const CUSTOMER = 'customer';

export * from 'SourceStore/MyAccount/MyAccount.dispatcher';

/** @namespace Pwa/Store/MyAccount/Dispatcher/MyAccountDispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    logout(authTokenExpired = false, isWithNotification = true, dispatch) {
        const wishlistMaskID = localStorage.getItem('wishlistMaskId');

        if (wishlistMaskID) {
            localStorage.removeItem('wishlistMaskId');
        }

        if (authTokenExpired) {
            if (isWithNotification) {
                dispatch(showNotification('error', __('Your session is over, you are logged out!')));
            }

            this.handleForceRedirectToLoginPage();
        } else {
            if (isSignedIn()) {
                fetchMutation(MyAccountQuery.getRevokeAccountToken());
                deleteAuthorizationToken();
            }

            if (isWithNotification) {
                dispatch(showNotification('success', __('You are successfully logged out!')));
            }
        }

        deleteGuestQuoteId();
        BrowserDatabase.deleteItem(CUSTOMER);
        removeUid();

        dispatch(updateCustomerSignInStatus(false));
        dispatch(updateCustomerDetails({}));

        CartDispatcher.then(({ default: dispatcher }) => {
            dispatcher.resetGuestCart(dispatch);
            dispatcher.createGuestEmptyCart(dispatch);
        });
        WishlistDispatcher.then(({ default: dispatcher }) => dispatcher.resetWishlist(dispatch));

        dispatch(clearComparedProducts());
        dispatch(updateCustomerDetails({}));
    }

    createAccount(options = {}, dispatch) {
        const {
            customer: { email },
            password,
        } = options;
        const mutation = MyAccountQuery.getCreateAccountMutation(options);
        dispatch(updateIsLoading(true));

        return fetchMutation(mutation).then(
            /** @namespace Pwa/Store/MyAccount/Dispatcher/fetchMutation/then */
            (data) => {
                const {
                    createCustomer: { customer },
                } = data;
                const { confirmation_required } = customer;

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));

                    return CONFIRMATION_REQUIRED;
                }

                return this.signIn({ email, password, isCreateAccount: true }, dispatch);
            },

            /** @namespace Pwa/Store/MyAccount/Dispatcher/fetchMutation/then */
            (error) => {
                dispatch(updateIsLoading(false));
                dispatch(showNotification('error', getErrorMessage(error)));
                return false;
            }
        );
    }

    /**
     * Sign in action
     * @param {{email: String, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    async signIn(options = {}, dispatch) {
        const wishlistMaskID = localStorage.getItem('wishlistMaskId');

        if (wishlistMaskID) {
            localStorage.removeItem('wishlistMaskId');
        }
        WishlistDispatcher.then(({ default: dispatcher }) => dispatcher.clearWishlist(dispatch));

        const mutation = MyAccountQuery.getSignInMutation(options);

        const result = await fetchMutation(mutation);
        const {
            generateCustomerToken: { token },
        } = result;

        setAuthorizationToken(token);

        ProductCompareDispatcher.then(({ default: dispatcher }) => dispatcher.assignCompareList(dispatch));

        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getGuestQuoteId();

        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            // merge guest cart id and customer cart id using magento capabilities
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch);

        WishlistDispatcher.then(({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch));

        if (options?.isCreateAccount) {
            await GDPRDispatcher.saveGroupsOnRegister();
        }

        await this.requestCustomerData(dispatch);

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        dispatch(goToPreviousNavigationState(TOP_NAVIGATION_TYPE));
        dispatch(showNotification('success', __('You are successfully logged in!')));

        return true;
    }
}

export default new MyAccountDispatcher();
