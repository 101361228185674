import { ProductConfigurableAttributesContainer as SourceProductConfigurableAttributesContainer } from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.container';

/** @namespace Pwa/Component/ProductConfigurableAttributes/Container/ProductConfigurableAttributesContainer */
export class ProductConfigurableAttributesContainer extends SourceProductConfigurableAttributesContainer {
    getLink({ attribute_code, attribute_value, attribute_label, has_swatch }) {
        const { getLink } = this.props;

        if (has_swatch) {
            return getLink(attribute_code, attribute_label);
        }

        return getLink(attribute_code, attribute_value);
    }

    handleOptionClick({ attribute_code, attribute_value, attribute_label, attribute_options, has_swatch }) {
        const { updateConfigurableVariant } = this.props;
        if (has_swatch) {
            updateConfigurableVariant(attribute_code, attribute_label);
            return null;
        }

        if (attribute_code === 'category_id') {
            const selected_option = Object.values(attribute_options).find(
                (option) => option.value_string === attribute_value
            );

            if (selected_option) {
                const sessionItems = JSON.parse(sessionStorage.getItem('categoryFilter'));
                const items = sessionItems || [];
                items.push(selected_option);
                const uniqueItems = [];

                const unique = items.filter((element) => {
                    const isDuplicate = uniqueItems.includes(element.value_string);

                    if (!isDuplicate) {
                        uniqueItems.push(element.value_string);

                        return true;
                    }

                    return false;
                });

                sessionStorage.setItem('categoryFilter', JSON.stringify(unique));
            }
        }

        updateConfigurableVariant(attribute_code, attribute_value);
    }

    isSelected({ attribute_code, attribute_value, attribute_label, has_swatch }) {
        const { parameters = {} } = this.props;
        const parameter = decodeURI(parameters[attribute_code]);
        const parameterFormatted = parameter.replace('%26', '&');

        if (parameterFormatted === undefined) {
            return false;
        }

        if (parameterFormatted.includes(',')) {
            const selectedParamsArr = parameterFormatted?.split(',');
            const valueToCompare = has_swatch ? attribute_label : attribute_value;
            const isSelectedFromArray = selectedParamsArr?.indexOf(valueToCompare) > -1;

            return isSelectedFromArray;
        }

        if (has_swatch) {
            return parameterFormatted === attribute_label;
        }

        return parameterFormatted === attribute_value;
    }
}

export default ProductConfigurableAttributesContainer;
