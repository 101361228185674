import Breadcrumbs from 'Component/Breadcrumbs';
import ContentWrapper from 'Component/ContentWrapper';

import PromotionalBanner from '../component/PromotionalBanner';

export class CategoryPageComponentPlugin {
    render = (args, callback, instance) => {
        const { promotional_banners, isMobile, category } = instance.props;
        const isCategoryMobile = category?.id && isMobile;
        const headerBanners = promotional_banners
            ? promotional_banners.filter((banner) => banner.type === 'header')
            : [];

        return (
            <>
                <ContentWrapper
                    wrapperMix={{
                        block: 'CategoryPage',
                        elem: 'PromoBanners',
                    }}
                    label="Category page"
                >
                    <PromotionalBanner isHeader banner={headerBanners[0]} />
                </ContentWrapper>
                {isCategoryMobile && <Breadcrumbs isPluginBreadcrumb />}
                {callback.apply(instance, args)}
            </>
        );
    };
}

export const { render } = new CategoryPageComponentPlugin();

export default {
    'Route/CategoryPage/Component': {
        'member-function': {
            render,
        },
    },
};
