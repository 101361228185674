import { SharedTransition as SourceSharedTransition } from 'SourceComponent/SharedTransition/SharedTransition.component';

import './SharedTransition.style';

/** @namespace Pwa/Component/SharedTransition/Component/SharedTransition */
export class SharedTransition extends SourceSharedTransition {
    // TODO implement logic
}

export default SharedTransition;
