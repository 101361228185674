import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { roundPercentageDiscount } from 'Util/Discount/Discount';

import './ProductDiscountLabel.style';

/** @namespace Pwa/Component/ProductDiscountLabel/Component/ProductDiscountLabel */
export class ProductDiscountLabel extends PureComponent {
    static propTypes = {
        discountType: PropTypes.string.isRequired,
        fixedDiscount: PropTypes.string,
        percentageDiscount: PropTypes.string,
        displayWithMinus: PropTypes.bool,
    };

    static defaultProps = {
        fixedDiscount: '',
        percentageDiscount: '',
        displayWithMinus: false,
    };

    render() {
        const { discountType, fixedDiscount, percentageDiscount, displayWithMinus } = this.props;
        if (!fixedDiscount && !percentageDiscount) {
            return null;
        }

        const discountAmount =
            discountType === 'fixed' ? fixedDiscount : `${roundPercentageDiscount(percentageDiscount)}%`;

        return (
            <div block="ProductDiscountLabel">
                {displayWithMinus && '-'}
                {discountAmount}
            </div>
        );
    }
}

export default ProductDiscountLabel;
