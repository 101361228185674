import { FieldNumberContainer as SourceFieldNumberContainer } from 'SourceComponent/FieldNumber/FieldNumber.container';

import { INPUT_REQUEST_DELAY } from './FieldNumber.config';
/**
 * Field Number
 * @class FieldNumberContainer
 * @namespace Pwa/Component/FieldNumber/Container/FieldNumberContainer */
export class FieldNumberContainer extends SourceFieldNumberContainer {
    __construct(props) {
        super.__construct(props);
        this.timer = null;
    }

    componentDidUpdate(prevProps) {
        const { attr: { min, value, defaultValue = min } = {}, isRmaProduct } = this.props;
        const { attr: { value: prevValue, defaultValue: prevDefaultValue } = {} } = prevProps;
        const { value: stateValue } = this.state;

        if (defaultValue < min) {
            this.handleInitialLoad(min);
        }

        if (defaultValue < 0 || prevDefaultValue < 0) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: min });
        }

        if (value !== prevValue) {
            this.setValue(value);
        }

        if (defaultValue !== stateValue && !isRmaProduct) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: defaultValue });
        }
    }

    handleValueChange(value) {
        const { events: { onChange } = {}, isCartField } = this.props;

        const newValue = this.setValue(value);

        if (!value && isCartField) {
            return;
        }

        if (typeof onChange === 'function') {
            if (isCartField) {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    onChange(newValue);
                }, INPUT_REQUEST_DELAY);

                return null;
            }
            onChange(newValue);
        }
    }

    containerProps() {
        const { isCartField, isCartOverlay, isProductWithCalculator, productQuantity } = this.props;

        return {
            ...super.containerProps(),
            isCartField,
            isCartOverlay,
            isProductWithCalculator,
            productQuantity,
        };
    }
}

export default FieldNumberContainer;
