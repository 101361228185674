/** @namespace Pwa/Component/Html/Config/getButtonClasses */
export const getButtonClasses = (classes = '') => {
    if (classes.includes('pagebuilder-button-primary')) {
        return ['Button', classes].filter(Boolean).join(' ');
    }

    if (classes.includes('pagebuilder-button-secondary')) {
        return ['Button', 'Button_isHollow', classes].filter(Boolean).join(' ');
    }

    return classes;
};

/** @namespace Pwa/Component/Html/Config/getBackgroundImage */
export const getBackgroundImage = (backgroundImages = '{}', isMobile = false) => {
    try {
        const { desktop_image, mobile_image } = JSON.parse(backgroundImages.replaceAll('\\', '')) || {};
        const image = isMobile ? mobile_image || desktop_image : desktop_image;
        return image && `url(${desktop_image})`;
    } catch {
        return undefined;
    }
};

/** @namespace Pwa/Component/Html/Config/getDisplayType */
export const getDisplayType = (data) =>
    data?.attribs?.['data-content-type'] === 'products' && data?.attribs?.['data-appearance'];
