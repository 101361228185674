/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CLEAR_AUTHOR, UPDATE_AUTHOR } from './Author.action';

/** @namespace Domneo/Blog/Store/Author/Reducer/getInitialState */
export const getInitialState = () => ({
    author: null,
    isLoaded: false,
});

/** @namespace Domneo/Blog/Store/Author/Reducer/AuthorReducer */
export const AuthorReducer = (state = getInitialState(), action) => {
    const { isLoaded, type } = action;

    switch (type) {
        case UPDATE_AUTHOR:
            const { author } = action;

            return {
                ...state,
                isLoaded,
                author,
            };

        case CLEAR_AUTHOR:
            return {
                ...state,
                author: null,
            };
        default:
            return state;
    }
};

export default AuthorReducer;
