/* eslint-disable no-return-assign */

import Countdown from '@domneo/slider/src/components/Countdown';
import SourceSliderComponent from '@domneo/slider/src/components/Slider/Slider.component';
import SliderNextArrow from '@domneo/slider/src/components/SliderNextArrow/SliderNextArrow.component';
import SliderPrevArrow from '@domneo/slider/src/components/SliderPrevArrow/SliderPrevArrow.component';
import SlickSlider from 'react-slick';

import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import media from 'Util/Media';

import { DEFAULT_SLICK_SETTINGS, VISIBLE_SLIDES } from './Slider.config';

import './Slider.override.style';

/** @namespace Pwa/@Domneo/Slider/Components/Slider/Component/SliderComponent */
export class SliderComponent extends SourceSliderComponent {
    renderImage(slide, index) {
        const { header, cta_link: ctaLink, image, tablet_image: tabletImage, mobile_image: mobileImage, name } = slide;

        if (!image) {
            return null;
        }

        return (
            <Link block="Slider" elem="Image" to={ctaLink} aria-label={name}>
                <picture>
                    {mobileImage ? <source media="(max-width: 767px)" srcSet={media(mobileImage)} /> : null}
                    {tabletImage ? <source media="(max-width: 1023px)" srcSet={media(tabletImage)} /> : null}
                    <img src={media(image)} alt={header || name} loading={index === 0 ? 'eager' : 'lazy'} />
                </picture>
            </Link>
        );
    }

    renderContent(slide) {
        const { body, header, cta_text: ctaText, cta_link: ctaLink, cta_color: ctaColor } = slide;

        if (!body && !ctaText && !header) {
            return null;
        }

        return (
            <div block="Slider" elem="Content">
                {body ? <h3>{body}</h3> : null}
                {header ? this.renderHeader(header, ctaLink) : null}
                {ctaText ? (
                    <Link
                        block="Slider"
                        elem="Button"
                        mix={{ block: 'Button' }}
                        style={{ backgroundColor: ctaColor, borderColor: ctaColor }}
                        to={ctaLink}
                    >
                        {ctaText}
                    </Link>
                ) : null}
            </div>
        );
    }

    renderHeader(header, ctaLink = null) {
        if (!ctaLink) {
            return <h1>{header}</h1>;
        }

        return (
            <Link to={ctaLink}>
                <h1>{header}</h1>
            </Link>
        );
    }

    renderCountdown(slide) {
        const { counter_date: counterDate, counter_enable: counterEnable } = slide;

        if (!counterEnable || !counterDate) {
            return null;
        }

        return <Countdown date={counterDate} />;
    }

    renderSlide(slide, index) {
        return (
            <div block="Slider" elem="Container">
                {this.renderContent(slide)}
                {this.renderImage(slide, index)}
                {this.renderCountdown(slide)}
            </div>
        );
    }

    renderSliderSlides(slider) {
        return slider.slides.map((slide, index) => <div key={`slider${index}`}>{this.renderSlide(slide, index)}</div>);
    }

    render() {
        const { slider, settings, loaded } = this.props;

        if (!loaded) {
            return (
                <div block="Slider" mods={{ placeholder: true }}>
                    <TextPlaceholder length="full" />
                </div>
            );
        }

        if (!slider || !slider?.slides) {
            return null;
        }

        return (
            <div block="Slider" mods={{ wide: slider?.slides.length > VISIBLE_SLIDES }}>
                <SlickSlider
                    appendDots={(dots) => <ul>{dots}</ul>}
                    customPaging={this.renderCustomPaging.bind(this)}
                    prevArrow={<SliderPrevArrow />}
                    nextArrow={<SliderNextArrow />}
                    {...DEFAULT_SLICK_SETTINGS}
                    {...settings}
                >
                    {this.renderSliderSlides(slider)}
                </SlickSlider>
            </div>
        );
    }
}

export default SliderComponent;
