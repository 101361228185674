import _ from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { isSignedIn } from 'Util/Auth';
import { roundPrice } from 'Util/Price';

import { getActionField } from '../../eventData/actionField.data';
import {
    productAddToCart as productAction,
    productQtyChangeData,
    productsPurchase,
} from '../../eventData/actionProduct.data';
import { baseProductData } from '../../eventData/baseProduct.data';
import {
 getGeneralEventData, getPath, getProducts, getStoreView, routes 
} from '../../eventData/general.data';
import { getImpressionsData } from '../../eventData/impression.data';
import { getPurchaseData } from '../../eventData/purchase.data';
import { setExecuted } from '../../store/GoogleTagManager/GoogleTagManager.action';
import {
    CHECKOUT,
    EVENT_VIEW_ITEM_LIST,
    NO_RESULTS_FOUND,
    RESULTS_LOADED,
    ROOT,
    SPAM_PROTECTION_DELAY,
    ZERO,
} from './GoogleTagManager.config.js';
import {
    EVENT_GTM_BEGIN_CHECKOUT,
    EVENT_GTM_CHECKOUT,
    EVENT_GTM_CHECKOUT_OPTION,
    EVENT_GTM_GENERAL_INIT,
    EVENT_GTM_IMPRESSIONS_PLP,
    EVENT_GTM_IMPRESSIONS_SEARCH,
    EVENT_GTM_NOT_FOUND,
    EVENT_GTM_PRODUCT_ADD_TO_CART,
    EVENT_GTM_PRODUCT_CLICK,
    EVENT_GTM_PRODUCT_DETAIL,
    EVENT_GTM_PRODUCT_REMOVE_FROM_CART,
    EVENT_GTM_PURCHASE,
    EVENT_GTM_SITE_SEARCH,
    EVENT_GTM_SITE_SEARCH_STARTED,
    EVENT_GTM_USER_LOGIN,
    EVENT_GTM_USER_REGISTER,
    EVENT_GTM_VIEW_CART,
    EVENT_KEY_ADD_TO_CART,
    EVENT_KEY_BEGIN_CHECKOUT,
    EVENT_KEY_CHECKOUT,
    EVENT_KEY_CHECKOUT_OPTION,
    EVENT_KEY_CUSTOM,
    EVENT_KEY_GENERAL,
    EVENT_KEY_NOT_FOUND,
    EVENT_KEY_PRODUCT_CLICK,
    EVENT_KEY_PRODUCT_DETAIL,
    EVENT_KEY_PRODUCT_REMOVE_FROM_CART,
    EVENT_KEY_PURCHASE,
    EVENT_KEY_SEARCH,
    EVENT_KEY_SEARCH_STARTED,
    EVENT_KEY_USER_LOGIN,
    EVENT_KEY_USER_REGISTER,
    EVENT_KEY_VIEW_CART,
    IMPRESSIONS,
} from './GoogleTagManager.events';

/** @namespace Domneo/Gtm/Component/GoogleTagManager/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    config: state.GoogleTagManagerReducer.config,
    isRewriteLoading: state.UrlRewritesReducer.isLoading,
    storeConfig: state.ConfigReducer,
    isExecuted: state.GoogleTagManagerReducer.isExecuted,
    event: state.GoogleTagManagerReducer.event,
    events: state.GoogleTagManagerReducer.config.events,
    customData: state.GoogleTagManagerReducer.custom,
    productsOnPages: state.ProductListReducer.pages,
    areProductsLoading: state.ProductListReducer.isLoading,
    signedIn: state.MyAccountReducer.isSignedIn,
    isLoading: state.ProductListInfoReducer.isLoading,
    cart: state.CartReducer.cartTotals,
    store: state
});

/** @namespace Domneo/Gtm/Component/GoogleTagManager/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    executed: () => dispatch(setExecuted())
});

/** @namespace Domneo/Gtm/Component/GoogleTagManager/Container/GoogleTagManagerContainer */
export class GoogleTagManagerContainer extends Component {
    static propTypes = {
        config: PropTypes.shape({
            enabled: PropTypes.bool,
            gtm_id: PropTypes.string
        }),
        isRewriteLoading: PropTypes.bool.isRequired,
        isExecuted: PropTypes.bool.isRequired,
        event: PropTypes.string,
        events: PropTypes.shape({}),
        executed: PropTypes.func.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        customData: PropTypes.any,
        // eslint-disable-next-line react/forbid-prop-types
        location: PropTypes.any.isRequired
    };

    static defaultProps = {
        config: {
            enabled: false,
            gtm_id: ''
        },
        event: EVENT_GTM_GENERAL_INIT,
        events: {},
        customData: ''
    };

    static isInitialized = false;

    static executedName = null;

    static generalFiredCount = 0;

    static lastEventTime = {};

    eventMap = {
        [EVENT_GTM_GENERAL_INIT]: {
            getData: this.prepareGeneralData.bind(this),
            eventKey: EVENT_KEY_GENERAL
        },
        [EVENT_GTM_USER_LOGIN]: {
            getData: this.blankEvent.bind(this),
            eventKey: EVENT_KEY_USER_LOGIN
        },
        [EVENT_GTM_USER_REGISTER]: {
            getData: this.blankEvent.bind(this),
            eventKey: EVENT_KEY_USER_REGISTER
        },
        [EVENT_GTM_PRODUCT_ADD_TO_CART]: {
            getData: this.prepareAddToCartData.bind(this),
            eventKey: EVENT_KEY_ADD_TO_CART
        },
        [EVENT_GTM_PRODUCT_REMOVE_FROM_CART]: {
            getData: this.prepareRemoveFromCartData.bind(this),
            eventKey: EVENT_KEY_PRODUCT_REMOVE_FROM_CART
        },
        [EVENT_GTM_PRODUCT_CLICK]: {
            getData: this.prepareProductClickData.bind(this),
            eventKey: EVENT_KEY_PRODUCT_CLICK
        },
        [EVENT_GTM_PRODUCT_DETAIL]: {
            getData: this.prepareProductDetailsData.bind(this),
            eventKey: EVENT_KEY_PRODUCT_DETAIL
        },
        [EVENT_GTM_IMPRESSIONS_PLP]: {
            getData: this.prepareProductImpression.bind(this),
            eventKey: IMPRESSIONS
        },
        [EVENT_GTM_IMPRESSIONS_SEARCH]: {
            getData: this.prepareProductSearchImpression.bind(this),
            eventKey: IMPRESSIONS
        },
        [EVENT_GTM_NOT_FOUND]: {
            getData: this.prepareNotFound.bind(this),
            eventKey: EVENT_KEY_NOT_FOUND
        },
        [EVENT_GTM_CHECKOUT_OPTION]: {
            getData: this.prepareCheckoutOption.bind(this),
            eventKey: EVENT_KEY_CHECKOUT_OPTION
        },
        [EVENT_GTM_CHECKOUT]: {
            getData: this.prepareCheckout.bind(this),
            eventKey: EVENT_KEY_CHECKOUT
        },
        [EVENT_GTM_PURCHASE]: {
            getData: this.preparePurchase.bind(this),
            eventKey: EVENT_KEY_PURCHASE
        },
        [EVENT_GTM_SITE_SEARCH]: {
            getData: this.prepareSearch.bind(this),
            eventKey: EVENT_KEY_SEARCH
        },
        [EVENT_GTM_SITE_SEARCH_STARTED]: {
            getData: this.blankEvent.bind(this),
            eventKey: EVENT_KEY_SEARCH_STARTED
        },
        [EVENT_GTM_BEGIN_CHECKOUT]: {
            getData: this.prepareCartData.bind(this),
            eventKey: EVENT_KEY_BEGIN_CHECKOUT
        },
        [EVENT_GTM_VIEW_CART]: {
            getData: this.prepareCartData.bind(this),
            eventKey: EVENT_KEY_VIEW_CART
        }
    };

    customEventMap = {
        [EVENT_GTM_BEGIN_CHECKOUT]: EVENT_GTM_CHECKOUT,
        [EVENT_GTM_VIEW_CART]: EVENT_GTM_CHECKOUT,
    };

    componentDidMount() {
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();
    }

    shouldComponentUpdate(nextProps) {
        return !_.isEqual(nextProps, this.props);
    }

    componentDidUpdate(prevProps) {
        const {
            event,
            isExecuted,
            isRewriteLoading,
            config: { enabled },
            store,
            location: { pathname }
        } = this.props;

        const {
            isRewriteLoading: prevRewriteLoading,
            location: { pathname: prevPath }
        } = prevProps;

        const storeView = getStoreView(store);
        const path = getPath(pathname, storeView);

        // Init And General Event
        if (
            !isRewriteLoading
            && (isSignedIn() || this.canFireGeneral())
            && !GoogleTagManagerContainer.isInitialized
            && enabled
        ) {
            this.init();
            this.fireGeneralEvent();
        }

        const shouldFireGeneral =            prevRewriteLoading && !isRewriteLoading && GoogleTagManagerContainer.isInitialized && enabled;

        // General Event
        if (shouldFireGeneral) {
            this.fireGeneralEvent(true);
        }

        // If user Lands on one of React routes
        if (pathname !== prevPath && GoogleTagManagerContainer.isInitialized && enabled && !shouldFireGeneral) {
            routes().find((route) => {
                if (path.includes(route)) {
                    this.fireGeneralEvent(true);
                    return null;
                }
            });

            if (path === ROOT) {
                this.fireGeneralEvent(true);
            }
        }

        if (isExecuted) {
            GoogleTagManagerContainer.executedName = null;
        }

        // Any event fire
        if (
            GoogleTagManagerContainer.executedName !== event
            && !isExecuted
            && GoogleTagManagerContainer.isInitialized
        ) {
            this.executeEvent();
        }
    }

    init() {
        const {
            config: { enabled, gtm_id }
        } = this.props;

        GoogleTagManagerContainer.isInitialized = true;

        if (enabled) {
            TagManager.initialize({ gtmId: gtm_id });
        }
    }

    resetEvent() {
        TagManager.dataLayer({
            dataLayer: {
                ecommerce: undefined,
            },
        });
    }

    executeEvent() {
        const { events, event } = this.props;

        // Check if event exists in Event map and backend configuration
        if (this.eventExist(event) && (events[event] || events[this.customEventMap[event]])) {
            const data = this.eventMap[event].getData();

            this.resetEvent();
            this.pushEvent(data);
        }
    }

    canFireGeneral(prevSignedIn, event) {
        const { cart = {} } = this.props;

        return !isSignedIn() && !(Object.keys(cart).length === 0);
    }

    fireGeneralEvent(fireOnSecondRun = false) {
        if (!fireOnSecondRun || (fireOnSecondRun && GoogleTagManagerContainer.generalFiredCount === 1)) {
            const data = this.prepareGeneralData();
            TagManager.dataLayer(data);

            if (fireOnSecondRun) {
                GoogleTagManagerContainer.generalFiredCount = 0;
            }
        } else if (fireOnSecondRun && GoogleTagManagerContainer.generalFiredCount === 0) {
            GoogleTagManagerContainer.generalFiredCount = 1;
        }
    }

    resize() {
        this.setState({ canFireImpression: true });
    }

    // Event Push
    pushEvent(data) {
        const { event, executed } = this.props;
        TagManager.dataLayer(data);
        GoogleTagManagerContainer.executedName = event;
        executed();
    }

    // Check if event exists in a map
    eventExist(event) {
        return typeof this.eventMap[event] !== 'undefined';
    }

    prepareCheckoutOption() {
        const {
            customData: { option, step }
        } = this.props;

        return {
            dataLayer: {
                event: this.getEventKey(),
                ecommerce: {
                    checkout_option: {
                        actionField: {
                            option,
                            step,
                            action: CHECKOUT
                        }
                    }
                }
            }
        };
    }

    prepareCheckout() {
        const {
            customData: { step },
            cart: { items = [] }
        } = this.props;

        return {
            dataLayer: {
                event: this.getEventKey(),
                ecommerce: {
                    checkout: {
                        actionField: {
                            step,
                            action: CHECKOUT
                        },
                        products: items.length ? getProducts(items, CHECKOUT) : []
                    }
                }
            }
        };
    }

    prepareNotFound() {
        return {
            dataLayer: {
                event: this.getEventKey(),
                eventAction: window.location.href
            }
        };
    }

    prepareUserLoginData() {
        return {
            dataLayer: {
                event: this.getEventKey()
            }
        };
    }

    prepareCartData() {
        const {
            cart: { grand_total = 0, items = [] },
            storeConfig: {
                currencyData: { current_currency_code }
            }
        } = this.props;

        return {
            dataLayer: {
                event: this.getEventKey(),
                ecommerce: {
                    currency: current_currency_code,
                    value: +roundPrice(grand_total),
                    items: items.length ? getProducts(items, CHECKOUT) : []
                }
            }
        };
    }

    prepareAddToCartData() {
        const { customData, store } = this.props;
        const { isCart = false } = customData;

        return {
            dataLayer: {
                event: this.getEventKey(),
                ecommerce: {
                    items: [
                        !isCart
                            ? { ...productAction(customData, store) }
                            : { ...productQtyChangeData(customData, store) },
                    ]
                }
            }
        };
    }

    prepareRemoveFromCartData() {
        const {
            customData,
            storeConfig: {
                currencyData: { current_currency_code }
            },
            store
        } = this.props;
        const { isCart = false } = customData;

        return {
            dataLayer: {
                event: this.getEventKey(),
                ecommerce: {
                    currencyCode: current_currency_code,
                    remove: {
                        products: [
                            !isCart
                                ? { ...productAction(customData, store) }
                                : { ...productQtyChangeData(customData, store) }
                        ]
                    }
                }
            }
        };
    }

    prepareProductClickData() {
        const { customData } = this.props;

        return {
            dataLayer: {
                event: this.getEventKey(),
                ecommerce: {
                    items: [{ ...baseProductData({ product: { ...customData } }, true) }]
                }
            }
        };
    }

    prepareProductDetailsData() {
        const { customData } = this.props;

        return {
            dataLayer: {
                event: this.getEventKey(),
                ecommerce: {
                    items: [{ ...baseProductData({ product: { ...customData } }, false) }]
                }
            }
        };
    }

    blankEvent() {
        return {
            dataLayer: {
                event: this.getEventKey()
            }
        };
    }

    prepareGeneralData() {
        const { store } = this.props;

        return {
            dataLayer: {
                event: EVENT_KEY_GENERAL,
                ...getGeneralEventData(store)
            }
        };
    }

    preparePurchase() {
        const {
            customData,
            storeConfig: {
                currencyData: { current_currency_code }
            }
        } = this.props;

        return {
            dataLayer: {
                event: this.getEventKey(),
                ecommerce: {
                    ...getPurchaseData(customData, current_currency_code),
                }
            }
        };
    }

    prepareProductImpression() {
        const { customData, store } = this.props;

        return {
            dataLayer: {
                event: this.getEventKey(),
                ecommerce: {
                    items: getImpressionsData(customData, store)
                }
            }
        };
    }

    prepareProductSearchImpression() {
        const {
            customData: { products = [], search },
            overlay = false
        } = this.props;
        const siteSearch = this.prepareSearch(search, products.length);
        TagManager.dataLayer(siteSearch);

        return this.prepareProductImpression();
    }

    prepareSearch(search, resultLoaded) {
        return {
            dataLayer: {
                event: EVENT_KEY_SEARCH,
                eventCategory: EVENT_KEY_SEARCH,
                eventAction: !resultLoaded ? NO_RESULTS_FOUND : RESULTS_LOADED,
                eventLabel: search,
                eventNonInteraction: ZERO
            }
        };
    }

    spamProtection(delay, type = 'default') {
        const typeParam = `${type}-${window.location.href}`;
        const previousEventTime = GoogleTagManagerContainer.lastEventTime[typeParam] || 0;
        GoogleTagManagerContainer.lastEventTime[typeParam] = Date.now();

        return previousEventTime + delay > GoogleTagManagerContainer.lastEventTime[typeParam];
    }

    // gets event Key aka Event Name in dataLayer
    getEventKey() {
        const { event = '' } = this.props;

        if (event === EVENT_VIEW_ITEM_LIST && this.spamProtection(SPAM_PROTECTION_DELAY, event)) {
            return;
        }

        return EVENT_KEY_CUSTOM[this.eventMap[event].eventKey] || this.eventMap[event].eventKey;
    }

    render() {
        return null;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GoogleTagManagerContainer));
