import AddToCart from 'Component/AddToCart';
import Image from 'Component/Image';
import Link from 'Component/Link';
import ProductLabel from 'Component/ProductLabel';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { ProductCard as SourceProductCard } from 'SourceComponent/ProductCard/ProductCard.component';

import './CrossSellProductCard.style.scss';
/** @namespace Domneo/Crosssellpopup/Component/CrossSellProductCard/Component/CrossSellProductCardComponent */
export class CrossSellProductCardComponent extends SourceProductCard {
    renderAddToCartButton(layout = GRID_LAYOUT) {
        const { addToCart, inStock, quantity, getActiveProduct } = this.props;

        return (
            <AddToCart
                mix={{ block: this.className, elem: 'AddToCart' }}
                addToCart={addToCart}
                isDisabled={!inStock}
                isIconEnabled={false}
                layout={layout}
                quantity={quantity}
                product={getActiveProduct()}
                customTexts={{ add: __('Add to cart') }}
                isCrossSellButton
            />
        );
    }

    render() {
        const {
            product: { name, thumbnail, url },
        } = this.props;

        return (
            <div block="CrossSellPopup" elem="ProductCard">
                <div block="CrossSellPopup" elem="ProductImage">
                    <Image block="CrossSellPopup" elem="Image" src={thumbnail?.url || ''} />
                </div>
                <div block="CrossSellPopup" elem="ProductName">
                    <Link to={url}>{name}</Link>
                </div>
                {this.renderPrice()}
                <div block="CrossSellPopup" elem="AddToCart">
                    {this.renderAddToCart()}
                    {this.renderQuantityChanger()}
                </div>
            </div>
        );
    }
}

export default CrossSellProductCardComponent;
