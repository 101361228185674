import { Field } from 'Util/Query';

/** @namespace Pwa/Query/GDPR/Query/GDPRQuery */
export class GDPRQuery {
    getCookieSettings() {
        return new Field('getCookieSettings').setAlias('cookieSettings').addFieldList(this._getCookieSettingsFields());
    }

    getCookieGroups(customer) {
        return new Field('getCookieGroups')
            .setAlias('cookieGroups')
            .addArgument('customer_id', 'Int', customer)
            .addFieldList(this._getCookieGroupsFields());
    }

    setCookieGroups(groups) {
        return new Field('setCookieGroups')
            .setAlias('cookieGroups')
            .addArgument('selected_groups', '[String]', groups)
            .addFieldList(this._setCookieGroupsFields());
    }

    _getCookieSettingsFields() {
        return ['gdpr_cookie_bar', 'gdpr_cookie_bar_text', 'gdpr_cookie_lifetime'];
    }

    _getCookieGroupsFields() {
        return ['group_id', 'is_essential', 'name', 'description', 'accepted', this._getRelatedCookiesField()];
    }

    _setCookieGroupsFields() {
        return ['result', 'message'];
    }

    _getRelatedCookiesField() {
        return new Field('cookies').addFieldList(this._getRelatedCookiesFields());
    }

    _getRelatedCookiesFields() {
        return ['name'];
    }
}

export default new GDPRQuery();
