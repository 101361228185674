import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import { roundPrice } from 'Util/Price';

import {
    CATEOGRY_LIMIT,
    CHECKOUT,
    GROUPED,
    NOT_APPLICABLE,
    ONE,
    ZERO,
} from '../component/GoogleTagManager/GoogleTagManager.config';

/** @namespace Domneo/Gtm/EventData/BaseProduct/Data/getProductName */
export const getProductName = (type, product, mainSku = '', click) => {
    if (!product.name) {
        return null;
    }
    const { name = '' } = product;

    if (click) {
        return NOT_APPLICABLE;
    }

    if (type === PRODUCT_TYPE.simple) {
        return name;
    }

    if (type === PRODUCT_TYPE.configurable && mainSku) {
        return name;
    }

    return name;
};

/** @namespace Domneo/Gtm/EventData/BaseProduct/Data/getProductSKU */
export const getProductSKU = (type, product, mainSku = '', click) => {
    const { sku = '' } = product;
    if (click) {
        return NOT_APPLICABLE;
    }

    if (type === PRODUCT_TYPE.simple) {
        return sku;
    }

    if (type === PRODUCT_TYPE.configurable && mainSku) {
        const { variants } = product;
        return variants.find(({ sku }) => sku === mainSku).sku;
    }

    return sku;
};

/** @namespace Domneo/Gtm/EventData/BaseProduct/Data/getCategories */
export const getCategories = (categories = []) => {
    if (!Array.isArray(categories)) {
        return [];
    }

    const limitedCategories = categories.slice(0, CATEOGRY_LIMIT).map(({ name }) => name);
    const categoryString = limitedCategories.join('/');
    return categoryString.charAt(0) === '/' ? categoryString.substring(1) : categoryString;
};

/** @namespace Domneo/Gtm/EventData/BaseProduct/Data/getCategoriesLevel */
export const getCategoriesLevel = (categories = []) => {
    if (!Array.isArray(categories)) {
        return [];
    }

    return categories
        .slice(0, CATEOGRY_LIMIT)
        .map(({ name }) => name)
        .reduce(
            (result, category, index) => ({ ...result, [`item_category${index === 0 ? '' : index + 1}`]: category }),
            {}
        );
};

/** @namespace Domneo/Gtm/EventData/BaseProduct/Data/getQty */
export const getQty = (qty) => ({ quantity: qty || ONE });

/** @namespace Domneo/Gtm/EventData/BaseProduct/Data/getVariantIndex */
export const getVariantIndex = (type_id, variants, configurableVariantIndex) => {
    if (type_id === PRODUCT_TYPE.simple) {
        return -1;
    }
    if (!isNaN(configurableVariantIndex) && configurableVariantIndex >= 0 && configurableVariantIndex !== false) {
        return configurableVariantIndex;
    }
    if (variants[0] !== undefined) {
        return 0;
    }

    return -1;
};

/** @namespace Domneo/Gtm/EventData/BaseProduct/Data/getPrice */
export const getPrice = (variant, type_id, price, notInGetProduct = true) => {
    if (price && notInGetProduct) {
        return price;
    }

    const {
        price_range: {
            maximum_price: {
                final_price: { value: discountValue = null } = {},
                regular_price: { value = 0 } = {}
            } = {},
        } = {},
    } = variant;

    return type_id !== GROUPED ? +roundPrice(discountValue || value) || 0 : 0;
};

/** @namespace Domneo/Gtm/EventData/BaseProduct/Data/getBrand */
export const getBrand = (product) => {
    const { attributes: { manufacturer } = {} } = product;

    if (manufacturer === undefined) {
        return NOT_APPLICABLE;
    }

    const { attribute_options, attribute_value } = manufacturer;

    const { label } = attribute_options[attribute_value] || {};
    return label;
};

/** @namespace Domneo/Gtm/EventData/BaseProduct/Data/getVariant */
export const getVariant = (product, type_id, configurableVariantIndex = false) => {
    const { variants, attributes: { size } = {} } = product;

    if (size === undefined || type_id === PRODUCT_TYPE.simple || variants[0] === undefined) {
        return NOT_APPLICABLE;
    }

    const configIndex = !configurableVariantIndex ? 0 : configurableVariantIndex;

    const { attribute_options } = size;

    const {
        attributes: {
            size: { attribute_value },
        },
    } = variants[configIndex];

    const {
        swatch_data: { value },
    } = attribute_options[attribute_value];

    return value;
};

/** @namespace Domneo/Gtm/EventData/BaseProduct/Data/getPosition */
export const getPosition = (position) => ({ index: position || ZERO });

/** @namespace Domneo/Gtm/EventData/BaseProduct/Data/baseProductData */
export const baseProductData = (item, click = false, notInGetProduct = true, event = '') => {
    const {
        qty = false,
        price,
        product: {
            type_id = PRODUCT_TYPE.simple,
            categories,
            sku: parentSku,
            position = 1,
            name: parentName,
            variants,
        },
        product,
    } = item;

    const data = {
        item_name: parentName,
        item_id: parentSku,
        item_brand: getBrand(product),
        price:
            type_id === PRODUCT_TYPE.configurable && notInGetProduct === false
                ? getPrice(variants[0], type_id, price, notInGetProduct)
                : getPrice(product, type_id, price, notInGetProduct),
        ...getQty(qty),
        ...getPosition(position, click),
        ...getCategoriesLevel(categories)
    };

    if (event === CHECKOUT) {
        data.item_variant = getVariant(product, type_id, ZERO);
    }

    return data;
};
