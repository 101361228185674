import ClickOutside from 'Component/ClickOutside';
import { Popup as SourcePopup } from 'SourceComponent/Popup/Popup.component';

import './Popup.override.style';

/** @namespace Pwa/Component/Popup/Component/Popup */
export class Popup extends SourcePopup {
    hidePopupAndGoBack() {
        this.hidePopUp();
    }

    renderContent() {
        const { children, contentMix } = this.props;
        const isVisible = this.getIsVisible();

        if (!isVisible) {
            return null;
        }

        return (
            <ClickOutside onClick={this.handleClickOutside}>
                <div block="Popup" elem="Content" mix={contentMix}>
                    <header block="Popup" elem="Header">
                        {this.renderTitle()}
                        {this.renderCloseButton()}
                    </header>
                    <div block="Popup" elem="Body">
                        {this.renderNotifications()}
                        {children}
                    </div>
                </div>
            </ClickOutside>
        );
    }
}

export default Popup;
