import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import { FieldSelect as SourceFieldSelect } from 'SourceComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

/** @namespace Pwa/Component/FieldSelect/Component/FieldSelect */
export class FieldSelect extends SourceFieldSelect {
    renderOption(option) {
        const { id, label, subLabel, isPlaceholder = false, isHovered, isAvailable = true } = option;

        const { isExpanded, handleSelectListOptionClick } = this.props;

        return (
            <li
                block="FieldSelect"
                elem="Option"
                mods={{
                    isDisabled: !isAvailable,
                    isExpanded,
                    isPlaceholder,
                    isHovered,
                }}
                key={id}
                /**
                 * Added 'o' as querySelector does not work with
                 * ids, that consist of numbers only
                 */
                id={id ? `o${id}` : null}
                role="menuitem"
                // eslint-disable-next-line react/jsx-no-bind
                onMouseDown={() => handleSelectListOptionClick(option)}
                // eslint-disable-next-line react/jsx-no-bind
                onTouchStart={() => handleSelectListOptionClick(option)}
                // eslint-disable-next-line react/jsx-no-bind
                onKeyPress={() => handleSelectListOptionClick(option)}
                tabIndex={isExpanded ? '0' : '-1'}
            >
                {label}
                {subLabel && <strong>{` ${subLabel}`}</strong>}
            </li>
        );
    }

    renderNativeSelect() {
        const {
            setRef,
            attr,
            events,
            isDisabled,
            options,
            handleSelectListOptionClick,
            attr: { name = '' } = {},
        } = this.props;

        const selectLabel = options?.length ? options[0]?.label : name;

        return (
            <select
                block="FieldSelect"
                elem="Select"
                ref={(elem) => setRef(elem)}
                disabled={isDisabled}
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                {...attr}
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                {...events}
                onChange={handleSelectListOptionClick}
                aria-label={selectLabel}
            >
                {options.map(this.renderNativeOption.bind(this))}
            </select>
        );
    }

    render() {
        const {
            attr: { id = '' } = {},
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
        } = this.props;

        return (
            <ClickOutside onClick={handleSelectExpandedExpand}>
                <div
                    id={`${id}_wrapper`}
                    block="FieldSelect"
                    mods={{ isExpanded }}
                    onClick={handleSelectExpand}
                    onKeyPress={handleSelectListKeyPress}
                    role="button"
                    tabIndex="0"
                    aria-expanded={isExpanded}
                >
                    <div block="FieldSelect" elem="Clickable">
                        {this.renderNativeSelect()}
                        <ChevronIcon direction={isExpanded ? TOP : BOTTOM} />
                    </div>
                    {this.renderOptions()}
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelect;
