/* eslint-disable */

/** @namespace Scandipwa/Component/Icons/Config/ANGLE_DOWN */
export const ANGLE_DOWN = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/ANGLE_DOWN */
export const ANGLE_RIGHT = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M9.70498 6L8.29498 7.41L12.875 12L8.29498 16.59L9.70498 18L15.705 12L9.70498 6Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/ANGLE_LEFT */
export const ANGLE_LEFT = ({fill, ...rest}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/ARROW_LEFT */
export const ARROW_LEFT = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M20 12.5C20.2761 12.5 20.5 12.2761 20.5 12C20.5 11.7239 20.2761 11.5 20 11.5L20 12.5ZM3.64645 11.6464C3.45118 11.8417 3.45118 12.1583 3.64645 12.3536L6.82843 15.5355C7.02369 15.7308 7.34027 15.7308 7.53553 15.5355C7.7308 15.3403 7.7308 15.0237 7.53553 14.8284L4.70711 12L7.53553 9.17157C7.7308 8.97631 7.7308 8.65973 7.53553 8.46447C7.34027 8.2692 7.02369 8.2692 6.82843 8.46446L3.64645 11.6464ZM20 11.5L4 11.5L4 12.5L20 12.5L20 11.5Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/ARROW_RIGHT */
export const ARROW_RIGHT = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M4 11.5C3.72386 11.5 3.5 11.7239 3.5 12C3.5 12.2761 3.72386 12.5 4 12.5V11.5ZM20.3536 12.3536C20.5488 12.1583 20.5488 11.8417 20.3536 11.6464L17.1716 8.46447C16.9763 8.2692 16.6597 8.2692 16.4645 8.46447C16.2692 8.65973 16.2692 8.97631 16.4645 9.17157L19.2929 12L16.4645 14.8284C16.2692 15.0237 16.2692 15.3403 16.4645 15.5355C16.6597 15.7308 16.9763 15.7308 17.1716 15.5355L20.3536 12.3536ZM4 12.5L20 12.5V11.5L4 11.5V12.5Z" fill={fill}/>
    </svg>

);

/** @namespace Scandipwa/Component/Icons/Config/BAG */
export const BAG = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M22 9.48977H17.21L12.83 2.92977C12.64 2.64977 12.32 2.50977 12 2.50977C11.68 2.50977 11.36 2.64977 11.17 2.93977L6.79 9.48977H2C1.45 9.48977 1 9.93977 1 10.4898C1 10.5798 1.01 10.6698 1.04 10.7598L3.58 20.0298C3.81 20.8698 4.58 21.4898 5.5 21.4898H18.5C19.42 21.4898 20.19 20.8698 20.43 20.0298L22.97 10.7598L23 10.4898C23 9.93977 22.55 9.48977 22 9.48977ZM12 5.28977L14.8 9.48977H9.2L12 5.28977ZM18.5 19.4898L5.51 19.4998L3.31 11.4898H20.7L18.5 19.4898ZM12 13.4898C10.9 13.4898 10 14.3898 10 15.4898C10 16.5898 10.9 17.4898 12 17.4898C13.1 17.4898 14 16.5898 14 15.4898C14 14.3898 13.1 13.4898 12 13.4898Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/BATH */
export const BATH = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M7 9C8.10457 9 9 8.10457 9 7C9 5.89543 8.10457 5 7 5C5.89543 5 5 5.89543 5 7C5 8.10457 5.89543 9 7 9Z" fill={fill}/>
        <path d="M20 13V4.83C20 3.27 18.73 2 17.17 2C16.42 2 15.7 2.3 15.17 2.83L13.92 4.08C13.76 4.03 13.59 4 13.41 4C13.01 4 12.64 4.12 12.33 4.32L15.09 7.08C15.29 6.77 15.41 6.4 15.41 6C15.41 5.82 15.38 5.66 15.34 5.49L16.59 4.24C16.74 4.09 16.95 4 17.17 4C17.63 4 18 4.37 18 4.83V13H11.15C10.85 12.79 10.58 12.55 10.33 12.28L8.93 10.73C8.74 10.52 8.5 10.35 8.24 10.23C7.93 10.08 7.59 10 7.24 10C6 10.01 5 11.01 5 12.25V13H2V19C2 20.1 2.9 21 4 21C4 21.55 4.45 22 5 22H19C19.55 22 20 21.55 20 21C21.1 21 22 20.1 22 19V13H20ZM20 19H4V15H20V19Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/BULB */
export const BULB = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M9 21C9 21.55 9.45 22 10 22H14C14.55 22 15 21.55 15 21V20H9V21ZM12 2C8.14 2 5 5.14 5 9C5 11.38 6.19 13.47 8 14.74V17C8 17.55 8.45 18 9 18H15C15.55 18 16 17.55 16 17V14.74C17.81 13.47 19 11.38 19 9C19 5.14 15.86 2 12 2ZM14.85 13.1L14 13.7V16H10V13.7L9.15 13.1C7.8 12.16 7 10.63 7 9C7 6.24 9.24 4 12 4C14.76 4 17 6.24 17 9C17 10.63 16.2 12.16 14.85 13.1Z" fill={fill}/>
    </svg>
);

export const BURGER = ({ fill = "#000" }) => (
  <svg width="20" height="14" viewBox="0 0 20 14" fill="none">
      <rect width="20" height="2" fill={fill}/>
      <rect y="6" width="20" height="2" fill={fill}/>
      <rect y="12" width="20" height="2" fill={fill}/>
  </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/CLOSE */
export const CLOSE = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/CUTLERY */
export const CUTLERY = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M16 7.08C16 8.85 15.16 10.33 14 10.9V21H12V10.9C10.84 10.33 10 8.85 10 7.08C10.01 4.83 11.35 3 13 3C14.66 3 16 4.83 16 7.08ZM17 3V21H19V13H21V7C21 5.24 19.76 3 17 3ZM8.28 3C7.88 3 7.56 3.32 7.56 3.72V7H6.72V3.72C6.72 3.32 6.4 3 6 3C5.6 3 5.28 3.32 5.28 3.72V7H4.44V3.72C4.44 3.32 4.12 3 3.72 3C3.32 3 3 3.32 3 3.72V9C3 10.1 3.9 11 5 11V21H7V11C8.1 11 9 10.1 9 9V3.72C9 3.32 8.68 3 8.28 3Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/EARPHONES */
export const EARPHONES = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M19 14V18H17V14H19ZM7 14V18H6C5.45 18 5 17.55 5 17V14H7ZM12 1C7.03 1 3 5.03 3 10V17C3 18.66 4.34 20 6 20H9V12H5V10C5 6.13 8.13 3 12 3C15.87 3 19 6.13 19 10V12H15V20H19V21H12V23H18C19.66 23 21 21.66 21 20V10C21 5.03 16.97 1 12 1Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/HEART */
export const HEART = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5 2.8252C14.76 2.8252 13.09 3.6352 12 4.9152C10.91 3.6352 9.24 2.8252 7.5 2.8252C4.42 2.8252 2 5.2452 2 8.3252C2 12.1052 5.4 15.1852 10.55 19.8652L12 21.1752L13.45 19.8552C18.6 15.1852 22 12.1052 22 8.3252C22 5.2452 19.58 2.8252 16.5 2.8252ZM12.1 18.3752L12 18.4752L11.9 18.3752C7.14 14.0652 4 11.2152 4 8.3252C4 6.3252 5.5 4.8252 7.5 4.8252C9.04 4.8252 10.54 5.8152 11.07 7.1852H12.94C13.46 5.8152 14.96 4.8252 16.5 4.8252C18.5 4.8252 20 6.3252 20 8.3252C20 11.2152 16.86 14.0652 12.1 18.3752Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/HOME */
export const HOME = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M12 4L6 8.58V7H4V10.11L1 12.4L2.21 13.99L4 12.62V22H20V12.62L21.79 13.98L23 12.4L12 4ZM18 20H13V16H11V20H6V11.1L12 6.52L18 11.1V20ZM10 2C10 3.66 8.66 5 7 5C6.45 5 6 5.45 6 6H4C4 4.34 5.34 3 7 3C7.55 3 8 2.55 8 2H10Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/INFO */
export const INFO = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/PHONE */
export const PHONE = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M15 12H17C17 9.24 14.76 7 12 7V9C13.66 9 15 10.34 15 12ZM19 12H21C21 7.03 16.97 3 12 3V5C15.87 5 19 8.13 19 12ZM20 15.5C18.75 15.5 17.55 15.3 16.43 14.93C16.33 14.9 16.22 14.88 16.12 14.88C15.86 14.88 15.61 14.98 15.41 15.17L13.21 17.37C10.38 15.93 8.06 13.62 6.62 10.78L8.82 8.57C9.1 8.31 9.18 7.92 9.07 7.57C8.7 6.45 8.5 5.25 8.5 4C8.5 3.45 8.05 3 7.5 3H4C3.45 3 3 3.45 3 4C3 13.39 10.61 21 20 21C20.55 21 21 20.55 21 20V16.5C21 15.95 20.55 15.5 20 15.5ZM5.03 5H6.53C6.6 5.88 6.75 6.75 6.98 7.58L5.78 8.79C5.38 7.58 5.12 6.32 5.03 5ZM19 18.97C17.68 18.88 16.4 18.62 15.2 18.21L16.4 17.01C17.25 17.25 18.12 17.4 19 17.46V18.97Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/PROJECT */
export const PROJECT = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M20.8387 7.40115C21.2287 7.01115 21.2287 6.38115 20.8387 5.99115L18.0087 3.16115C17.6187 2.77115 16.9887 2.77115 16.5987 3.16115L12.1087 7.65115L8.21874 3.76115C7.43874 2.98115 6.16874 2.98115 5.38874 3.76115L3.48874 5.66115C2.70874 6.44115 2.70874 7.71115 3.48874 8.49115L7.37874 12.3812L2.86874 16.8912V21.1312H7.10874L11.6287 16.6112L15.5187 20.5012C16.4687 21.4512 17.7487 21.1012 18.3487 20.5012L20.2487 18.6012C21.0287 17.8212 21.0287 16.5512 20.2487 15.7712L16.3587 11.8812L20.8387 7.40115ZM4.90874 7.07115L6.79874 5.17115L8.06874 6.44115L6.88874 7.63115L8.29874 9.04115L9.48874 7.85115L10.6887 9.05115L8.78874 10.9512L4.90874 7.07115ZM16.1387 14.5112L14.9487 15.7012L16.3587 17.1112L17.5487 15.9212L18.8187 17.1912L16.9187 19.0912L13.0287 15.2012L14.9287 13.3012L16.1387 14.5112ZM6.27874 19.1312H4.86874V17.7212L14.4787 8.11115L15.8887 9.52115L6.27874 19.1312ZM15.8887 6.69115L17.2987 5.28115L18.7087 6.69115L17.2987 8.10115L15.8887 6.69115Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/REFRESH */
export const REFRESH = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M12 6V2L7 7L12 12V8C15.31 8 18 10.69 18 14C18 17.31 15.31 20 12 20C8.69 20 6 17.31 6 14H4C4 18.42 7.58 22 12 22C16.42 22 20 18.42 20 14C20 9.58 16.42 6 12 6Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/SCALE */
export const SCALE = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M13 8.33C13.85 8.03 14.53 7.35 14.83 6.5H18L15 13.5C15 15.16 16.57 16.5 18.5 16.5C20.43 16.5 22 15.16 22 13.5L19 6.5H21V4.5H14.83C14.42 3.33 13.31 2.5 12 2.5C10.69 2.5 9.58 3.33 9.17 4.5H3V6.5H5L2 13.5C2 15.16 3.57 16.5 5.5 16.5C7.43 16.5 9 15.16 9 13.5L6 6.5H9.17C9.47 7.35 10.15 8.03 11 8.33V19.5H2V21.5H22V19.5H13V8.33ZM20.37 13.5H16.63L18.5 9.14L20.37 13.5ZM7.37 13.5H3.63L5.5 9.14L7.37 13.5ZM12 6.5C11.45 6.5 11 6.05 11 5.5C11 4.95 11.45 4.5 12 4.5C12.55 4.5 13 4.95 13 5.5C13 6.05 12.55 6.5 12 6.5Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/SEARCH */
export const SEARCH = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M15.755 14.2549H14.965L14.685 13.9849C15.665 12.8449 16.255 11.3649 16.255 9.75488C16.255 6.16488 13.345 3.25488 9.755 3.25488C6.165 3.25488 3.255 6.16488 3.255 9.75488C3.255 13.3449 6.165 16.2549 9.755 16.2549C11.365 16.2549 12.845 15.6649 13.985 14.6849L14.255 14.9649V15.7549L19.255 20.7449L20.745 19.2549L15.755 14.2549ZM9.755 14.2549C7.26501 14.2549 5.255 12.2449 5.255 9.75488C5.255 7.26488 7.26501 5.25488 9.755 5.25488C12.245 5.25488 14.255 7.26488 14.255 9.75488C14.255 12.2449 12.245 14.2549 9.755 14.2549Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/SHAPES */
export const SHAPES = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M11.5 2L6 11H17L11.5 2ZM11.5 5.84L13.43 9H9.56L11.5 5.84ZM17 13C14.51 13 12.5 15.01 12.5 17.5C12.5 19.99 14.51 22 17 22C19.49 22 21.5 19.99 21.5 17.5C21.5 15.01 19.49 13 17 13ZM17 20C15.62 20 14.5 18.88 14.5 17.5C14.5 16.12 15.62 15 17 15C18.38 15 19.5 16.12 19.5 17.5C19.5 18.88 18.38 20 17 20ZM2.5 21.5H10.5V13.5H2.5V21.5ZM4.5 15.5H8.5V19.5H4.5V15.5Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/SHARE */
export const SHARE = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M18 16.12C17.24 16.12 16.56 16.42 16.04 16.89L8.91 12.74C8.96 12.51 9 12.28 9 12.04C9 11.8 8.96 11.57 8.91 11.34L15.96 7.23004C16.5 7.73004 17.21 8.04004 18 8.04004C19.66 8.04004 21 6.70004 21 5.04004C21 3.38004 19.66 2.04004 18 2.04004C16.34 2.04004 15 3.38004 15 5.04004C15 5.28004 15.04 5.51004 15.09 5.74004L8.04 9.85004C7.5 9.35004 6.79 9.04004 6 9.04004C4.34 9.04004 3 10.38 3 12.04C3 13.7 4.34 15.04 6 15.04C6.79 15.04 7.5 14.73 8.04 14.23L15.16 18.39C15.11 18.6 15.08 18.82 15.08 19.04C15.08 20.65 16.39 21.96 18 21.96C19.61 21.96 20.92 20.65 20.92 19.04C20.92 17.43 19.61 16.12 18 16.12ZM18 4.04004C18.55 4.04004 19 4.49004 19 5.04004C19 5.59004 18.55 6.04004 18 6.04004C17.45 6.04004 17 5.59004 17 5.04004C17 4.49004 17.45 4.04004 18 4.04004ZM6 13.04C5.45 13.04 5 12.59 5 12.04C5 11.49 5.45 11.04 6 11.04C6.55 11.04 7 11.49 7 12.04C7 12.59 6.55 13.04 6 13.04ZM18 20.06C17.45 20.06 17 19.61 17 19.06C17 18.51 17.45 18.06 18 18.06C18.55 18.06 19 18.51 19 19.06C19 19.61 18.55 20.06 18 20.06Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/SHIELD */
export const SHIELD = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM12 11.99H19C18.47 16.11 15.72 19.78 12 20.93V12H5V6.3L12 3.19V11.99Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/SNOWFLAKE */
export const SNOWFLAKE = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M22 11H17.83L21.07 7.76L19.66 6.34L15 11H13V9L17.66 4.34L16.24 2.93L13 6.17V2H11V6.17L7.76 2.93L6.34 4.34L11 9V11H9L4.34 6.34L2.93 7.76L6.17 11H2V13H6.17L2.93 16.24L4.34 17.66L9 13H11V15L6.34 19.66L7.76 21.07L11 17.83V22H13V17.83L16.24 21.07L17.66 19.66L13 15V13H15L19.66 17.66L21.07 16.24L17.83 13H22V11Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/STAR */
export const STAR = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M22 9.74L14.81 9.12L12 2.5L9.19 9.13L2 9.74L7.46 14.47L5.82 21.5L12 17.77L18.18 21.5L16.55 14.47L22 9.74ZM12 15.9L8.24 18.17L9.24 13.89L5.92 11.01L10.3 10.63L12 6.6L13.71 10.64L18.09 11.02L14.77 13.9L15.77 18.18L12 15.9Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/STAR_FILLED */
export const STAR_FILLED = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M12 17.77L18.18 21.5L16.54 14.47L22 9.74L14.81 9.13L12 2.5L9.19 9.13L2 9.74L7.46 14.47L5.82 21.5L12 17.77Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/STAR_HALF_FILLED */
export const STAR_HALF_FILLED = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M22 9.74L14.81 9.12L12 2.5L9.19 9.13L2 9.74L7.46 14.47L5.82 21.5L12 17.77L18.18 21.5L16.55 14.47L22 9.74ZM12 15.9V6.6L13.71 10.64L18.09 11.02L14.77 13.9L15.77 18.18L12 15.9Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/TICK */
export const TICK = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M8.79502 15.8749L4.62502 11.7049L3.20502 13.1149L8.79502 18.7049L20.795 6.70492L19.385 5.29492L8.79502 15.8749Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/TRADE */
export const TRADE = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M9.01 14H2V16H9.01V19L13 15L9.01 11V14ZM14.99 13V10H22V8H14.99V5L11 9L14.99 13Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/TRASH */
export const TRASH = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M16 9V19H8V9H16ZM14.5 3H9.5L8.5 4H5V6H19V4H15.5L14.5 3ZM18 7H6V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/TRUCK */
export const TRUCK = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M20 8H17V4H3C1.9 4 1 4.9 1 6V17H3C3 18.66 4.34 20 6 20C7.66 20 9 18.66 9 17H15C15 18.66 16.34 20 18 20C19.66 20 21 18.66 21 17H23V12L20 8ZM19.5 9.5L21.46 12H17V9.5H19.5ZM6 18C5.45 18 5 17.55 5 17C5 16.45 5.45 16 6 16C6.55 16 7 16.45 7 17C7 17.55 6.55 18 6 18ZM8.22 15C7.67 14.39 6.89 14 6 14C5.11 14 4.33 14.39 3.78 15H3V6H15V15H8.22ZM18 18C17.45 18 17 17.55 17 17C17 16.45 17.45 16 18 16C18.55 16 19 16.45 19 17C19 17.55 18.55 18 18 18Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/USER */
export const USER = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M12 6C13.1 6 14 6.9 14 8C14 9.1 13.1 10 12 10C10.9 10 10 9.1 10 8C10 6.9 10.9 6 12 6ZM12 15C14.7 15 17.8 16.29 18 17V18H6V17.01C6.2 16.29 9.3 15 12 15ZM12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM12 13C9.33 13 4 14.34 4 17V20H20V17C20 14.34 14.67 13 12 13Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/WARNING */
export const WARNING = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/WINDOW */
export const WINDOW = ({ fill, ...rest }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" {...rest} xmlns="http://www.w3.org/2000/svg">
        <path d="M20 2H4C2.9 2 2 2.9 2 4V20C2 21.1 2.9 22 4 22H20C21.1 22 22 21.1 22 20V4C22 2.9 21.1 2 20 2ZM20 11H13V4H20V11ZM11 4V11H4V4H11ZM4 13H11V20H4V13ZM13 20V13H20V20H13Z" fill={fill}/>
    </svg>
);

/** @namespace Scandipwa/Component/Icons/Config/HOUSE */
export const HOUSE = ({ fill, ...rest }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
        <path d="M10 3.19L15 7.69V15.5H13V9.5H7V15.5H5V7.69L10 3.19ZM10 0.5L0 9.5H3V17.5H9V11.5H11V17.5H17V9.5H20L10 0.5Z" fill="#10069F"/>
    </svg>
);

export const NO_MATCH = ({fill, ...rest}) => (
    <svg width="252" height="245" viewBox="0 0 252 245" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M126 0L213 150.688H39L126 0Z" fill="#4D53C6"/>
        <path d="M126.336 0L126.335 150.688H39.3359L126.336 0Z" fill="#5157E0"/>
        <path d="M130.602 53.6982L129.99 98.7735H122.886L122.273 53.6982H130.602ZM126.438 116.902C124.927 116.902 123.631 116.361 122.549 115.279C121.467 114.197 120.926 112.9 120.926 111.39C120.926 109.879 121.467 108.583 122.549 107.501C123.631 106.419 124.927 105.878 126.438 105.878C127.948 105.878 129.245 106.419 130.327 107.501C131.409 108.583 131.95 109.879 131.95 111.39C131.95 112.39 131.694 113.309 131.184 114.146C130.694 114.983 130.031 115.656 129.194 116.167C128.377 116.657 127.458 116.902 126.438 116.902Z" fill="white"/>
        <path d="M0 228.883V213.805L32.7734 162.164H46.1719V182.633H38.3984L19.2188 213.024V213.649H66.6797V228.883H0ZM38.6328 242.164V224.274L39.0234 217.672V162.164H57.1094V242.164H38.6328Z" fill="#5157E0"/>
        <path d="M125.835 244.117C118.856 244.117 112.84 242.464 107.788 239.156C102.736 235.823 98.843 231.045 96.1086 224.821C93.3742 218.571 92.0201 211.058 92.0461 202.281C92.0721 193.505 93.4393 186.058 96.1477 179.938C98.882 173.792 102.762 169.117 107.788 165.914C112.84 162.685 118.856 161.071 125.835 161.071C132.814 161.071 138.83 162.685 143.882 165.914C148.96 169.117 152.866 173.792 155.601 179.938C158.335 186.084 159.689 193.531 159.663 202.281C159.663 211.084 158.296 218.61 155.562 224.86C152.827 231.11 148.934 235.888 143.882 239.196C138.856 242.477 132.84 244.117 125.835 244.117ZM125.835 228.297C130.002 228.297 133.374 226.175 135.952 221.93C138.53 217.659 139.807 211.11 139.78 202.281C139.78 196.5 139.195 191.735 138.023 187.985C136.851 184.209 135.223 181.396 133.14 179.547C131.057 177.698 128.622 176.774 125.835 176.774C121.695 176.774 118.348 178.87 115.796 183.063C113.244 187.229 111.955 193.636 111.929 202.281C111.903 208.141 112.463 212.998 113.609 216.852C114.78 220.706 116.421 223.584 118.53 225.485C120.64 227.36 123.075 228.297 125.835 228.297Z" fill="#5157E0"/>
        <path d="M251.711 229.183V214L218.562 162H205.01V182.611H212.873L232.272 213.214V213.843H184.268V229.183H251.711ZM212.636 242.557V224.542L212.241 217.894V162H193.948V242.557H212.636Z" fill="#5157E0"/>
    </svg>
)
export const DOWNLOAD = ({fill, ...rest}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
        <path d="M16 11L12 15M12 15L8 11M12 15V3M21 15V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V15" stroke="#10069F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)

export const CHANGE_PRODUCT = ({fill, ...rest}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none" {...rest}>
        <path d="M7.51 9.5H0.5V11.5H7.51V14.5L11.5 10.5L7.51 6.5V9.5ZM13.49 8.5V5.5H20.5V3.5H13.49V0.5L9.5 4.5L13.49 8.5Z" fill={fill}/>
    </svg>
)

export const COPY_CODE = ({...rest}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M1.5 22.5H15V18H16.5V22.5V24H15H1.5H0V22.5V9V7.5H1.5H6V9H1.5V22.5ZM9 15H22.5V1.5H9V15ZM7.5 16.5V15V1.5V0H9H22.5H24V1.5V15V16.5H22.5H9H7.5Z" fill="#10069F"/>
    </svg>
)

export const CODE_LABEL = ({...rest}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
        <path d="M32 45.9844L45.9062 32L30.9062 17H17V30.9844L32 45.9844ZM47.7031 30.2812L49.5 32L47.7031 33.7969L33.7188 47.7812L32 49.5L30.2031 47.7812L14.5 32V17V14.5H17H32L47.7031 30.2812ZM23.25 25.125C22.1562 25.125 21.375 24.3438 21.375 23.25C21.375 22.2344 22.1562 21.375 23.25 21.375C24.2656 21.375 25.125 22.2344 25.125 23.25C25.125 24.3438 24.2656 25.125 23.25 25.125Z" fill="#10069F"/>
    </svg>
)

export const CHEVRON_DOUBLE = () => (
    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.41 8.09375L6 3.3747L10.59 8.09375L12 6.64094L6 0.458783L1.27008e-07 6.64094L1.41 8.09375Z" fill="#4F5759"/>
        <path d="M1.41 15L6 10.281L10.59 15L12 13.5472L6 7.36503L1.27008e-07 13.5472L1.41 15Z" fill="#4F5759"/>
    </svg>
)

export const ENVELOPE = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path d="M22 6.80078C22 5.70078 21.1 4.80078 20 4.80078H4C2.9 4.80078 2 5.70078 2 6.80078V18.8008C2 19.9008 2.9 20.8008 4 20.8008H20C21.1 20.8008 22 19.9008 22 18.8008V6.80078ZM20 6.80078L12 11.8008L4 6.80078H20ZM20 18.8008H4V8.80078L12 13.8008L20 8.80078V18.8008Z" fill="#10069F"/>
    </svg>
)
