export * from 'SourceStore/Checkout/Checkout.action';

export const SET_DELIVERY_METHOD = 'SET_DELIVERY_METHOD';
export const SET_DELIVERY_POINT = 'SET_DELIVERY_POINT';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_BLUE_GATEWAY_LIST = 'SET_BLUE_GATEWAY_LIST';
export const SET_BLUE_PAYMENT_GATEWAY_ID = 'SET_BLUE_PAYMENT_GATEWAY_ID';
export const SET_AGREEMENTS_STATUS = 'SET_AGREEMENTS_STATUS';
export const SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS';

/** @namespace Pwa/Store/Checkout/Action/setDeliveryMethod */
export const setDeliveryMethod = (method) => ({
    type: SET_DELIVERY_METHOD,
    method,
});

/** @namespace Pwa/Store/Checkout/Action/setDeliveryPoint */
export const setDeliveryPoint = (point) => ({
    type: SET_DELIVERY_POINT,
    point,
});

/** @namespace Pwa/Store/Checkout/Action/setPaymentMethod */
export const setPaymentMethod = (paymentMethod) => ({
    type: SET_PAYMENT_METHOD,
    paymentMethod,
});

/** @namespace Pwa/Store/Checkout/Action/setBluePaymentGatewayList */
export const setBluePaymentGatewayList = (blueGatewaysArray) => ({
    type: SET_BLUE_GATEWAY_LIST,
    blueGatewaysArray,
});

/** @namespace Pwa/Store/Checkout/Action/setBluePaymentGateway */
export const setBluePaymentGateway = (gatewayId) => ({
    type: SET_BLUE_PAYMENT_GATEWAY_ID,
    gatewayId,
});

/** @namespace Pwa/Store/Checkout/Action/setAgreementsStatus */
export const setAgreementsStatus = (isAllRequiredAgreementsSelected) => ({
    type: SET_AGREEMENTS_STATUS,
    isAllRequiredAgreementsSelected,
});

/** @namespace Pwa/Store/Checkout/Action/setShippingAddress */
export const setShippingAddress = (shippingAddress) => ({
    type: SET_SHIPPING_ADDRESS,
    shippingAddress,
});
