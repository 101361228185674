import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './SliderProgressBar.style';

/** @namespace Pwa/Component/SliderProgressBar/Component/SliderProgressBar */
export class SliderProgressBar extends PureComponent {
    static propTypes = {
        currentSlide: PropTypes.number.isRequired,
        numberOfSlides: PropTypes.number.isRequired,
    };

    render() {
        const { currentSlide, numberOfSlides } = this.props;
        const partOfProgressBar = currentSlide / numberOfSlides;
        return (
            <div block="SliderProgressBar">
                <div block="SliderProgressBar" elem="Filler" style={{ transform: `scaleX(${partOfProgressBar})` }} />
            </div>
        );
    }
}

export default SliderProgressBar;
