import { isMobile as sourceIsMobile, isMobileClientHints, isUsingClientHints } from 'SourceUtil/Mobile/isMobile';

export { isUsingClientHints, isMobileClientHints };

export const isMobile = {
    ...sourceIsMobile,
    any: () => window.matchMedia('(max-width: 767px)').matches,
    tablet: () => window.matchMedia('(min-width:768px) and (max-width: 1023px)').matches,
    isFirefox: () => navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
};

export default isMobile;
