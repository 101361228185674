import { STAR_EMPTY, STAR_FULL, STAR_HALF_FULL } from 'Component/StarIcon/StarIcon.config';
import { ProductReviewRating as SourceProductReviewRating } from 'SourceComponent/ProductReviewRating/ProductReviewRating.component';

import './ProductReviewRating.override.style';

/** @namespace Pwa/Component/ProductReviewRating/Component/ProductReviewRating */
export class ProductReviewRating extends SourceProductReviewRating {
    render() {
        const { summary, placeholder, mix, count } = this.props;

        const [fullCount, halfFullCount, emptyCount] = this.getStarCounts();

        if (placeholder) {
            return this.renderPlaceholder();
        }

        return (
            <div block="ProductReviewRating" title={`${summary}%`} ref={this.reviewRating} mix={mix}>
                {this.renderStar(fullCount, STAR_FULL)}
                {this.renderStar(halfFullCount, STAR_HALF_FULL)}
                {this.renderStar(emptyCount, STAR_EMPTY)}
                <span block="ProductReviewRating" elem="Counter">
                    {`(${count})`}
                </span>
            </div>
        );
    }
}

export default ProductReviewRating;
