const appendImageToInitialState = (args, callback) => {
  return {
    ...callback(...args),
    image: '',
  };
};

const appendImageToProductMeta = (args, callback) => {
  const ctxTags = callback(...args);
  const image = args[0]?.small_image?.url;

  return { ...ctxTags, image };
};

export default {
  'Store/Meta/Reducer/getInitialState': {
    'member-function': {
      getInitialState: appendImageToInitialState,
    },
  },
  'Util/Meta/Dispatcher': {
    'member-function': {
      _getProductMeta: appendImageToProductMeta,
    },
  },
};
