import PropTypes from 'prop-types';

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        isTablet: state.ConfigReducer.device.isTablet,
    };
};

export class CategoryPageRouteContainerPlugin {
    propTypes = (originalMember) => ({
        ...originalMember,
        isTablet: PropTypes.bool.isRequired,
    });

    containerProps = (args, callback, instance) => {
        const { isTablet } = instance.props;
        return {
            ...callback.apply(instance, args),
            isTablet,
        };
    };
}

const { propTypes, containerProps } = new CategoryPageRouteContainerPlugin();

export default {
    'Route/CategoryPage/Container/mapStateToProps': {
        function: mapStateToProps,
    },
    'Route/CategoryPage/Container': {
        'static-member': {
            propTypes,
        },
        'member-function': {
            containerProps,
        },
    },
};
