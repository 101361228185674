import PropTypes from 'prop-types';

import { SEARCH_URL, SEARCHING_URL } from 'Route/CategoryPage/CategoryPage.config.js';
import history from 'Util/History';

import PromotionalBannerDispatcher from '../store/PromotionalBanner/PromotionalBanner.dispatcher';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        getPromotionalBanners: (category_id) =>
            PromotionalBannerDispatcher.getPromotionalBanners(category_id, dispatch),
    };
};

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        promotional_banners: state.PromotionalBannersReducer.promotional_banners,
    };
};

const propTypes = (originalMember) => ({
    ...originalMember,
    promotional_banners: PropTypes.instanceOf(Array).isRequired,
});

export class CategoryPageContainerPlugin {
    componentDidMount(args, callback, instance) {
        callback.apply(instance, args);

        const {
            getPromotionalBanners,
            category: { id },
        } = instance.props;

        const {
            location: { pathname },
        } = history;

        const isSearchPage = pathname.includes(SEARCHING_URL) || pathname.includes(SEARCH_URL);

        if (id && !isSearchPage) {
            getPromotionalBanners(id);
        }
    }

    componentDidUpdate(args, callback, instance) {
        callback.apply(instance, args);

        const {
            getPromotionalBanners,
            category: { id },
        } = instance.props;

        const [prevProps] = args;

        const {
            location: { pathname },
        } = history;

        const {
            category: { id: prevId },
        } = prevProps;

        const isSearchPage = pathname.includes(SEARCHING_URL) || pathname.includes(SEARCH_URL);

        if (id !== prevId && !isSearchPage) {
            getPromotionalBanners(id);
        }
    }

    updatePromotionalBanners() {}

    containerProps(args, callback, instance) {
        const { promotional_banners } = instance.props;
        return {
            ...callback.apply(instance, args),
            promotional_banners,
        };
    }
}

const { componentDidMount, componentDidUpdate, containerProps } = new CategoryPageContainerPlugin();

export default {
    'Route/CategoryPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
    'Route/CategoryPage/Container/mapStateToProps': {
        function: mapStateToProps,
    },
    'Route/CategoryPage/Container': {
        'member-function': {
            componentDidMount,
            componentDidUpdate,
            containerProps,
        },
        'static-member': {
            propTypes,
        },
    },
};
