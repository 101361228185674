import { ExpandableContentShowMore as SourceExpandableContentShowMore } from 'SourceComponent/ExpandableContentShowMore/ExpandableContentShowMore.component';

import './ExpandableContentShowMore.override.style';

/** @namespace Pwa/Component/ExpandableContentShowMore/Component/ExpandableContentShowMore */
export class ExpandableContentShowMore extends SourceExpandableContentShowMore {
    __construct(props) {
        super.__construct(props);

        this.state = { isOpen: false };
    }

    componentDidMount() {}

    componentDidUpdate() {}

    handleShowAllButtonClick() {
        this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
    }

    renderShowAllButton() {
        const {
            showElemCount,
            children: { length },
            isLoading,
        } = this.props;

        if (length <= showElemCount) {
            return null;
        }

        const { isOpen } = this.state;

        const mods = isOpen ? { state: 'isOpen' } : {};

        return (
            <button
                onClick={this.handleShowAllButtonClick}
                mix={{ block: 'Button', mods: { isHollow: true } }}
                block="ExpandableContentShowMore"
                elem="ShowAllButton"
                mods={mods}
                disabled={isLoading}
            >
                {isOpen ? __('Show less') : __('Show more')}
            </button>
        );
    }

    renderExpandableChildren() {
        const { isOpen } = this.state;
        const { children, showElemCount } = this.props;

        const finalChildren = isOpen ? children.slice(showElemCount) : null;
        const style = {
            height: isOpen ? 'auto' : 0,
        };

        return (
            <div block="ExpandableContentShowMore" elem="ExpandableChildren" style={style}>
                {finalChildren}
            </div>
        );
    }

    renderContent() {
        const { children, showElemCount } = this.props;

        const child = children.slice(0, showElemCount);

        return (
            <>
                {child}
                {this.renderExpandableChildren()}
                {this.renderShowAllButton()}
            </>
        );
    }

    render() {
        const { children, isMobile } = this.props;

        if (isMobile) {
            return children;
        }

        return <div block="ExpandableContentShowMore">{this.renderContent()}</div>;
    }
}

export default ExpandableContentShowMore;
