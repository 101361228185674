import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import './Accordion.style';

/** @namespace Pwa/Component/Accordion/Component/Accordion */
export class Accordion extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
    };

    static defaultProps = {
        title: '',
    };

    state = {
        isOpen: false,
    };

    toggleAccordion() {
        this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    }

    render() {
        const { title, children } = this.props;
        const { isOpen } = this.state;

        const isAccordionChild =
            React.Children.toArray(children).filter((child) => child?.type === Accordion)?.length > 0;

        return (
            <div block="Accordion" mods={{ isSecondary: true }}>
                <div block="Accordion" elem="Header" onClick={this.toggleAccordion.bind(this)}>
                    <span block="Accordion" elem="Icon" mods={{ isOpen }} />
                    <span block="Accordion" elem="Title">
                        {title}
                    </span>
                </div>
                {isOpen ? (
                    <div block="Accordion" elem="Content" mods={{ isAccordionChild }}>
                        {children}
                    </div>
                ) : null}
            </div>
        );
    }
}

export default Accordion;
