import {
    formatURI,
    getFetch,
    getGraphqlEndpoint,
    getWindowId,
    handleConnectionError,
    HTTP_201_CREATED,
    HTTP_410_GONE,
    HTTP_503_SERVICE_UNAVAILABLE,
    postFetch,
    putPersistedQuery,
} from 'SourceUtil/Request/Request';
import { isSignedIn, refreshAuthorizationToken } from 'Util/Auth';
import { refreshUid } from 'Util/Compare';
import { captureGraphqlException } from 'Util/Sentry';

export * from 'SourceUtil/Request/Request';

/** @namespace Pwa/Util/Request/checkForErrors */
export const checkForErrors = (res, options) =>
    new Promise((resolve, reject) => {
        const { errors, data } = res;

        if (errors) {
            captureGraphqlException(errors, options);

            if (Array.isArray(errors)) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return reject([...errors, data]);
            }

            return reject(errors);
        }

        return resolve(data);
    });

/** @namespace Pwa/Util/Request/parseResponse */
export const parseResponse = (promise, options) =>
    new Promise((resolve, reject) => {
        promise.then(
            /** @namespace Pwa/Util/Request/then */
            (res) =>
                res.json().then(
                    /** @namespace Pwa/Util/Request/json/then */
                    (res) => resolve(checkForErrors(res, options)),
                    /** @namespace Pwa/Util/Request/json/then */
                    () => {
                        captureGraphqlException('Can not transform JSON!', options);
                        handleConnectionError('Can not transform JSON!');
                        return reject();
                    }
                ),
            /** @namespace Pwa/Util/Request/then */
            (error) => {
                captureGraphqlException('Can not establish connection!', options);
                handleConnectionError('Can not establish connection!');
                return reject(error);
            }
        );
    });

/** @namespace Pwa/Util/Request/executeGet */
export const executeGet = (queryObject, name, cacheTTL) => {
    const { query, variables } = queryObject;
    const uri = formatURI(query, variables, getGraphqlEndpoint());

    if (isSignedIn()) {
        refreshAuthorizationToken();
        refreshUid();
    }

    return parseResponse(
        new Promise((resolve, reject) => {
            getFetch(uri, name).then(
                /** @namespace Pwa/Util/Request/getFetch/then */
                (res) => {
                    if (res.status === HTTP_410_GONE) {
                        putPersistedQuery(getGraphqlEndpoint(), query, cacheTTL).then(
                            /** @namespace Pwa/Util/Request/putPersistedQuery/then */
                            (putResponse) => {
                                if (putResponse.status === HTTP_201_CREATED) {
                                    getFetch(uri, name).then(
                                        /** @namespace Pwa/Util/Request/getFetch/then */
                                        (res) => resolve(res)
                                    );
                                }
                            }
                        );
                    } else if (res.status === HTTP_503_SERVICE_UNAVAILABLE) {
                        reject(res);
                    } else {
                        resolve(res);
                    }
                }
            );
        }),
        { query, variables }
    );
};

/** @namespace Pwa/Util/Request/executePost */
export const executePost = (queryObject) => {
    const { query, variables } = queryObject;

    if (isSignedIn()) {
        refreshAuthorizationToken();
        refreshUid();
    }

    return parseResponse(postFetch(getGraphqlEndpoint(), query, variables), {
        query,
        variables,
    });
};

/** @namespace Pwa/Util/Request/listenForBroadCast */
export const listenForBroadCast = (name) =>
    new Promise((resolve) => {
        const { BroadcastChannel } = window;
        const windowId = getWindowId();

        if (BroadcastChannel) {
            const bc = new BroadcastChannel(`${name}_${windowId}`);
            bc.onmessage = (update) => {
                const {
                    data: { payload: body },
                } = update;

                resolve(checkForErrors(body));
            };
        }
    });
