import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation, fetchQuery } from 'Util/Request';

import InpostQuery from '../../query/Inpost.query';
import CheckoutInpost from './CheckoutInpost.component';
import { CHECKOUT_INPOST_POPUP_ID } from './CheckoutInpost.config';

/** @namespace Domneo/Inpost/Component/CheckoutInpost/Container/mapStateToProps */
export const mapStateToProps = () => ({});
/** @namespace Domneo/Inpost/Component/CheckoutInpost/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(CHECKOUT_INPOST_POPUP_ID, payload)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
});
/** @namespace Domneo/Inpost/Component/CheckoutInpost/Container/CheckoutInpostContainer */
export class CheckoutInpostContainer extends PureComponent {
    static propTypes = {
        showPopup: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
    };

    state = {
        googleApiKey: null,
        parcelLocker: null,
        inpostStyles: document.createElement('link'),
    };

    containerFunctions = {
        showInpostPopup: this.showInpostPopup.bind(this),
        setParcelLocker: this.setParcelLocker.bind(this),
        hidePopup: this.hidePopup.bind(this),
    };

    componentDidMount() {
        this.getGoogleApiKey();
        this.getParcelLocker();
        this.inpostSettings();
    }

    componentDidUpdate(prevProps, prevState) {
        const { parcelLocker } = this.state;
        const { parcelLocker: prevParcelLocker } = prevState;

        if (parcelLocker) {
            const { cart_id, parcel_locker, parcel_locker_address } = parcelLocker;

            if (parcel_locker && parcel_locker !== prevParcelLocker?.parcel_locker) {
                const guest_cart_id = !isSignedIn() ? getGuestQuoteId() : '';

                this.setParcelLocker({
                    cart_id: guest_cart_id || cart_id,
                    parcel_locker,
                    parcel_locker_address: `${parcel_locker_address.line1} ${parcel_locker_address.line2}`,
                });
            }
        }
    }

    inpostSettings() {
        const { inpostStyles } = this.state;
        inpostStyles.setAttribute('rel', 'stylesheet');
        inpostStyles.setAttribute('type', 'text/css');
        inpostStyles.setAttribute('href', 'https://geowidget.easypack24.net/css/easypack.css');

        return document.head.appendChild(inpostStyles);
    }

    showInpostPopup() {
        const { showPopup } = this.props;
        showPopup({ action: CHECKOUT_INPOST_POPUP_ID, title: __('Choose a parcel locker') });
    }

    hidePopup() {
        const { hideActiveOverlay } = this.props;
        this.getParcelLocker();
        hideActiveOverlay();
    }

    containerProps = () => {
        const { googleApiKey, parcelLocker } = this.state;

        return { googleApiKey, parcelLocker };
    };

    getGoogleApiKey = () => {
        const query = InpostQuery.getGoogleApiKey();

        fetchQuery(query).then(
            /** @namespace Domneo/Inpost/Component/CheckoutInpost/Container/fetchQuery/then */
            ({ getGoogleApiKey: { key } }) => {
                this.setState({ googleApiKey: key });
            }
        );
    };

    getParcelLocker = () => {
        const parcelLocker = JSON.parse(sessionStorage.getItem('parcelLocker'));
        this.setState({ parcelLocker });
    };

    setParcelLocker(parcelLocker) {
        fetchMutation(InpostQuery.setParcelLockerToQuoteMutation(parcelLocker));
    }

    render() {
        return <CheckoutInpost {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutInpostContainer);
