import { cloneElement } from 'react';

export class ProductListComponentPlugin {
    renderPage(args, callback, instance) {
        const [children] = args;
        const originalLink = callback(...args);
        const { promotional_banners } = instance.props;

        return cloneElement(originalLink, { promotionalBanners: promotional_banners }, children);
    }
}

export const { renderPage } = new ProductListComponentPlugin();

export default {
    'Component/ProductList/Component': {
        'member-function': {
            renderPage,
        },
    },
};
