import { DEFAULT_SLICK_SETTINGS as SOURCE_DEFAULT_SLICK_SETTINGS } from '@domneo/slider/src/components/Slider/Slider.config';

export const DEFAULT_SLICK_SETTINGS = {
    ...SOURCE_DEFAULT_SLICK_SETTINGS,
    cssEase: 'linear',
    adaptiveHeight: true,
    arrows: false,
    speed: 500,
    infinite: false,
};

export const VISIBLE_SLIDES = 6;
