import { Field } from 'Util/Query';

/** @namespace Domneo/Slider/Query/Slider/Query/SliderQuery */
export class SliderQuery {
    getSliderQuery(position, code) {
        const query = new Field('slider')
            .addArgument('position', 'String!', position)
            .addArgument('code', 'String!', code)
            .addField(this._getSliderItemField());

        return query;
    }

    _getSliderItemField() {
        return new Field('slides').addFieldList([
            'body',
            'name',
            'cta_color',
            'cta_link',
            'cta_text',
            'header',
            'image',
            'image_height',
            'image_width',
            'mobile_image',
            'tablet_image',
            'counter_date',
            'counter_enable',
        ]);
    }
}

export default new SliderQuery();
