import ChevronIcon from 'Component/ChevronIcon';
import Icons from 'Component/Icons';
import Link from 'Component/Link';
import { Breadcrumb as SourceBreadcrumb } from 'SourceComponent/Breadcrumb/Breadcrumb.component';

import './Breadcrumb.override.style';

/** @namespace Pwa/Component/Breadcrumb/Component/Breadcrumb */
export class Breadcrumb extends SourceBreadcrumb {
    renderLink() {
        const { index, isDisabled, isHomePage, baseUrl } = this.props;

        const url = this.getLinkUrl() || {};

        if (isHomePage) {
            return (
                <Link block="Breadcrumb" elem="Link" to={baseUrl} tabIndex={isDisabled ? '-1' : '0'}>
                    <meta itemProp="item" content={baseUrl} />
                    <span block="Breadcrumb" elem="Name" itemProp="name" content={__('Home page')}>
                        <Icons name="house" />
                    </span>
                    <ChevronIcon />
                    <meta itemProp="position" content={index} />
                </Link>
            );
        }

        return (
            <Link
                block="Breadcrumb"
                elem="Link"
                to={url.pathname}
                tabIndex={isDisabled ? '-1' : '0'}
                aria-label={isHomePage ? __('Start') : ''}
            >
                <meta itemProp="item" content={url.pathname} />
                <span block="Breadcrumb" elem="Name" itemProp="name">
                    {this.renderName()}
                </span>
                <ChevronIcon />
                <meta itemProp="position" content={index} />
            </Link>
        );
    }
}

export default Breadcrumb;
