import { Field } from 'Util/Query';

/** @namespace Pwa/Query/ReviesSection/Query/ReviewsSliderQuery */
export class ReviewsSliderQuery {
    getQuery() {
        return new Field('promoReviews').addFieldList(this._getReviewsFields()).setAlias('promoReviews');
    }

    _getReviewsFields() {
        return [this._getReviewsField(), 'total_reviews_amount', 'total_reviews_average'];
    }

    _getReviewsField() {
        return new Field('items').addFieldList(this._getReviewFields());
    }

    _getReviewFields() {
        return [this._getProductField(), 'average_rating', 'nickname', 'title', 'text', 'title', 'homepage_available'];
    }

    _getProductField() {
        return new Field('product').addFieldList(this._getProductFields());
    }

    _getProductFields() {
        return ['name', 'sku', 'url'];
    }

    _getNextReviews(productID, reviewSize, reviewPage) {
        return new Field('products')
            .addArgument('filter', 'ProductAttributeFilterInput', { id: { eq: productID } })
            .addField(this._getReviewItemsField(reviewSize, reviewPage));
    }

    _getRatingsBreakdownFields() {
        return [new Field('name').setAlias('rating_code'), 'value'];
    }

    _getRatingsBreakdownField() {
        return new Field('ratings_breakdown').setAlias('rating_votes').addFieldList(this._getRatingsBreakdownFields());
    }

    _getReviewItemsFields() {
        return [
            'average_rating',
            'nickname',
            new Field('summary').setAlias('title'),
            new Field('text').setAlias('detail'),
            'created_at',
            this._getRatingsBreakdownField(),
        ];
    }

    _getReviewItemsField(reviewSize, reviewPage) {
        return new Field('items').addField(this._getNextReviewsField(reviewSize, reviewPage));
    }

    _getReviewsItemsFields() {
        return new Field('items').addFieldList(this._getReviewItemsFields());
    }

    _getNextReviewsFields() {
        return [this._getReviewsItemsFields()];
    }

    _getNextReviewsField(reviewSize, reviewPage) {
        return (
            new Field('reviews')
                // eslint-disable-next-line no-magic-numbers
                .addArgument('pageSize', 'Int', reviewSize || 3)
                .addArgument('currentPage', 'Int', reviewPage || 1)
                .addFieldList(this._getNextReviewsFields())
        );
    }
}

export default new ReviewsSliderQuery();
