/* eslint-disable no-return-assign, fp/no-let */

import { BlogRecentPostsComponent } from '@domneo/blog/src/component/BlogRecentPosts/BlogRecentPosts.component';
import { MAX_RECENT_POSTS } from '@domneo/blog/src/component/BlogRecentPosts/BlogRecentPosts.config';
import { getPostUrl } from '@domneo/blog/src/util/Posts';
import React from 'react';
import SlickSlider from 'react-slick';

import Html from 'Component/Html';
import Icons from 'Component/Icons';
import Image from 'Component/Image';
import Link from 'Component/Link';
import SliderCounterNav from 'Component/SliderCounterNav';
import SliderNext from 'Component/SliderNext';
import SliderPrev from 'Component/SliderPrev';
import SliderProgressBar from 'Component/SliderProgressBar';
import TextPlaceholder from 'Component/TextPlaceholder';

import { BLOG_LIST_URL, BLOG_WIDGET_SLIDER_OPTIONS } from './BlogWidget.config';

import './BlogWidget.style.scss';

/** @namespace Pwa/Component/BlogWidget/Component/BlogWidgetComponent */
export class BlogWidgetComponent extends BlogRecentPostsComponent {
    state = {
        currentSlide: 1,
    };

    __construct() {
        this.nextSlide = this.nextSlide.bind(this);
        this.previousSlide = this.previousSlide.bind(this);
    }

    nextSlide() {
        this.slider.slickNext();
    }

    previousSlide() {
        this.slider.slickPrev();
    }

    getNumberOfSlides() {
        const { posts, slidesToShow } = this.props;

        return posts?.length ? Math.ceil(posts.length / slidesToShow) : 0;
    }

    renderPaging() {
        const { currentSlide } = this.state;

        return (
            <SliderCounterNav
                currentSlide={currentSlide}
                numberOfSlides={this.getNumberOfSlides() || 0}
                goToNextSlide={this.nextSlide}
                goToPrevSlide={this.previousSlide}
            />
        );
    }

    renderProgressBar() {
        const { currentSlide } = this.state;

        return <SliderProgressBar currentSlide={currentSlide} numberOfSlides={this.getNumberOfSlides() || 0} />;
    }

    renderContents() {
        const { posts, isLoaded, slidesToShow } = this.props;
        const post = {};

        const postsAreNotLoaded = Array(MAX_RECENT_POSTS)
            .fill()
            .map((_, i) => this.renderCard(post, i));

        return (
            <>
                <SlickSlider
                    prevArrow={<SliderPrev />}
                    nextArrow={<SliderNext />}
                    beforeChange={(_, nextSlide) => {
                        this.setState({ currentSlide: nextSlide / slidesToShow + 1 });
                    }}
                    ref={(slider) => (this.slider = slider)}
                    {...BLOG_WIDGET_SLIDER_OPTIONS}
                >
                    {!isLoaded ? postsAreNotLoaded : posts.map(this.renderCard)}
                </SlickSlider>
                {this.renderProgressBar()}
            </>
        );
    }

    renderCard(post, key) {
        const { title, first_image, short_content } = post || {};
        const postUrl = getPostUrl(post);

        return (
            <div block="BlogWidget" elem="CardWrapper" key={key}>
                <div block="BlogWidget" elem="Card">
                    <Link to={postUrl} title={title} mix={{ block: 'BlogWidget', elem: 'ImageWrapper' }}>
                        <Image
                            src={first_image}
                            ratio="custom"
                            isPlaceholder={!first_image}
                            block="BlogWidget"
                            elem="Image"
                        />
                    </Link>

                    <div block="BlogWidget" elem="Content">
                        <Link to={postUrl} title={title}>
                            <h3 block="BlogWidget" elem="PostTitle">
                                <TextPlaceholder
                                    mix={{ block: 'BlogWidget', elem: 'PostTitlePlaceholder' }}
                                    content={title}
                                    length="short"
                                />
                            </h3>
                        </Link>

                        {!!short_content && (
                            <div block="BlogWidget" elem="Description">
                                <Html content={short_content} />
                            </div>
                        )}
                    </div>

                    <Link to={postUrl} title={title} mix={{ block: 'BlogWidget', elem: 'ArrowLink' }}>
                        <Icons name="arrow_right" fill="#10069F" />
                    </Link>
                </div>
            </div>
        );
    }

    renderHead() {
        const { title, hiddenSwipe } = this.props;
        return (
            <div block="BlogWidget" elem="Head">
                <h2 block="BlogWidget" elem="Header" mods={{ hiddenSwipe }}>
                    {title}
                </h2>
                {this.renderPaging()}
                <Link to={BLOG_LIST_URL} mix={{ block: 'BlogWidget', elem: 'ShowAll' }}>
                    {__('Show all')}
                </Link>
            </div>
        );
    }

    render() {
        return (
            <div block="BlogWidget">
                {this.renderHead()}
                {this.renderContents()}
            </div>
        );
    }
}

export default BlogWidgetComponent;
