import { IMAGE_HUNDRED_PERCENT } from 'Component/Image/Image.config';
import { ImageContainer as SourceImageContainer } from 'SourceComponent/Image/Image.container';

/** @namespace Pwa/Component/Image/Container/ImageContainer */
export class ImageContainer extends SourceImageContainer {
    getParsedImageSource() {
        const { src } = this.props;

        if (src?.includes('media url=')) {
            try {
                return [window.location.origin, 'media', src.replaceAll('{{media url=', '').replaceAll('}}', '')]
                    .filter(Boolean)
                    .join('/');
            } catch {
                return src;
            }
        }

        return src;
    }

    containerProps() {
        const { children, loadingForCrawlers, magnification, extended, noImgAttributesTransform, width, height } =
            this.props;
        const src = this.getParsedImageSource();

        return {
            ...super.containerProps(),
            sizes: this._getCorrectSize(),
            src,
            children,
            magnification,
            extended,
            noImgAttributesTransform,
            width,
            height,
            loadingForCrawlers,
        };
    }

    _isCached() {
        const { showIsLoading } = this.props;
        const src = this.getParsedImageSource();

        if (!showIsLoading) {
            return false;
        }

        if (window.prefetchedImages && window.prefetchedImages[src] && window.prefetchedImages[src].complete) {
            return true;
        }

        const img = document.createElement('img');
        img.src = src;

        if (img.complete) {
            return true;
        }

        return false;
    }

    _getCorrectSize() {
        const { width, height } = this.props;

        const correctHeight = this._parseSize(height);
        const correctWidth = this._parseSize(width);

        if (correctHeight === IMAGE_HUNDRED_PERCENT && correctWidth === IMAGE_HUNDRED_PERCENT) {
            return {};
        }

        if (correctHeight === IMAGE_HUNDRED_PERCENT && correctWidth) {
            return { width: correctWidth };
        }

        if (correctHeight && correctWidth === IMAGE_HUNDRED_PERCENT) {
            return { height: correctHeight };
        }

        return { width: correctWidth, height: correctHeight };
    }
}

export default ImageContainer;
