import Slider from '@domneo/slider/src/components/Slider';

export const sliderRules = (originalMember, instance) => {
    const replaceSlider = ({ attribs }) => {
        const { 'data-position': position, 'data-code': code } = instance.attributesToProps(attribs);

        return <Slider position={position} code={code} />;
    };

    return [
        ...originalMember,
        {
            query: { attribs: ['data-slider'] },
            replace: replaceSlider,
        },
    ];
};

export const config = {
    'Component/Html/Component': {
        'member-property': {
            rules: sliderRules,
        },
    },
};

export default config;
