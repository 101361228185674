import { parse } from 'cookie';

import GDPRQuery from 'Query/GDPR.query';
import { prepareQuery } from 'Util/Query';
import { fetchMutation } from 'Util/Request';
import { executePost } from 'Util/Request/Request';

export const GDPR_COOKIE = 'amcookie_allowed';

/** @namespace Pwa/Store/GDPR/Dispatcher/GDPRDispatcher */
export class GDPRDispatcher {
    acceptGroups(data) {
        return fetchMutation(GDPRQuery.setCookieGroups(data));
    }

    getGroups(customer) {
        return executePost(prepareQuery(GDPRQuery.getCookieGroups(customer)));
    }

    isAccepted() {
        const isConsentsSelected = !!localStorage.getItem('consentsSelected');
        return (!!parse(document.cookie)[GDPR_COOKIE] && isConsentsSelected) ?? false;
    }

    getAccepted() {
        return parse(document.cookie)[GDPR_COOKIE].split(',') ?? [];
    }

    async saveGroupsOnRegister(code) {
        if (!this.isAccepted(code)) return;

        try {
            await this.acceptGroups(this.getAccepted(code));
        } catch {
            return Promise.resolve();
        }
    }
}

export default new GDPRDispatcher();
