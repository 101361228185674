import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ReviewsSliderQuery from 'Query/ReviesSection.query';
import { showNotification } from 'Store/Notification/Notification.action';
import DataContainer from 'Util/Request/DataContainer';

import ReviewsSection from './ReviewsSection.component';

/** @namespace Pwa/Component/ReviewsSection/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/ReviewsSection/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, title, error) => dispatch(showNotification(type, title, error)),
});

/** @namespace Pwa/Component/ReviewsSection/Container/ReviewsSectionContainer */
export class ReviewsSectionContainer extends DataContainer {
    static propTypes = {
        showNotification: PropTypes.func.isRequired,
    };

    state = {
        total_reviews_amount: 0,
        total_reviews_average: 0,
        reviews: [],
    };

    containerProps() {
        const { reviews, total_reviews_average, total_reviews_amount } = this.state;
        const { device } = this.props;

        return { device, reviews, total_reviews_average, total_reviews_amount };
    }

    componentDidMount() {
        this.requestReviews();
    }

    requestReviews() {
        const { showNotification } = this.props;

        this.fetchData(
            [ReviewsSliderQuery.getQuery()],
            ({ promoReviews: { items, total_reviews_amount, total_reviews_average } }) => {
                const reviewsHomepageAvailable = items.filter((review) => review.homepage_available === 1);
                this.setState({ total_reviews_amount, total_reviews_average, reviews: reviewsHomepageAvailable });
            },
            (e) => showNotification('error', __('Error fetching promoReviews!'), e)
        );
    }

    render() {
        return <ReviewsSection {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsSectionContainer);
