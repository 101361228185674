import Breadcrumb from 'Component/Breadcrumb';
import ContentWrapper from 'Component/ContentWrapper';
import { Breadcrumbs as SourceBreadcrumbs } from 'SourceComponent/Breadcrumbs/Breadcrumbs.component';
import { appendWithStoreCode } from 'Util/Url';

import './Breadcrumbs.override.style';

/** @namespace Pwa/Component/Breadcrumbs/Component/Breadcrumbs */
export class Breadcrumbs extends SourceBreadcrumbs {
    renderBreadcrumb({ url, name }, i) {
        const {
            breadcrumbs,
            device: { isMobile },
            baseUrl,
        } = this.props;
        const isDisabled = !url || breadcrumbs.length - 1 === i;

        if (i === 0 && isMobile) {
            return (
                <Breadcrumb
                    name={name}
                    url={url}
                    index={i + 1}
                    key={i}
                    isDisabled={isDisabled}
                    isHomePage
                    baseUrl={baseUrl}
                />
            );
        }

        return <Breadcrumb name={name} url={url} index={i + 1} key={i} isDisabled={isDisabled} />;
    }

    render() {
        const {
            breadcrumbs,
            areBreadcrumbsVisible,
            device: { isMobile },
            category,
            isPluginBreadcrumb,
        } = this.props;

        const isCategoryPage = !!category?.id;

        if (
            !areBreadcrumbsVisible ||
            location.pathname === '/' ||
            location.pathname === appendWithStoreCode('/') ||
            location.pathname.includes('/thank-you-page') ||
            (isCategoryPage && !isPluginBreadcrumb && isMobile)
        ) {
            return null;
        }

        return (
            <ContentWrapper mix={{ block: 'Breadcrumbs' }} label={__('Breadcrumbs (current location)...')}>
                <nav aria-label="Breadcrumbs navigation">
                    <ul block="Breadcrumbs" elem="List" itemScope itemType="http://schema.org/BreadcrumbList">
                        {breadcrumbs?.length ? this.renderBreadcrumbList(breadcrumbs) : this.renderBreadcrumb({}, 0)}
                    </ul>
                </nav>
            </ContentWrapper>
        );
    }
}

export default Breadcrumbs;
