import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';
import { CategoryTreeType } from 'Type/Category.type';

import './CategoryAdditionalDescription.style.scss';

/** @namespace Domneo/Categoryadditionaldescription/Component/CategoryAdditionalDescription/Component/CategoryAdditionalDescriptionComponent */
export class CategoryAdditionalDescriptionComponent extends PureComponent {
    static propTypes = {
        category: CategoryTreeType.isRequired,
        isCurrentCategoryLoaded: PropTypes.bool,
    };

    static defaultProps = {
        isCurrentCategoryLoaded: true,
    };

    renderCategoryAdditionalDescription() {
        const {
            category: { additional_description, id },
            isCurrentCategoryLoaded,
        } = this.props;

        if (!id || !isCurrentCategoryLoaded) {
            return this.renderCategoryAdditionalDescriptionPlaceholder();
        }

        if (!additional_description) {
            return null;
        }

        return <Html content={additional_description} />;
    }

    renderCategoryAdditionalDescriptionPlaceholder() {
        return (
            <p>
                <TextPlaceholder length="long" />
            </p>
        );
    }

    render() {
        const {
            category: { additional_description },
        } = this.props;

        if (!additional_description) {
            return null;
        }

        return (
            <div block="CategoryAdditionalDescription">
                <div block="CategoryAdditionalDescription" elem="Description">
                    {this.renderCategoryAdditionalDescription()}
                </div>
            </div>
        );
    }
}

export default CategoryAdditionalDescriptionComponent;
