import AddIcon from 'Component/AddIcon';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import MinusIcon from 'Component/MinusIcon';
import { FieldNumber as SourceFieldNumber } from 'SourceComponent/FieldNumber/FieldNumber.component';
import { DEFAULT_MAX_PRODUCTS } from 'Util/Product/Extract';

import { DEFAULT_STEP_VALUE } from './FieldNumber.config';

/** @namespace Pwa/Component/FieldNumber/Component/FieldNumber */
export class FieldNumber extends SourceFieldNumber {
    render() {
        const {
            attr,
            attr: { min = 1, max = DEFAULT_MAX_PRODUCTS },
            events,
            setRef,
            value,
            handleValueChange,
            isDisabled,
            isCartOverlay,
            isProductWithCalculator,
            productQuantity,
        } = this.props;

        const quantity = isProductWithCalculator ? productQuantity : value;

        return (
            <>
                <input
                    ref={(elem) => setRef(elem)}
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                    {...attr}
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                    {...events}
                    type={FIELD_TYPE.number}
                    readOnly={isCartOverlay}
                    aria-label={__('Value')}
                    value={quantity}
                    disabled={isDisabled}
                    onChange={(e) => handleValueChange(e.target.value)}
                />
                <button
                    disabled={+quantity === max || isDisabled}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => handleValueChange(+quantity + DEFAULT_STEP_VALUE)}
                    aria-label={__('Add')}
                    type={FIELD_TYPE.button}
                >
                    <AddIcon block="SubtractButton" isPrimary />
                </button>
                <button
                    disabled={+quantity === min || isDisabled}
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => handleValueChange(+quantity - DEFAULT_STEP_VALUE)}
                    aria-label={__('Subtract')}
                    type={FIELD_TYPE.button}
                >
                    <MinusIcon block="AddButton" isPrimary />
                </button>
            </>
        );
    }
}

export default FieldNumber;
