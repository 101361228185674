export * from 'SourceComponent/Image/Image.config';

export const DEFAULT_DESKTOP_WINDOW_WIDTH = 1440;
export const DEFAULT_MOBILE_WINDOW_WIDTH = 360;
export const INITIAL_PROMO_BANNER_DESKTOP_HEIGHT = 160;
export const INITIAL_PROMO_BANNER_DESKTOP_WIDTH = 1370;
export const INITIAL_PROMO_BANNER_MOBILE_HEIGHT = 220;
export const INITIAL_PROMO_BANNER_MOBILE_WIDTH = 360;
export const DEFAULT_MOBILE_BREAKPOINT_WIDTH = 757;
export const PROMO_BANNER_TITLE = 'header';
