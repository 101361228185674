/* eslint-disable prettier/prettier */
/* eslint-disable indent */

import isEmpty from 'lodash/isEmpty';

import { Meta as SourceMeta } from 'SourceComponent/Meta/Meta.component';
import history from 'Util/History';

/**
 * Page Meta data
 * @class Meta
 * @namespace Pwa/Component/Meta/Component/Meta */
export class Meta extends SourceMeta {
    static propTypes = {
        ...this.propTypes,
    };

    static defaultProps = {
        ...this.defaultProps,
        title: '',
        canonical_url: '',
    };

    componentDidMount() {
        super.componentDidMount()

        this.updateAttrLang();
    }

    updateAttrLang() {
        const { lang } = this.props;

        document.documentElement.setAttribute('lang', lang);
    }

    renderCategories = () => {
        const { metadata } = this.props;

        const categories = metadata.find((meta) => meta.property === 'categories');
        if (isEmpty(categories?.content)) {
            return null;
        }

        return categories.content.map((cat) => (
        <meta property="product:category" content={cat.synerise_path} />
            ));
    };

  renderHrefLangs() {
    const { hreflangs, alternateLinks } = this.props;
    const { location } = history;

    if (hreflangs && hreflangs?.length > 0) {
      return hreflangs.map(({ href, lang }) => (
        <link
          rel="alternate"
          hrefLang={lang === 'cz' ? 'cs' : lang}
          href={href}
          key={lang}
        />
      ));
    }

    if (alternateLinks && alternateLinks?.length >= 0) {
      const staticPath = location?.pathname
        ?.replace(new RegExp(window.storeRegexText), '')
        ?.replace('/', '');

      if (staticPath)
        return alternateLinks.map(({ href, hreflang }) => (
          <link
            rel="alternate"
            hrefLang={hreflang === 'cz' ? 'cs' : hreflang}
            href={href + staticPath}
            key={hreflang}
          />
        ));

      return alternateLinks.map(({ href, hreflang }) => (
        <link
          rel="alternate"
          hrefLang={hreflang === 'cz' ? 'cs' : hreflang}
          href={href}
          key={hreflang}
        />
      ));
    }

    return null;
  }

  renderMeta() {
    const { metadata } = this.props;
    const otherMetadata = metadata.filter(
      (meta) => !['categories'].includes(meta.property)
    );

    return (
      <>
        {this.renderTitle()}
        {this.renderCanonical()}
        {this.renderHrefLangs()}
        {this.renderCategories()}
        {otherMetadata.map((tag) => (
          <meta key={tag.name || tag.property} {...tag} />
        ))}
      </>
    );
  }
}

export default Meta;
