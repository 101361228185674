import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    SearchItemContainer as SourceSearchItemContainer,
} from 'SourceComponent/SearchItem/SearchItem.container';

export { mapDispatchToProps, mapStateToProps };

/** @namespace Pwa/Component/SearchItem/Container/SearchItemContainer */
export class SearchItemContainer extends SourceSearchItemContainer {
    getImgSrc() {
        const { product } = this.props;

        const thumbnailUrl = product?.thumbnail?.url;

        return thumbnailUrl;
    }

    getCustomAttribute() {
        const {
            product: { sku },
        } = this.props;
        const { product_list_content: { attribute_to_display } = {} } = window.contentConfiguration;
        const {
            product: { attributes = {} },
        } = this.props;
        const attribute = attributes[attribute_to_display || 'nazwa_producenta'];

        if (sku && !attribute) {
            return null;
        }

        return attribute;
    }

    containerProps() {
        const { product, isCategories } = this.props;

        return {
            product,
            linkTo: this.getLinkTo(),
            imgSrc: this.getImgSrc(),
            customAttribute: this.getCustomAttribute(),
            isCategories,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchItemContainer);
