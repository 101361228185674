import CategoryAdditionalDescription from '../component/CategoryAdditionalDescription';

export class CategoryPageRouteComponentPlugin {
    renderCategoryAdditionalDescription(instance) {
        const { category, isCurrentCategoryLoaded } = instance.props;

        return <CategoryAdditionalDescription category={category} isCurrentCategoryLoaded={isCurrentCategoryLoaded} />;
    }

    renderContent = (args, callback, instance) => (
        <>
            {callback.apply(instance, args)}
            {this.renderCategoryAdditionalDescription(instance)}
        </>
    );
}

export const { renderContent } = new CategoryPageRouteComponentPlugin();

export default {
    'Route/CategoryPage/Component': {
        'member-function': {
            renderContent,
        },
    },
};
