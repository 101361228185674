import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer,
} from 'SourceComponent/ProductPrice/ProductPrice.container';

/** @namespace Pwa/Component/ProductPrice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    percentageDiscountTreshold: state.ConfigReducer.percentage_discount_threshold,
});

export { mapDispatchToProps };

/** @namespace Pwa/Component/ProductPrice/Container/ProductPriceContainer */
export class ProductPriceContainer extends SourceProductPriceContainer {
    containerProps() {
        const {
            isProductWithCalculator,
            quantity,
            increment,
            percentageDiscountTreshold,
            isVirtualProduct = false,
        } = this.props;

        return {
            ...super.containerProps(),
            isProductWithCalculator,
            quantity,
            increment,
            percentageDiscountTreshold,
            isVirtualProduct,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
