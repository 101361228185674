import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import { ChildrenType } from 'Type/Common.type';

import './Tabs.style';

/** @namespace Pwa/Component/Tabs/Component/Tabs */
export class Tabs extends PureComponent {
    static propTypes = {
        isPageBuilder: PropTypes.bool,
        defaultActive: PropTypes.string,
        children: ChildrenType,
    };

    static defaultProps = {
        isPageBuilder: false,
        defaultActive: '',
        children: [],
    };

    __construct() {
        super.__construct();

        const { defaultActive } = this.props;

        this.state = {
            selectedTab: defaultActive ? (!Number.isNaN(defaultActive) ? Number(defaultActive) : 0) : 0,
        };
    }

    getTitles() {
        const { isPageBuilder, children } = this.props;

        if (isPageBuilder) {
            if (Array.isArray(React.Children.toArray(children)[0]?.props?.children)) {
                return React.Children.toArray(children)[0]?.props?.children?.map(
                    (child) => child?.props?.children?.props?.children?.props?.children
                );
            }

            return [React.Children.toArray(children)[0]?.props?.children?.props?.children];
        }

        return React.Children.toArray(children)
            .filter((child) => child?.type !== Fragment && child?.props['data-tabs-item'])
            .map((child) => child?.props['data-tabs-item-title']);
    }

    getContents() {
        const { children, isPageBuilder } = this.props;
        const { selectedTab } = this.state;

        if (isPageBuilder) {
            if (Array.isArray(React.Children.toArray(children)[1]?.props?.children)) {
                return React.Children.toArray(children)[1]?.props?.children?.map((child) => child?.props?.children)[
                    selectedTab
                ];
            }

            return React.Children.toArray(children)[1]?.props?.children?.props?.children;
        }

        return React.Children.toArray(children).filter(
            (child) => child?.type !== Fragment && child?.props['data-tabs-item']
        )[selectedTab];
    }

    getContentAttr() {
        const { children } = this.props;

        return omit(React.Children.toArray(children)[1]?.props || {}, 'children');
    }

    changeTabs(value) {
        this.setState({ selectedTab: value });
    }

    renderSidebar() {
        const { isPageBuilder } = this.props;
        const { selectedTab } = this.state;

        return (
            <div block="Tabs" elem="Sidebar">
                {this.getTitles().map((title, key) => (
                    <div
                        block="Tabs"
                        elem="Link"
                        mods={{ isSelected: selectedTab === key }}
                        onClick={this.changeTabs.bind(this, key)}
                    >
                        {title}
                        {!isPageBuilder ? <ChevronIcon /> : null}
                    </div>
                ))}
            </div>
        );
    }

    renderContent() {
        return (
            <div block="Tabs" elem="Content" {...this.getContentAttr()}>
                {this.getContents()}
            </div>
        );
    }

    render() {
        const { isPageBuilder } = this.props;
        return (
            <div block="Tabs" mods={{ isPageBuilder }}>
                {this.renderSidebar()}
                {this.renderContent()}
            </div>
        );
    }
}

export default Tabs;
