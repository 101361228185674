import { PRODUCT_TYPE as SOURCE_PRODUCT_TYPE } from 'SourceComponent/Product/Product.config';

export * from 'SourceComponent/Product/Product.config';

export const PRODUCT_TYPE = {
    ...SOURCE_PRODUCT_TYPE,
    built: 'built',
};

export default PRODUCT_TYPE;
