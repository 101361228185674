export * from 'SourceComponent/Header/Header.config';

export const WISHLIST = 'wishlist';
export const WISHLIST_LINK = '/wishlist';
export const ACC_LOGIN_LINK = '/customer/account/login';
export const ACC_CREATE_LINK = '/customer/account/create';
export const ACC_FORGOT_PASS_LINK = '/customer/account/forgotpassword';
export const SUBCATEGORY = 'MENU_SUBCATEGORY';
export const LOGO_ALT = 'Domneo.pl';
export const CART_PAGE_URL = 'cart';
export const TDUID_URL_PARAM = 'tduid=';
export const SUCCESS_PAGE_URL = 'checkout/success';
export const PAYMENT_PAGE_URL = 'checkout/payment';
