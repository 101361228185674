import isEmpty from 'lodash/isEmpty';

import CrossSellPopup from '../component/CrossSellPopup/CrossSellPopup.container';

const renderCrossSellPopup = (props) => {
    const { popupProduct } = props;
    if (isEmpty(popupProduct) || isEmpty(popupProduct.crosssell_products)) return null;
    return <CrossSellPopup product={popupProduct} />;
};

const render = (args, callback, instance) => (
    <>
        {callback.apply(instance, args)}
        {renderCrossSellPopup(instance.props)}
    </>
);

export default {
    'Route/CategoryPage/Component': {
        'member-function': {
            render,
        },
    },
};
