import {
    mapDispatchToProps,
    mapStateToProps,
    PromotionalBannerContainer as SourcePromotionalBannerContainer,
} from '@domneo/promotionalbanner/src/component/PromotionalBanner/PromotionalBanner.container';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

export * from '@domneo/promotionalbanner/src/component/PromotionalBanner/PromotionalBanner.container';

/** @namespace Pwa/@Domneo/Promotionalbanner/Component/PromotionalBanner/Container/PromotionalBannerContainer */
export class PromotionalBannerContainer extends SourcePromotionalBannerContainer {
    containerProps() {
        const { device } = this.props;
        return {
            ...super.containerProps(),
            device,
        };
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PromotionalBannerContainer));
