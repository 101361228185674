import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './Countdown.style';

/** @namespace Domneo/Slider/Components/Countdown/Component/CountdownComponent */
export class CountdownComponent extends PureComponent {
    static propTypes = {
        days: PropTypes.string,
        hours: PropTypes.string,
        minutes: PropTypes.string,
        seconds: PropTypes.string,
        expired: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
    };

    renderFinish() {
        return (
            <div block="Countdown" elem="Finished">
                {__('Finished')}
            </div>
        );
    }

    renderContent() {
        const { days, hours, minutes, seconds } = this.props;

        return (
            <div block="Countdown" elem="Content">
                <div block="Countdown" elem="Item">
                    <span block="Countdown" elem="Label">
                        {__('Days')}
                    </span>
                    <div block="Countdown" elem="Number">
                        {days}
                    </div>
                </div>
                <div block="Countdown" elem="Item">
                    <span block="Countdown" elem="Label">
                        {__('Hours')}
                    </span>
                    <div block="Countdown" elem="Number">
                        {hours}
                    </div>
                </div>
                <div block="Countdown" elem="Item">
                    <span block="Countdown" elem="Label">
                        {__('Minutes')}
                    </span>
                    <div block="Countdown" elem="Number">
                        {minutes}
                    </div>
                </div>
                <div block="Countdown" elem="Item">
                    <span block="Countdown" elem="Label">
                        {__('Seconds')}
                    </span>
                    <div block="Countdown" elem="Number">
                        {seconds}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { expired } = this.props;

        if (expired) {
            return this.renderFinish();
        }

        return <div block="Countdown">{this.renderContent()}</div>;
    }
}

export default CountdownComponent;
