import ContentWrapper from 'Component/ContentWrapper';
import Icons from 'Component/Icons';
import { SomethingWentWrong as SourceSomethingWentWrong } from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.component';

import './SomethingWentWrong.override.style';

/** @namespace Pwa/Route/SomethingWentWrong/Component/SomethingWentWrong */
export class SomethingWentWrong extends SourceSomethingWentWrong {
    render() {
        const { onClick } = this.props;

        return (
            <main block="SomethingWentWrong">
                <ContentWrapper label="Something went wrong on the page.">
                    <div block="SomethingWentWrong">
                        <Icons name="no_match" />
                        <h1 block="SomethingWentWrong" elem="Heading">
                            {__('There was a problem during page loading.')}
                        </h1>
                        <h2 block="SomethingWentWrong" elem="SubHeading">
                            {__('Please reload the page')}
                        </h2>
                        <a
                            href="/"
                            block="SomethingWentWrong"
                            elem="Button"
                            mix={{ block: 'Button' }}
                            onClick={onClick}
                        >
                            {__('Back to homepage')}
                        </a>
                    </div>
                </ContentWrapper>
            </main>
        );
    }
}

export default SomethingWentWrong;
