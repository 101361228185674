import { PromotionalBannersReducer } from '../store/PromotionalBanner/PromotionalBanner.reducer';

export class PromotionalBannersReducerPlugin {
    getStaticReducers = (args, callback, instance) => ({
        ...callback.apply(instance, args),
        PromotionalBannersReducer,
    });
}

const { getStaticReducers } = new PromotionalBannersReducerPlugin();

export const config = {
    'Store/Index/getStaticReducers': {
        function: getStaticReducers,
    },
};

export default config;
