import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';
import { fetchMutation } from 'Util/Request';

import InpostQuery from '../query/Inpost.query';

export class CheckoutRouteContainerPlugin {
    setParcelLocker([parcelLocker]) {
        fetchMutation(InpostQuery.setParcelLockerToQuoteMutation(parcelLocker));
    }

    removeParcelLocker() {
        sessionStorage.removeItem('parcelLocker');
        document.cookie = `parcel_locker=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }

    componentWillUnmount(args, callback, instance) {
        instance.removeParcelLocker();
        callback(...args);
    }

    handleInpost([shippingCarrierCode], callback, instance) {
        const isCustomerSignedIn = isSignedIn();
        const guest_cart_id = !isCustomerSignedIn ? getGuestQuoteId() : '';

        const parcelLocker = JSON.parse(sessionStorage.getItem('parcelLocker'));

        if (shippingCarrierCode !== 'inpost' && parcelLocker) {
            instance.removeParcelLocker();
            instance.setParcelLocker({
                cart_id: guest_cart_id,
            });
        }
    }
}

const { setParcelLocker, removeParcelLocker, handleInpost, componentWillUnmount } = new CheckoutRouteContainerPlugin();

export default {
    'Route/Checkout/Container': {
        'member-function': {
            setParcelLocker,
            removeParcelLocker,
            handleInpost,
            componentWillUnmount,
        },
    },
};
