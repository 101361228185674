import {
    BlogRecentPostsContainer,
    mapDispatchToProps,
} from '@domneo/blog/src/component/BlogRecentPosts/BlogRecentPosts.container';
import BlogPostsQuery from '@domneo/blog/src/query/BlogPosts.query';
import React from 'react';
import { connect } from 'react-redux';

import { MAGEFAN_FEATURED, MAGEFAN_RECENT } from 'Component/WidgetFactory/WidgetFactory.config';
import { isCrawler } from 'Util/Browser/Browser';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';

import BlogWidget from './BlogWidget.component';
import {
    BLOG_WIDGET_DEFAULT_SIZE,
    BLOG_WIDGET_SLIDES_TO_SHOW_DESKTOP,
    BLOG_WIDGET_SLIDES_TO_SHOW_MOBILE,
    BLOG_WIDGET_SLIDES_TO_SHOW_TABLET,
} from './BlogWidget.config';

/** @namespace Pwa/Component/BlogWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/BlogWidget/Container/BlogWidgetContainer */
export class BlogWidgetContainer extends BlogRecentPostsContainer {
    __construct(props) {
        super.__construct(props);
        this.options = {
            recentPosts: {
                pageSize: BLOG_WIDGET_DEFAULT_SIZE,
                sort: 'DESC',
                getMedia: true,
                getDescription: true,
            },
        };
    }

    containerProps() {
        const { isLoaded } = this.state;
        const { device, postsNumber, title, hiddenSwipe = false } = this.props;

        return {
            isLoaded,
            device,
            postsNumber,
            title,
            hiddenSwipe,
            posts: this.preparePosts(),
            slidesToShow: this.getSlidesToShow(),
        };
    }

    getQueryByCategoryId() {
        const { authorId, categoryId, numberOfPosts, tagId } = this.props;

        return BlogPostsQuery.getQuery({
            filter: {
                ...(authorId === 0 ? {} : { author_id: { eq: authorId } }),
                ...(categoryId === 0 ? {} : { category_id: { eq: categoryId } }),
                ...(tagId === 0 ? {} : { tag_id: { eq: tagId } }),
            },
            getDescriptionSnippet: true,
            getMediaSnippet: true,
            pageSize: numberOfPosts || BLOG_WIDGET_DEFAULT_SIZE,
        });
    }

    getQueryByPostsIds() {
        const { postsIds } = this.props;

        return BlogPostsQuery.getQuery({
            filter: { post_id: { in: postsIds.split(',') } },
            getDescriptionSnippet: true,
            getMediaSnippet: true,
            pageSize: (postsIds || []).split(',').length,
        });
    }

    getSlidesToShow() {
        const {
            device: { isMobile, isTablet },
        } = this.props;

        if (isMobile) {
            return BLOG_WIDGET_SLIDES_TO_SHOW_MOBILE;
        }

        if (isTablet) {
            return BLOG_WIDGET_SLIDES_TO_SHOW_TABLET;
        }

        return BLOG_WIDGET_SLIDES_TO_SHOW_DESKTOP;
    }

    preparePosts() {
        const { posts } = this.state;

        if (isCrawler()) {
            return posts.slice(0, this.getSlidesToShow());
        }

        return posts;
    }

    requestPosts() {
        const { type, showNotification } = this.props;

        if (type === MAGEFAN_FEATURED) {
            executeGet(prepareQuery(this.getQueryByPostsIds()), 'BlogPostsByIds')
                .then(
                    /** @namespace Pwa/Component/BlogWidget/Container/executeGet/then */
                    ({ posts: { items } }) => this.updatePostList(items, true)
                )
                .catch(
                    /** @namespace Pwa/Component/BlogWidget/Container/executeGet/then/catch */
                    (err) => {
                        this.updatePostList([], false);
                        showNotification('error', err);
                    }
                );
        } else if (type === MAGEFAN_RECENT) {
            executeGet(prepareQuery(this.getQueryByCategoryId()), 'BlogPostsByCategoryId')
                .then(
                    /** @namespace Pwa/Component/BlogWidget/Container/executeGet/then */
                    ({ posts: { items } }) => this.updatePostList(items, true)
                )
                .catch(
                    /** @namespace Pwa/Component/BlogWidget/Container/executeGet/then/catch */
                    (err) => {
                        this.updatePostList([], false);
                        showNotification('error', err);
                    }
                );
        } else {
            super.requestPosts();
        }
    }

    render() {
        return <BlogWidget {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogWidgetContainer);
