import { SET_HEADING_BANNER } from './Layout.action';

/** @namespace Pwa/Store/Layout/Reducer/getInitialState */
export const getInitialState = () => ({
    headingBanner: null,
});

/** @namespace Pwa/Store/Layout/Reducer/LayoutReducer */
export const LayoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case SET_HEADING_BANNER:
            const { payload } = action;

            return {
                ...state,
                headingBanner: payload,
            };

        default:
            return state;
    }
};

export default LayoutReducer;
