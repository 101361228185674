import { LazyLoadImage } from 'react-lazy-load-image-component';

import ImageMagnify from 'Component/ImageMagnify';
import { Image as SourceImage } from 'SourceComponent/Image/Image.component';
import { isCrawler } from 'Util/Browser';
import { checkifIsInViewport } from 'Util/Image';

import {
    DEFAULT_DESKTOP_WINDOW_WIDTH,
    DEFAULT_MOBILE_BREAKPOINT_WIDTH,
    DEFAULT_MOBILE_WINDOW_WIDTH,
    IMAGE_LOADED,
    IMAGE_LOADING,
    IMAGE_NOT_FOUND,
    INITIAL_PROMO_BANNER_DESKTOP_HEIGHT,
    INITIAL_PROMO_BANNER_DESKTOP_WIDTH,
    INITIAL_PROMO_BANNER_MOBILE_HEIGHT,
    INITIAL_PROMO_BANNER_MOBILE_WIDTH,
    PROMO_BANNER_TITLE,
} from './Image.config';

import './Image.override.style';

/** @namespace Pwa/Component/Image/Component/Image */
export class Image extends SourceImage {
    calculateWindowWidth() {
        const windowWidth = window.innerWidth;
        const isMobile = window.innerWidth < DEFAULT_MOBILE_BREAKPOINT_WIDTH;
        const mobileWidth = windowWidth > DEFAULT_MOBILE_WINDOW_WIDTH ? DEFAULT_MOBILE_WINDOW_WIDTH : windowWidth;
        const desktopWidth = windowWidth > DEFAULT_DESKTOP_WINDOW_WIDTH ? DEFAULT_DESKTOP_WINDOW_WIDTH : windowWidth;

        const calculatedWidth = isMobile ? mobileWidth : desktopWidth;

        return calculatedWidth;
    }

    calculateBannerDimensions() {
        const isMobile = window.innerWidth < DEFAULT_MOBILE_BREAKPOINT_WIDTH;
        const currentWindowWidth = this.calculateWindowWidth();
        const defaultWindowWidth = isMobile ? DEFAULT_MOBILE_WINDOW_WIDTH : DEFAULT_DESKTOP_WINDOW_WIDTH;
        const initialBannerHeight = isMobile ? INITIAL_PROMO_BANNER_MOBILE_HEIGHT : INITIAL_PROMO_BANNER_DESKTOP_HEIGHT;
        const initialBannerWidth = isMobile ? INITIAL_PROMO_BANNER_MOBILE_WIDTH : INITIAL_PROMO_BANNER_DESKTOP_WIDTH;

        const height = Math.round((initialBannerHeight * currentWindowWidth) / defaultWindowWidth);
        const width = Math.round((initialBannerWidth * currentWindowWidth) / defaultWindowWidth);

        const headerBannerDimensions = {
            height,
            width,
        };

        return headerBannerDimensions;
    }

    onError() {
        if (isCrawler()) {
            this.setState({ imageStatus: IMAGE_LOADED });
            return;
        }

        this.setState({ imageStatus: IMAGE_NOT_FOUND });
    }

    renderPlainImage() {
        const { alt, src, style, title, className, noImgAttributesTransform, width, height } = this.props;
        const isHeaderBanner = title === PROMO_BANNER_TITLE;

        if (noImgAttributesTransform) {
            return (
                <img
                    block={className}
                    src={src || ''}
                    alt={alt}
                    title={!isHeaderBanner ? title : ''}
                    onLoad={this.onLoad}
                    onError={this.onError}
                    loading="lazy"
                    width={width}
                    height={height}
                />
            );
        }

        return (
            <img
                block={className}
                src={src || ''}
                alt={alt}
                style={isHeaderBanner ? this.calculateBannerDimensions() : style}
                title={!isHeaderBanner ? title : ''}
                onLoad={this.onLoad}
                onError={this.onError}
                loading="lazy"
            />
        );
    }

    renderStyledImage() {
        const { alt, loadingForCrawlers, magnification, src, style, title, sizes, extended, imageRef } = this.props;
        const { height, width } = sizes || {};
        const { imageStatus } = this.state;
        const isVisible = checkifIsInViewport(imageRef);

        if (isCrawler() || (extended && src)) {
            return (
                <img
                    block="Image"
                    elem="Image"
                    src={src || ''}
                    alt={alt}
                    mods={{ isLoading: imageStatus === IMAGE_LOADING }}
                    style={style}
                    title={title}
                    onLoad={this.onLoad}
                    onError={this.onError}
                    loading={loadingForCrawlers || 'lazy'}
                />
            );
        }

        if (magnification?.enabled) {
            return <ImageMagnify alt={alt} src={src} />;
        }

        return (
            <LazyLoadImage
                className="Image-Image"
                src={src || ''}
                alt={alt}
                mods={{ isLoading: imageStatus === IMAGE_LOADING }}
                style={style}
                title={title}
                onLoad={this.onLoad}
                onError={this.onError}
                height={height}
                width={width}
                visibleByDefault={isVisible}
            />
        );
    }

    render() {
        const { ratio, mix, isPlaceholder, wrapperSize, src, imageRef, className, isPlain, children } = this.props;
        const { imageStatus } = this.state;

        if (isPlain) {
            return this.renderImage();
        }

        return (
            <div
                block="Image"
                ref={imageRef}
                mods={{
                    ratio,
                    imageStatus: imageStatus.toLowerCase(),
                    isPlaceholder,
                    hasSrc: !!src,
                }}
                mix={mix}
                style={wrapperSize}
                // eslint-disable-next-line react/forbid-dom-props
                className={className}
            >
                {this.renderImage()}
                {this.renderLoader()}
                {children}
            </div>
        );
    }
}

export default Image;
