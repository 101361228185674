import {
    SET_AGREEMENTS_STATUS,
    SET_BLUE_GATEWAY_LIST,
    SET_BLUE_PAYMENT_GATEWAY_ID,
    SET_DELIVERY_METHOD,
    SET_DELIVERY_POINT,
    SET_PAYMENT_METHOD,
    SET_SHIPPING_ADDRESS,
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    UPDATE_SHIPPING_FIELDS,
} from 'Store/Checkout/Checkout.action';

/** @namespace Pwa/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    shippingFields: {},
    email: '',
    isEmailAvailable: true,
    deliveryMethod: null,
    deliveryPoint: null,
    paymentMethod: null,
    blueGatewaysArray: [],
    gatewayId: null,
    isAllRequiredAgreementsSelected: false,
    shippingAddress: null,
});

/** @namespace Pwa/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
        case UPDATE_SHIPPING_FIELDS:
            const { shippingFields } = action;

            return {
                ...state,
                shippingFields,
            };

        case UPDATE_EMAIL:
            const { email } = action;

            return {
                ...state,
                email,
            };
        case UPDATE_EMAIL_AVAILABLE:
            const { isEmailAvailable } = action;

            return {
                ...state,
                isEmailAvailable,
            };

        case SET_DELIVERY_METHOD:
            const { method } = action;

            return {
                ...state,
                deliveryMethod: method,
            };
        case SET_DELIVERY_POINT:
            const { point } = action;

            return {
                ...state,
                deliveryPoint: point,
            };
        case SET_PAYMENT_METHOD:
            const { paymentMethod } = action;
            return {
                ...state,
                paymentMethod,
            };
        case SET_BLUE_GATEWAY_LIST:
            const { blueGatewaysArray } = action;

            return {
                ...state,
                blueGatewaysArray,
            };
        case SET_BLUE_PAYMENT_GATEWAY_ID:
            const { gatewayId } = action;

            return {
                ...state,
                gatewayId,
            };
        case SET_AGREEMENTS_STATUS:
            const { isAllRequiredAgreementsSelected } = action;
            return {
                ...state,
                isAllRequiredAgreementsSelected,
            };
        case SET_SHIPPING_ADDRESS:
            return {
                ...state,
                shippingAddress: {
                    ...state.shippingAddress,
                    ...action.shippingAddress,
                },
            };
        default:
            return state;
    }
};

export default CheckoutReducer;
