import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    RouterContainer,
} from 'SourceComponent/Router/Router.container';
import { updateConfigDevice } from 'Store/Config/Config.action';
import { isMobile } from 'Util/Mobile';

export { mapStateToProps };

/** @namespace Pwa/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateConfigDevice: (device) => dispatch(updateConfigDevice({ ...device, isTablet: isMobile.tablet() })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
