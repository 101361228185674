import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Script from 'react-load-script';

import Popup from 'Component/Popup';
import { isSignedIn } from 'Util/Auth';
import { getGuestQuoteId } from 'Util/Cart';

import { CHECKOUT_INPOST_POPUP_ID } from './CheckoutInpost.config';

import './CheckoutInpost.style';

/** @namespace Domneo/Inpost/Component/CheckoutInpost/Component/CheckoutInpostComponent */
export class CheckoutInpostComponent extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        showInpostPopup: PropTypes.func.isRequired,
    };

    handleScriptLoad = () => {
        const { googleApiKey } = this.props;

        const mapType = googleApiKey ? 'google' : 'osm';

        window.easyPackAsyncInit = () => {
            // eslint-disable-next-line no-undef
            easyPack.init({
                defaultLocale: 'pl',
                mapType,
                searchType: mapType,
                points: {
                    types: ['parcel_locker'],
                },
                map: {
                    initialTypes: ['parcel_locker'],
                    googleKey: googleApiKey || null,
                },
            });
        };
    };

    renderInpostButton() {
        const { disabled, parcelLocker } = this.props;

        // eslint-disable-next-line fp/no-let
        let buttonText = __('Choose a parcel locker');
        if (parcelLocker) {
            buttonText = __('Change a parcel locker');
        }

        return (
            <span
                block="CheckoutDeliveryOption"
                elem="InpostButton"
                mix={{ block: 'Button' }}
                onClick={this.openModal}
                disabled={disabled}
            >
                {buttonText}
            </span>
        );
    }

    renderContent() {
        return <div id="easypack-map" />;
    }

    renderParcelLockerContent() {
        const { parcelLocker } = this.props;

        if (!parcelLocker) {
            return null;
        }

        return (
            <div block="CheckoutInpost" elem="SelectedParcelLocker">
                <span block="CheckoutInpost" elem="SelectedParcelLockerTitle">
                    {__('Selected parcel locker')}:
                </span>
                <div block="CheckoutInpost" elem="SelectedParcelLockerAddress">
                    <span>
                        {__('Parcel locker')} {parcelLocker.parcel_locker}
                    </span>
                    <span>{parcelLocker.parcel_locker_address.line1}</span>
                    <span>{parcelLocker.parcel_locker_address.line2}</span>
                </div>
            </div>
        );
    }

    openModal = (e) => {
        e.preventDefault();
        const { showInpostPopup, hidePopup } = this.props;
        const isCustomerSignedIn = isSignedIn();
        const guest_cart_id = !isCustomerSignedIn ? getGuestQuoteId() : '';

        showInpostPopup();

        // eslint-disable-next-line no-undef
        easyPack.mapWidget('easypack-map', (point) => {
            const parcelLocker = {
                cart_id: guest_cart_id,
                parcel_locker: point.name,
                parcel_locker_address: point.address,
            };

            sessionStorage.setItem('parcelLocker', JSON.stringify(parcelLocker));
            document.cookie = `parcel_locker=${parcelLocker.parcel_locker}`;
            hidePopup();
        });
    };

    render() {
        const { googleApiKey } = this.props;

        return (
            <div block="CheckoutInpost">
                {googleApiKey !== null && (
                    <Script
                        url="https://geowidget.easypack24.net/js/sdk-for-javascript.js"
                        onLoad={() => this.handleScriptLoad()}
                    />
                )}
                {this.renderParcelLockerContent()}
                {this.renderInpostButton()}
                <Popup id={CHECKOUT_INPOST_POPUP_ID} clickOutside={false} mix={{ block: 'InpostMapPopup' }}>
                    {this.renderContent()}
                </Popup>
            </div>
        );
    }
}

export default CheckoutInpostComponent;
