/* eslint-disable no-return-assign */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import Link from 'Component/Link';
import ProductReviewRating from 'Component/ProductReviewRating';
import SliderCounterNav from 'Component/SliderCounterNav';
import SliderProgressBar from 'Component/SliderProgressBar';
import { DeviceType } from 'Type/Device.type';

import { MAX_REVIEWS } from './ReviewsSection.config';

import './ReviewsSection.style';

/** @namespace Pwa/Component/ReviewsSection/Component/ReviewsSection */
export class ReviewsSection extends PureComponent {
    static propTypes = {
        total_reviews_amount: PropTypes.number.isRequired,
        total_reviews_average: PropTypes.number.isRequired,
        reviews: PropTypes.instanceOf(Array).isRequired,
        device: DeviceType.isRequired,
    };

    state = {
        currentSlide: 1,
    };

    __construct() {
        this.nextSlide = this.nextSlide.bind(this);
        this.previousSlide = this.previousSlide.bind(this);
    }

    nextSlide() {
        this.slider.slickNext();
    }

    previousSlide() {
        this.slider.slickPrev();
    }

    renderPaging() {
        const { currentSlide } = this.state;
        const {
            reviews,
            device: { isMobile, isTablet },
        } = this.props;

        if (!isMobile && !isTablet) {
            return null;
        }

        return (
            <SliderCounterNav
                currentSlide={currentSlide}
                numberOfSlides={reviews?.length || 0}
                goToNextSlide={this.nextSlide}
                goToPrevSlide={this.previousSlide}
            />
        );
    }

    renderProgressBar() {
        const { currentSlide } = this.state;
        const {
            reviews,
            device: { isMobile, isTablet },
        } = this.props;

        if (!isMobile && !isTablet) {
            return null;
        }

        return <SliderProgressBar currentSlide={currentSlide} numberOfSlides={reviews?.length || 0} />;
    }

    renderReviewsAverage() {
        const {
            total_reviews_amount,
            total_reviews_average,
            device: { isMobile },
        } = this.props;

        if (isMobile) {
            return null;
        }

        return (
            <div block="ReviewsSection" elem="TotalAverage">
                {__('Overall rating based on')} {total_reviews_amount} {__('reviews')}{' '}
                <span>{total_reviews_average}</span>
            </div>
        );
    }

    renderHeader() {
        const {
            device: { isMobile },
        } = this.props;

        return (
            <h2 block="ReviewsSection" elem="Header">
                {isMobile ? __('Why should you choose us?') : __('Reviews')}
            </h2>
        );
    }

    renderReview(review, i) {
        const {
            average_rating,
            nickname,
            text,
            title,
            product: { name, url },
        } = review;

        if (i >= MAX_REVIEWS) {
            return null;
        }

        const summaryRating = average_rating ? average_rating * 20 : 0;

        return (
            <div block="ReviewsSection" elem="Review" key={`${title}${i}`}>
                <div block="ReviewsSection" elem="Average">
                    <ProductReviewRating summary={summaryRating || 0} count={1} />
                </div>
                <div block="ReviewsSection" elem="Product">
                    {__('review relates to')}: <Link to={url}>{name}</Link>
                </div>
                <div block="ReviewsSection" elem="Text">
                    {text}
                </div>
                <div block="ReviewsSection" elem="Nickname">
                    {nickname}
                </div>
            </div>
        );
    }

    renderReviews() {
        const {
            reviews,
            device: { isMobile, isTablet },
        } = this.props;

        if (isMobile || isTablet) {
            return (
                <SlickSlider
                    beforeChange={(_, nextSlide) => {
                        this.setState({ currentSlide: nextSlide + 1 });
                    }}
                    ref={(slider) => (this.slider = slider)}
                >
                    {reviews.map((review, i) => this.renderReview(review, i))}
                </SlickSlider>
            );
        }

        return (
            <div block="ReviewsSection" elem="Reviews">
                {reviews.map((review, i) => this.renderReview(review, i))}
            </div>
        );
    }

    render() {
        const { reviews } = this.props;

        if (!reviews?.length) {
            return null;
        }

        return (
            <div block="ReviewsSection">
                <div block="ReviewsSection" elem="Head">
                    {this.renderHeader()}
                    {this.renderReviewsAverage()}
                </div>
                {this.renderPaging()}
                {this.renderReviews()}
                {this.renderProgressBar()}
            </div>
        );
    }
}

export default ReviewsSection;
