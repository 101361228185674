import { Field } from 'Util/Query';

/** @namespace Domneo/Inpost/Query/Inpost/Query/InpostQuery */
export class InpostQuery {
    getGoogleApiKey() {
        return new Field('getGoogleApiKey').addField(this._getGoogleApiKeyField());
    }

    _getGoogleApiKeyField() {
        return new Field('key');
    }

    setParcelLockerToQuoteMutation(parcelLocker) {
        const mutation = new Field('setParcelLockerToQuote')
            .addArgument('input', 'ParcelLockerInput', parcelLocker)
            .addFieldList(this._getParcelLockerFields());

        return mutation;
    }

    _getParcelLockerFields() {
        return ['parcel_locker', 'parcel_locker_address'];
    }
}

export default new InpostQuery();
