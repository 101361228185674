export * from 'SourceComponent/WidgetFactory/WidgetFactory.config';

export const MAGEFAN_FEATURED = 'MagefanFeatured';
export const MAGEFAN_RECENT = 'MagefanRecent';
export const STANDARD = 'standard';
export const STANDARD_WITHOUT_MOBILE = 'standard_without_mobile';
export const EXTENDED = 'extended';
export const CATEGORIES = 'Categories';
export const BUILD_PRODUCT_WITH_HOTSPOT = 'built_product_with_hotspot';
export const BUILD_PRODUCT_WITHOUT_HOTSPOT = 'built_product_without_hotspot';
