import { PureComponent } from 'react';

import './SliderPrevArrow.style.scss';

/** @namespace Domneo/Slider/Components/SliderPrevArrow/Component/SliderPrevArrowComponent */
export class SliderPrevArrowComponent extends PureComponent {
    render() {
        const { onClick, className } = this.props;
        const isDisabled = className.search('disabled') !== -1;

        return (
            <div block="SliderPrevArrow" mods={{ disabled: isDisabled }} onClick={onClick}>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                    clipRule="evenodd"
                >
                    <path d="M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z" />
                </svg>
            </div>
        );
    }
}

export default SliderPrevArrowComponent;
