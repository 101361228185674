export const BLOG_WIDGET_SLIDES_TO_SHOW_MOBILE = 1;
export const BLOG_WIDGET_SLIDES_TO_SHOW_TABLET = 1;
export const BLOG_WIDGET_SLIDES_TO_SHOW_DESKTOP = 2;

export const BLOG_WIDGET_DEFAULT_SIZE = 3;

export const BLOG_LIST_URL = '/blog';

export const BLOG_WIDGET_SLIDER_OPTIONS = {
    slidesToShow: BLOG_WIDGET_SLIDES_TO_SHOW_DESKTOP,
    slidesToScroll: BLOG_WIDGET_SLIDES_TO_SHOW_DESKTOP,
    responsive: [
        {
            breakpoint: 1023,
            settings: {
                arrows: false,
                slidesToShow: BLOG_WIDGET_SLIDES_TO_SHOW_TABLET,
                slidesToScroll: BLOG_WIDGET_SLIDES_TO_SHOW_TABLET,
            },
        },
        {
            breakpoint: 767,
            settings: {
                arrows: false,
                variableWidth: true,
                slidesToShow: BLOG_WIDGET_SLIDES_TO_SHOW_MOBILE,
                slidesToScroll: BLOG_WIDGET_SLIDES_TO_SHOW_MOBILE,
            },
        },
    ],
};
