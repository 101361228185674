import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { isCrawler } from 'Util/Browser/Browser';
import { fetchQuery } from 'Util/Request';

import SliderQuery from '../../query/Slider.query';
import Slider from './Slider.component';

/** @namespace Domneo/Slider/Components/Slider/Container/SliderContainer */
export class SliderContainer extends PureComponent {
    static propTypes = {
        position: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
    };

    state = {
        slider: {},
        loaded: false,
    };

    componentDidMount() {
        this.getSliderData();
    }

    getSliderData() {
        const { position, code } = this.props;

        fetchQuery(SliderQuery.getSliderQuery(position, code)).then(
            /** @namespace Domneo/Slider/Components/Slider/Container/fetchQuery/then */
            ({ slider }) => {
                this.setState({
                    slider: this._prepareSliderData(slider),
                    loaded: true,
                });
            },
            /** @namespace Domneo/Slider/Components/Slider/Container/fetchQuery/then */
            () => {
                this.setState({
                    slider: {},
                    loaded: true,
                });
            }
        );
    }

    _prepareSliderData(slider) {
        if (isCrawler()) {
            return {
                ...slider,
                slides: slider?.slides?.[0] ? [slider.slides[0]] : [],
            };
        }

        return slider;
    }

    containerProps = () => {
        const { slider, loaded } = this.state;

        return {
            slider,
            loaded,
        };
    };

    render() {
        return <Slider {...this.containerProps()} />;
    }
}

export default SliderContainer;
