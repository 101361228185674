import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { getErrorMessage } from 'Util/Request';
import { getErrorCategory, GRAPHQL_AUTHORIZATION } from 'Util/Request/Error';

import { AuthorizationError, GraphqlError } from './Error';

export { Sentry };

/** @namespace Pwa/Util/Sentry/Index/captureGraphqlException */
export const captureGraphqlException = (errors, options = {}) => {
    Sentry.withScope((scope) => {
        const context = {
            ...(options ?? {}),
        };

        if (typeof errors !== 'string') {
            context.error = JSON.stringify(errors ?? {});
        }

        scope.setTransactionName('GraphQL Request');
        scope.setContext('GraphQL', context);

        if (getErrorCategory(errors) === GRAPHQL_AUTHORIZATION) {
            Sentry.captureException(new AuthorizationError(getErrorMessage(errors)));
        } else {
            Sentry.captureException(new GraphqlError(getErrorMessage(errors)));
        }
    });
};

Sentry.init({
    dsn: 'https://8835766afa754f18923927fdae7cbf29@sentry.advox.pl/58',
    enabled: process.env.NODE_ENV === 'production',
    environment: window.location.host,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    debug: process.env.NODE_ENV !== 'production',
});
