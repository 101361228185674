import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Icons from './Icons.component';
import * as IconsConfig from './Icons.config';

/** @namespace Pwa/Component/Icons/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Pwa/Component/Icons/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/Icons/Container/IconsContainer */
export class IconsContainer extends PureComponent {
    static propTypes = {
        name: PropTypes.oneOf([
            'angle_down',
            'angle_right',
            'angle_left',
            'arrow_left',
            'arrow_right',
            'bag',
            'bath',
            'bulb',
            'burger',
            'close',
            'cutlery',
            'earphones',
            'heart',
            'home',
            'info',
            'phone',
            'project',
            'refresh',
            'scale',
            'search',
            'shapes',
            'share',
            'shield',
            'snowflake',
            'star',
            'star_filled',
            'star_half_filled',
            'tick',
            'trade',
            'trash',
            'truck',
            'user',
            'warning',
            'window',
            'no_match',
            'download',
            'change_product',
            'copy_code',
            'code_label',
            'chevron_double',
            'envelope',
        ]).isRequired,
        fill: PropTypes.string,
        width: PropTypes.string,
        height: PropTypes.string,
    };

    static defaultProps = {
        fill: '#191C1D',
        width: '24',
        height: '24',
    };

    containerProps = () => {
        const { name, ...rest } = this.props;

        return {
            icon: this._getIconByName(name, rest),
        };
    };

    _getIconByName(name, props) {
        const nameUpperCase = name.toUpperCase();
        if (typeof IconsConfig[nameUpperCase] === 'function') {
            return IconsConfig[nameUpperCase](props);
        }

        return '';
    }

    render() {
        return <Icons {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IconsContainer);
