/* eslint-disable indent */
import React, { PureComponent } from 'react';
import { v4 as uuid } from 'uuid';

import { LENS_HEIGHT, LENS_WIDTH, PREVIEW_HEIGHT, PREVIEW_WIDTH } from './ImageMagnify.config';

import './ImageMagnify.style';

/** @namespace Pwa/Component/ImageMagnify/Component/ImageMagnify */
export class ImageMagnify extends PureComponent {
    id = null;

    preview_id = null;

    state = {
        showLens: false,
    };

    __construct() {
        this.id = `IMAGE_MAGNIFIED_${uuid()}`;
        this.preview_id = `IMAGE_MAGNIFIED_PREVIEW_${uuid()}`;

        setTimeout(() => {
            this.initScript();
        }, 1000);
    }

    initScript() {
        const imageZoom = (imgSelector, resultSelector, lensSelector) => {
            const img = document.querySelector(imgSelector);
            const result = document.querySelector(resultSelector);

            /* Create lens: */
            const lens = document.querySelector(lensSelector);

            /* Calculate the ratio between result DIV and lens: */
            const cx = result.offsetWidth / lens.offsetWidth;
            const cy = result.offsetHeight / lens.offsetHeight;

            function moveResult(result, img, lensX, lensY) {
                /* eslint-disable fp/no-let */
                let resultPosX = lensX - PREVIEW_WIDTH / 2 + LENS_WIDTH / 2;
                let resultPosY = lensY - PREVIEW_HEIGHT / 2 + LENS_HEIGHT / 2;
                /* eslint-enable fp/no-let */

                /* Prevent the elem from being positioned outside the image: */
                if (resultPosX > img.width - PREVIEW_WIDTH) {
                    resultPosX = img.width - PREVIEW_WIDTH;
                }
                if (resultPosX < 0) {
                    resultPosX = 0;
                }
                if (resultPosY > img.height - PREVIEW_HEIGHT) {
                    resultPosY = img.height - PREVIEW_HEIGHT;
                }
                if (resultPosY < 0) {
                    resultPosY = 0;
                }

                /* magic fix */
                // resultPosX += img.offsetLeft;
                /*   */

                result.style.left = `${resultPosX}px`;
                result.style.top = `${resultPosY}px`;
            }

            function getCursorPos(e) {
                /* eslint-disable fp/no-let */
                let a;
                let x = 0;
                let y = 0;
                /* eslint-enable fp/no-let */

                e = e || window.event;
                /* Get the x and y positions of the image: */
                // eslint-disable-next-line prefer-const
                a = img.getBoundingClientRect();
                /* Calculate the cursor's x and y coordinates, relative to the image: */
                x = e.pageX - a.left;
                y = e.pageY - a.top;
                /* Consider any page scrolling: */
                x -= window.pageXOffset;
                y -= window.pageYOffset;

                return { x, y };
            }

            function moveLens(e) {
                /* eslint-disable fp/no-let */
                let x;
                let y;
                /* eslint-enable fp/no-let */
                /* Prevent any other actions that may occur when moving over the image */
                e.preventDefault();
                /* Get the cursor's x and y positions: */
                const pos = getCursorPos(e);
                /* Calculate the position of the lens: */
                x = pos.x - lens.offsetWidth / 2;
                y = pos.y - lens.offsetHeight / 2;
                /* Prevent the lens from being positioned outside the image: */
                if (x > img.width - lens.offsetWidth) {
                    x = img.width - lens.offsetWidth;
                }
                if (x < 0) {
                    x = 0;
                }
                if (y > img.height - lens.offsetHeight) {
                    y = img.height - lens.offsetHeight;
                }
                if (y < 0) {
                    y = 0;
                }

                /* Display what the lens "sees": */
                result.style.backgroundPosition = `-${x * cx}px -${y * cy}px`;

                moveResult(result, img, x, y);

                /* magic fix */
                // x += img.offsetLeft;
                /*   */

                /* Set the position of the lens: */
                lens.style.left = `${x}px`;
                lens.style.top = `${y}px`;
            }

            /* Set background properties for the result DIV */
            result.style.backgroundImage = `url('${img.src}')`;
            result.style.backgroundSize = `${img.width * cx}px ${img.height * cy}px`;

            /* Execute a function when someone moves the cursor over the image, or the lens: */
            lens.addEventListener('mousemove', moveLens);
            img.addEventListener('mousemove', moveLens);

            // img.addEventListener('mousemove', (e) => moveLens(e, result));
            // lens.addEventListener('mousemove', (e) => moveLens(e, result));

            // lens.addEventListener('touchmove', moveLens);
            // img.addEventListener('touchmove', moveLens);
        };

        imageZoom(`#${this.id} .ImageMagnify-Image`, `#${this.id} .ImageMagnify-Result`, `#${this.id} .img-zoom-lens`);
    }

    toggleLens(value) {
        this.setState({ showLens: value });
    }

    render() {
        const { alt, src } = this.props;
        const { showLens } = this.state;

        return (
            <div
                id={this.id}
                block="ImageMagnify"
                onMouseEnter={() => this.toggleLens(true)}
                onMouseLeave={() => this.toggleLens(false)}
                mods={{ shown: showLens }}
            >
                <img block="ImageMagnify" elem="Image" src={src} alt={alt} />
                <div
                    className="img-zoom-lens"
                    style={{
                        height: LENS_HEIGHT,
                        width: LENS_WIDTH,
                    }}
                />
                <div
                    id={this.preview_id}
                    block="ImageMagnify"
                    elem="Result"
                    style={{
                        height: PREVIEW_HEIGHT,
                        width: PREVIEW_WIDTH,
                    }}
                />
            </div>
        );
    }
}

export default ImageMagnify;
