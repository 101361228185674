import { FIELD_TYPE } from 'Component/Field/Field.config';
import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';
import { validate } from 'Util/Validator';

/** @namespace Pwa/Component/Field/Container/FieldContainer */
export class FieldContainer extends SourceFieldContainer {
    validate(data) {
        const { validationRule, type, attr: { name } = {} } = this.props;
        const value =
            type === FIELD_TYPE.checkbox || type === FIELD_TYPE.radio ? !!this.fieldRef.checked : this.fieldRef.value;
        const response = validate(value, validationRule);
        const output = response !== true ? { ...response, type, name } : response;

        // If validation is called from different object you can pass object
        // to store validation error values
        if (data && data.detail && response !== true) {
            if (!data.detail.errors) {
                // eslint-disable-next-line no-param-reassign
                data.detail.errors = [];
            }
            data.detail.errors.push(output);
        }
        this.setState({ validationResponse: output });

        return output;
    }

    validateOnEvent(hook, ...args) {
        const output = this.validate();

        if (hook) {
            const { attr, type } = this.props;
            const { value } = this.fieldRef;
            hook(
                ...[
                    ...args,
                    {
                        ...attr,
                        validated: output === true,
                        fieldRef: this.fieldRef,
                        value,
                        type,
                    },
                ]
            );
        }
    }

    containerProps() {
        const { isCartField, isCartOverlay, isProductWithCalculator, productQuantity, isRmaProduct, isGDPRPopup } =
            this.props;

        return {
            ...super.containerProps(),
            isCartField,
            isCartOverlay,
            isProductWithCalculator,
            productQuantity,
            isRmaProduct,
            isGDPRPopup,
        };
    }
}

export default FieldContainer;
