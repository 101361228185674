import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/AddToCart/AddToCart.container';
import { ADD_TO_CART } from 'Util/Product';
import { getMaxQuantity, getMinQuantity } from 'Util/Product/Extract';
import { magentoProductTransform } from 'Util/Product/Transform';

export { mapStateToProps, mapDispatchToProps };

/** @namespace Pwa/Component/AddToCart/Container/AddToCartContainer */
export class AddToCartContainer extends SourceAddToCartContainer {
    typeValidationMap = {
        ...this.typeValidationMap,
        [PRODUCT_TYPE.built]: this.validateBuilt.bind(this),
    };

    validateBuilt() {
        return true;
    }

    validateQuantity() {
        const {
            product,
            quantity,
            showNotification,
            product: { type_id: typeId },
        } = this.props;
        const minQty = getMinQuantity(product);
        const maxQty = getMaxQuantity(product);
        const inRange = quantity >= minQty && quantity <= maxQty;
        const isValid = typeId === PRODUCT_TYPE.grouped || typeId === PRODUCT_TYPE.built || inRange;

        if (!isValid) {
            if (quantity < minQty) {
                showNotification('info', __('Sorry! Minimum quantity for this product is %s!', minQty));
            } else {
                showNotification('info', __('Sorry! Maximum quantity for this product is %s!', maxQty));
            }
        }

        return isValid;
    }

    async addProductToCart(e, isBuyNow) {
        const { product, addToCart } = this.props;

        if ((!product || Object.keys(product).length === 0) && !addToCart) {
            return;
        }

        e.preventDefault();
        this.setState({ isAdding: true });

        if (!this.validate()) {
            return;
        }

        if (typeof addToCart === 'function') {
            try {
                await addToCart();
                if (isBuyNow) {
                    this.props.history.push('/cart');
                }
            } finally {
                this.setState({ isAdding: false });
            }
        } else {
            const { quantity, cartId, fallbackAddToCart } = this.props;
            const magentoProduct = magentoProductTransform(ADD_TO_CART, product, quantity);
            try {
                await fallbackAddToCart({
                    products: magentoProduct,
                    cartId,
                });
                if (isBuyNow) {
                    this.props.history.push('/cart');
                }
            } finally {
                this.setState({ isAdding: false });
            }
        }

        this.setState({ isAdding: false });
    }

    containerProps() {
        const { customTexts, isBuyNowButton, isCrossSellButton, isShortButtonText } = this.props;

        return {
            ...super.containerProps(),
            customTexts,
            isBuyNowButton,
            isCrossSellButton,
            isShortButtonText,
        };
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer));
