import { ClickOutside as SourceClickOutside } from 'SourceComponent/ClickOutside/ClickOutside.component';

/** @namespace Pwa/Component/ClickOutside/Component/ClickOutside */
export class ClickOutside extends SourceClickOutside {
    handleClick({ target }) {
        const { onClick } = this.props;

        if (
            this.childrenRefs.every((ref) => {
                const elementRef = ref.current?.overlayRef?.current || ref.current;

                return !elementRef.contains(target);
            })
        ) {
            if (typeof onClick === 'function') {
                onClick();
            }
        }
    }
}

export default ClickOutside;
