/* eslint-disable indent */

import { DEFAULT_PRICE_CURRENCY } from 'Component/CheckoutOrderSummaryPriceLine/CheckoutOrderSummaryPriceLine.config';
import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import { STOCK_TYPE } from 'Component/Product/Stock.config';
import { CALCULATOR_UNIT } from 'Component/ProductActions/ProductActions.config';
import { formatPrice } from 'Util/Price';
import { multiplyValues } from 'Util/Product/Calculator';
import { getBuiltProductById } from 'Util/Product/Transform';

export * from 'SourceUtil/Product/Extract';

// #region IMAGE
/**
 * Returns product image based on variable field, on fail placeholder
 * @param product
 * @param field
 * @namespace Pwa/Util/Product/Extract/getImage */
export const getImage = (product, field) => {
    const url = product?.[field]?.url || 'no_selection';
    return url && url !== 'no_selection' ? url : '';
};

/**
 * Returns products thumbnail image
 * @param product
 * @namespace Pwa/Util/Product/Extract/getThumbnailImage */
export const getThumbnailImage = (product) => getImage(product, 'thumbnail');

/**
 * Returns products small image
 * @param product
 * @namespace Pwa/Util/Product/Extract/getSmallImage */
export const getSmallImage = (product) => getImage(product, 'small_image');

/**
 * Returns products base image
 * @param product
 * @namespace Pwa/Util/Product/Extract/getBaseImage */
export const getBaseImage = (product) => getImage(product, 'image');
// #endregion

/** @namespace Pwa/Util/Product/Extract/getBuiltImage */
export const getBuiltImage = (product, field) => (product?.[field]?.path ? product?.[field]?.url : '');

/** @namespace Pwa/Util/Product/Extract/getBuiltProductsTotals */
export const getBuiltProductsTotals = (product = {}, quantity) => {
    const getDiscount = () => product?.built_discount / 100 ?? 0;

    const tilesQty = ({ product_unit, vp_items } = {}, qty) =>
        product_unit === CALCULATOR_UNIT ? Number(multiplyValues(qty, vp_items ?? 1)) : qty;

    const itemQty = (item, qty) => tilesQty(item, quantity?.[item?.id] ?? qty ?? 0);

    const getAmount = (data, type) => {
        if (type) {
            return data?.reduce(
                (amount, { qty, product }) =>
                    (product?.price_range?.maximum_price[type]?.value ?? 0) * 100 * itemQty(product, qty) + amount,
                0
            );
        }

        return 0;
    };

    const getCurrency = (data, type) => {
        if (type) {
            return data?.price_range?.maximum_price[type]?.currency || DEFAULT_PRICE_CURRENCY;
        }

        return DEFAULT_PRICE_CURRENCY;
    };

    const getAvailbility = () => {
        const productAvailability = [];
        const { items } = product;

        items?.map((element) => {
            if (quantity && quantity[element.product.id] && quantity[element.product.id] > 0) {
                return element.product.stock_availability
                    ? productAvailability.push(element.product.stock_availability.label)
                    : productAvailability.push(element.product.deliverer_available);
            }

            if (element.quantity > 0) {
                return element.product.stock_availability
                    ? productAvailability.push(element.product.stock_availability.label)
                    : productAvailability.push(element.product.deliverer_available);
            }

            return null;
        });

        if (productAvailability.includes('On request')) {
            return 'On request';
        }

        const deliveryTimeLongerThan24h = productAvailability.filter(
            (element) => element !== '24h' && element !== 'On request'
        );

        if (deliveryTimeLongerThan24h.length > 0) {
            const convertedDeliveryTime = deliveryTimeLongerThan24h.map((item) => parseInt(item, 10));

            return Math.max(...convertedDeliveryTime);
        }

        if (productAvailability.includes('24h')) {
            return '24h';
        }

        return null;
    };

    return {
        currency: getCurrency(product, 'final_price'),
        item_count: quantity
            ? Object.keys(quantity).reduce(
                  (count, key) =>
                      count +
                      (getBuiltProductById(key, product)?.product?.product_unit === CALCULATOR_UNIT
                          ? 1
                          : quantity[key]),
                  0
              )
            : product?.items?.reduce((count) => count + 1, 0),
        regular_price: Math.round(getAmount(product?.items, 'regular_price')) / 100,
        special_price: Math.round(getAmount(product?.items, 'final_price')) / 100,
        final_price:
            Math.round(
                getAmount(product?.items, 'final_price') -
                    Math.round(getAmount(product?.items, 'final_price') * getDiscount())
            ) / 100,
        discount_price:
            Math.round(
                getAmount(product?.items, 'regular_price') -
                    Math.round(
                        getAmount(product?.items, 'final_price') -
                            Math.round(getAmount(product?.items, 'final_price') * getDiscount())
                    )
            ) / 100,
        discount_value: getDiscount(),
        productAvailability: getAvailbility(),
    };
};

/** @namespace Pwa/Util/Product/Extract/getProductQuantity */
export const getProductQuantity = ({ vp_items, product_unit }, qty = 1) => {
    if (product_unit === CALCULATOR_UNIT) {
        const increment = Number(vp_items);

        if (Number.isNaN(increment)) {
            return 0;
        }

        if (Number(increment) > 0) {
            return (qty / increment).toFixed(0);
        }

        return increment;
    }

    return qty;
};

/** @namespace Pwa/Util/Product/Extract/getProductInStock */
export const getProductInStock = (product, parentProduct = {}) => {
    if (!product) {
        return false;
    }

    const { type_id: type, variants = [], items = [], stock_item: { in_stock: inStock = true } = {} } = product;

    if (type === PRODUCT_TYPE.bundle) {
        const { items = [] } = product;
        const requiredItems = items.filter(({ required }) => required);
        const requiredItemsInStock = requiredItems.filter(({ options }) =>
            options.some(({ product }) => getProductInStock(product))
        );

        return inStock && requiredItemsInStock.length === requiredItems.length;
    }

    if (type === PRODUCT_TYPE.configurable && parentProduct === product) {
        return inStock && !!variants.some((variant) => getProductInStock(variant, product));
    }

    const { type_id: parentTypeId = false } = parentProduct;

    if (parentTypeId === PRODUCT_TYPE.configurable && parentProduct !== product) {
        const { stock_item: { in_stock: parentInStock = true } = {}, stock_status: parentStockStatus } = parentProduct;

        return parentInStock && parentStockStatus !== STOCK_TYPE.OUT_OF_STOCK && getProductInStock(product);
    }

    if (type === PRODUCT_TYPE.grouped || type === PRODUCT_TYPE.built) {
        return inStock && !!items.some(({ product }) => getProductInStock(product));
    }

    const { stock_status: stockStatus } = product;

    return stockStatus !== STOCK_TYPE.OUT_OF_STOCK && (inStock || stockStatus === STOCK_TYPE.IN_STOCK);
};

/** @namespace Pwa/Util/Product/Extract/getProductPriceWithQty */
export const getProductPriceWithQty = ({ value, currency, valueFormatted } = {}, qty, options = {}) =>
    !qty
        ? valueFormatted
        : formatPrice(Math.round((value || 0) * 100 * ((options.increment || 1) * (qty || 1))) / 100, currency);

/** @namespace Pwa/Util/Product/Extract/getProductUrlName */
export const getProductUrlName = () => {
    const cleaned = window.location.pathname.replaceAll(/\/|(.html)/g, '').replaceAll(/-/g, ' ');
    return cleaned.charAt(0).toUpperCase() + cleaned.substring(1);
};
