/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_TAGS = 'UPDATE_TAGS';
export const CLEAR_TAG = 'CLEAR_TAG';
export const UPDATE_TAG = 'UPDATE_TAG';
export const NO_TAGS = 'NO_TAGS';

/**
 * Update categories
 * @param categories
 * @returns {{categories: *, type: string}}
 * @namespace Domneo/Blog/Store/Tags/Action/updateTags */
export const updateTags = (tags) => ({
    type: UPDATE_TAGS,
    isLoaded: true,
    tags,
});

/**
 * Update categories
 * @param categories
 * @returns {{categories: *, type: string}}
 * @namespace Domneo/Blog/Store/Tags/Action/updateTag */
export const updateTag = (tag) => ({
    type: UPDATE_TAG,
    isLoaded: true,
    tag,
});

/**
 * Update categories
 * @param categories
 * @returns {{categories: *, type: string}}
 * @namespace Domneo/Blog/Store/Tags/Action/clearTag */
export const clearTag = () => ({
    type: CLEAR_TAG,
});

/**
 * @param message
 * @returns {{type: string, message: *}}
 * @namespace Domneo/Blog/Store/Tags/Action/tagsException */
export const tagsException = (message) => ({
    type: NO_TAGS,
    message,
});
