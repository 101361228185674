import { fetchQuery } from 'Util/Request';

import { EVENT_GTM_PURCHASE } from '../component/GoogleTagManager/GoogleTagManager.events';
import GtmPurchaseQuery from '../query/GtmPurchase.query';

const getPurchase = ({ orderID, email, totals } = {}, event) => {
    const query = GtmPurchaseQuery.getGTMPurchase(orderID);

    fetchQuery(query).then(({ purchase }) => {
        event(EVENT_GTM_PURCHASE, {
            ...purchase,
            orderID,
            email,
            totals
        });
    });
};

const render = (args, callback, instance) => {
    const { orderID, email, event, totals } = instance.props;

    if (orderID && totals) {
        getPurchase({ orderID, email, totals }, event);
    }

    return callback.apply(instance, args);
};

export default {
    'Pwa/Component/CheckoutSuccess/Component/CheckoutSuccess': {
        'member-function': {
            render,
        },
    },
};
