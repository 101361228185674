import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import {
    CONSENTS_CHECKOUT,
    CONSENTS_CONTACT,
    CONSENTS_NEWSLETTER,
    CONSENTS_REGISTER,
} from 'Component/Consents/Consents.config';
import FIELD_TYPE from 'Component/Field/Field.config';
import AdvoxConsentQuery from 'Query/AdvoxConsent.query';
import getFieldsData from 'Util/Form/Extract';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';
import { validateGroup } from 'Util/Validator/Validator';

import Consents from './Consents.component';

/** @namespace Pwa/Component/Consents/Container/ConsentsContainer */
export class ConsentsContainer extends PureComponent {
    static propTypes = {
        type: PropTypes.oneOf([CONSENTS_REGISTER, CONSENTS_CONTACT, CONSENTS_NEWSLETTER, CONSENTS_CHECKOUT]),
        data: PropTypes.arrayOf(
            PropTypes.shape({
                consent_id: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                is_required: PropTypes.bool.isRequired,
                is_checkbox: PropTypes.bool.isRequired,
            })
        ),
    };

    static defaultProps = {
        data: null,
    };

    static async getConsentsByType(type) {
        if (!type) throw new Error('Consents type is required');

        try {
            return (await executeGet(prepareQuery(AdvoxConsentQuery.getAdvoxConsentsQuery(type))))?.getConsents;
        } catch {
            return Promise.resolve([]);
        }
    }

    static getConsents(element) {
        if (!element) {
            return [];
        }

        return getFieldsData(element, false, [FIELD_TYPE.number, FIELD_TYPE.button], false);
    }

    static validateConsents(element, rules = {}) {
        if (!element) {
            return true;
        }

        if (validateGroup(element, rules) !== true) {
            return false;
        }

        return validateGroup(element, rules) === true;
    }

    static getAllConsents(fields = []) {
        return fields.filter((field) => field.type === FIELD_TYPE.checkbox).map((consent) => consent.name);
    }

    static getAcceptedConsents(fields = []) {
        return fields
            .filter((field) => field.type === FIELD_TYPE.checkbox && field.value === true)
            .map((consent) => consent.name);
    }

    state = {
        data: [],
    };

    componentDidMount() {
        const { data, type } = this.props;

        if (data) {
            this.setState({ data });
        } else if (!data && type) {
            this.initialize();
        }
    }

    async initialize() {
        const { type } = this.props;

        this.setState({
            data: await ConsentsContainer.getConsentsByType(type),
        });
    }

    containerProps() {
        const { data } = this.state;

        return {
            data,
        };
    }

    render() {
        return <Consents {...this.containerProps()} />;
    }
}

export default ConsentsContainer;
