import { ProductListQuery } from 'Query/ProductList.query';
import { Field } from 'Util/Query';

/** @namespace Domneo/CatalogWidget/Query/CatalogWidget/Query/CatalogWidgetQuery */
export class CatalogWidgetQuery extends ProductListQuery {
    getCatalogWidgetQuery(widgetCode) {
        const query = new Field('catalogWidget')
            .addArgument('widget_code', 'String!', widgetCode)
            .addFieldList(this._getCatalogWidgetFields());

        return query;
    }

    _getCatalogWidgetFields() {
        return ['title', 'widget_code', this._getItemsField()];
    }

    _getLabelField() {
        return new Field('labels').addFieldList([
            'code',
            'image',
            'text',
            'text_background_color',
            'text_color',
            'type',
            'url',
        ]);
    }

    _getPriceExcludingTaxField() {
        return new Field('price_excluding_tax').addFieldList([
            this._getFinalPriceField(),
            this._getRegularPriceField(),
        ]);
    }

    _getPriceIncludingTaxField() {
        return new Field('price_including_tax').addFieldList([
            this._getFinalPriceField(),
            this._getRegularPriceField(),
        ]);
    }

    _getFinalPriceField() {
        return new Field('final_price').addFieldList(['currency', 'value']);
    }

    _getRegularPriceField() {
        return new Field('regular_price').addFieldList(['currency', 'value']);
    }

    _getRelatedProductsByAttribute() {
        return new Field('related_products_by_attribute').addFieldList([
            'url_key',
            'name',
            this._getProductImageField(),
            this._getProductSmallField(),
        ]);
    }

    _getItemsField() {
        return new Field('items').addFieldList([
            'sku',
            'name',
            'type_id',
            'url_key',
            'special_to_date',
            'entity_id',
            'image',
            'alternative_hover_image',
            this._getLabelField(),
            this._getStockItemField(),
            this._getPriceRangeField(),
            this._getTierPricesField(),
            this._getVariantsFieldX(),
            this._getRelatedProductsByAttribute(),
        ]);
    }

    _getVariantsFieldX() {
        return new Field('variants').setAlias('variants').addFieldList(this._getVariantFields());
    }
}

export default new CatalogWidgetQuery();
