import { connect } from 'react-redux';

import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import { CALCULATOR_UNIT } from 'Component/ProductActions/ProductActions.config';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductContainer as SourceProductContainer,
} from 'SourceComponent/Product/Product.container';
import { ADD_TO_CART } from 'Util/Product';
import { multiplyValues } from 'Util/Product/Calculator';
import { DEFAULT_MIN_PRODUCTS, getMaxQuantity, getMinQuantity, getProductQuantity } from 'Util/Product/Extract';
import { magentoProductTransform, transformParameters } from 'Util/Product/Transform';

import {
    DEFAULT_AREA_VALUE,
    DEFAULT_LENGTH_VALUE,
    DEFAULT_SUPPLY_VALUE,
    DEFAULT_WEIGHT_VALUE,
    DEFAULT_WIDTH_VALUE,
    getSideLengthMultiplier,
} from './Calculator.config';

export { mapDispatchToProps };

/** @namespace Pwa/Component/Product/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    device: state.ConfigReducer.device,
    percentageDiscountTreshold: state.ConfigReducer.percentage_discount_threshold,
    installmentsEnabled: state.ConfigReducer.installments_enabled,
    storePhoneNumber: state.ConfigReducer.phone_number,
});

/** @namespace Pwa/Component/Product/Container/ProductContainer */
export class ProductContainer extends SourceProductContainer {
    containerFunctions = {
        ...this.containerFunctions,
        handleCalculationArea: this.handleCalculationArea.bind(this),
        handleCalculationGlue: this.handleCalculationGlue.bind(this),
    };

    state = {
        enteredOptions: this.setDefaultProductOptions('defaultEnteredOptions', 'enteredOptions'),
        selectedOptions: this.setDefaultProductOptions('defaultSelectedOptions', 'selectedOptions'),
        downloadableLinks: [],
        quantity: 1,
        adjustedPrice: {},
        selectedProduct: null,
        parameters: this.props.parameters,
        width: DEFAULT_WIDTH_VALUE,
        length: DEFAULT_LENGTH_VALUE,
        supply: DEFAULT_SUPPLY_VALUE,
        area: DEFAULT_AREA_VALUE,
        weight: DEFAULT_WEIGHT_VALUE,
    };

    getMagentoProduct() {
        const { quantity, enteredOptions, selectedOptions, downloadableLinks, parameters } = this.state;

        const { product } = this.props;

        const { attributes, vp_items, product_unit } = product || {};

        const isProductWithCalculator = product_unit === CALCULATOR_UNIT;
        const productQuantity = isProductWithCalculator ? multiplyValues(vp_items, quantity) : quantity;

        const configurableOptions = transformParameters(parameters, attributes);

        return magentoProductTransform(ADD_TO_CART, product, productQuantity, enteredOptions, [
            ...selectedOptions,
            ...downloadableLinks,
            ...configurableOptions,
        ]);
    }

    getMaxQuantity() {
        const { product: { vp_items, product_unit } = {} } = this.props;
        const activeProduct = this.getActiveProduct();

        return product_unit === CALCULATOR_UNIT
            ? Math.floor(getMaxQuantity(activeProduct) / vp_items)
            : getMaxQuantity(activeProduct);
    }

    handleCalculationGlue(_, { name, value }) {
        const {
            product: { glue_volume: glueVolume },
        } = this.props;

        this.setState(
            {
                [name]: value,
            },
            () => {
                const maxQuantity = this.getMaxQuantity();
                const { area, length } = this.state;

                const areaValue = area.replace(',', '.');
                const lengthValue = length.replace(',', '.');

                if (Number.isNaN(areaValue) || Number.isNaN(lengthValue) || Number.isNaN(glueVolume)) {
                    return;
                }

                const weight = Math.ceil(
                    Number(areaValue) * (Number(lengthValue) > 0 ? getSideLengthMultiplier(Number(lengthValue)) : 0)
                );
                const quantity = Math.ceil(Number(weight) / Number(glueVolume)) || DEFAULT_MIN_PRODUCTS;

                this.setState(
                    {
                        weight,
                    },
                    () => this.setQuantity(quantity <= maxQuantity ? quantity : maxQuantity)
                );
            }
        );
    }

    handleCalculationArea(_, { name, value }) {
        const {
            product: { vp_items },
        } = this.props;

        this.setState(
            {
                [name]: value,
            },
            () => {
                const maxQuantity = this.getMaxQuantity();
                const { width, length, supply } = this.state;

                const widthValue = width.replace(',', '.');
                const lengthValue = length.replace(',', '.');

                if (Number.isNaN(widthValue) || Number.isNaN(lengthValue)) {
                    return;
                }

                const areaValue = Number(widthValue) * Number(lengthValue);
                const supplyValue = (Number(areaValue) * Number(supply)) / 100;
                const area = (Number(areaValue) + Number(supplyValue)).toFixed(2);
                const quantity = Math.ceil(Number(area) / Number(vp_items)) || DEFAULT_MIN_PRODUCTS;

                this.setState(
                    {
                        area,
                    },
                    () => this.setQuantity(quantity <= maxQuantity ? quantity : maxQuantity)
                );
            }
        );
    }

    static getDerivedStateFromProps(props, state) {
        const { quantity: quantityState } = state;

        const quantity = ProductContainer.getDefaultQuantity(props, state);

        if (quantity && typeof quantityState !== 'object') {
            return { quantity };
        }

        return null;
    }

    static getDefaultQuantity(props, state) {
        const { quantity, selectedProduct } = state;
        const { product, product: { type_id: typeId } = {} } = props;

        if (!product) {
            return null;
        }

        if (typeId === PRODUCT_TYPE.grouped || typeId === PRODUCT_TYPE.built) {
            const { items = [] } = product;

            return items.reduce(
                (o, { qty = 1, product: { id, ...product } }) => ({
                    ...o,
                    [id]: getProductQuantity(product, qty),
                }),
                {}
            );
        }

        const minQty = getMinQuantity(selectedProduct || product);

        if (quantity < minQty) {
            return DEFAULT_MIN_PRODUCTS;
        }

        const maxQty = getMaxQuantity(selectedProduct || product);

        if (quantity > maxQty) {
            return maxQty;
        }

        return null;
    }

    containerProps() {
        const { extended, device, percentageDiscountTreshold, cartPriceRules, installmentsEnabled, storePhoneNumber } =
            this.props;
        const { area, width, length, weight } = this.state;

        return {
            ...super.containerProps(),
            minQuantity: DEFAULT_MIN_PRODUCTS,
            maxQuantity: this.getMaxQuantity(),
            extended,
            device,
            area,
            width,
            length,
            weight,
            percentageDiscountTreshold,
            cartPriceRules,
            installmentsEnabled,
            storePhoneNumber,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
