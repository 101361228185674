import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ProductListQuery from 'Query/ProductList.query';
import { GroupedProductsItemContainer as SourceGroupedProductsItemContainer } from 'SourceComponent/GroupedProductsItem/GroupedProductsItem.container';
import { setArrangementBuiltProductHandler } from 'Store/ArrangementBuiltProduct/ArrangementBuiltProduct.action';
import { ProductType } from 'Type/ProductList.type';
import { getProductQuantity } from 'Util/Product/Extract';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';
import { FIVE_MINUTES_IN_SECONDS } from 'Util/Request/QueryDispatcher';

import GroupedProductsItem from './GroupedProductsItem.component';

/** @namespace Pwa/Component/GroupedProductsItem/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Pwa/Component/GroupedProductsItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setArrangementBuiltProduct: (isArrangementBuiltProduct, arrangementSrc) =>
        dispatch(setArrangementBuiltProductHandler(isArrangementBuiltProduct, arrangementSrc)),
});

/**
 * Product description
 * @class GroupedProductsItem
 * @namespace Pwa/Component/GroupedProductsItem/Container/GroupedProductsItemContainer */
export class GroupedProductsItemContainer extends SourceGroupedProductsItemContainer {
    static propTypes = {
        product: ProductType.isRequired,
        quantity: PropTypes.objectOf(PropTypes.number).isRequired,
        setQuantity: PropTypes.func.isRequired,
        defaultQuantity: PropTypes.number.isRequired,
        isHiddenElement: PropTypes.bool,
        required: PropTypes.bool,
    };

    static defaultProps = {
        isHiddenElement: false,
    };

    static PopperModifiers = [
        {
            name: 'offset',
            enabled: true,
            options: {
                offset: [0, 4],
            },
        },
    ];

    containerFunctions = {
        setQuantity: this.setQuantity.bind(this),
        setIncludingProduct: this.setIncludingProduct.bind(this),
        handleChangeProduct: this.handleChangeProduct.bind(this),
        handleClickChangeProduct: this.handleClickChangeProduct.bind(this),
    };

    __construct(props) {
        super.__construct(props);
        const { product: { id } = {} } = this.props;
        const defaultQuantity = this._getDefaultQuantity();

        this.state = {
            isIncluded: defaultQuantity > 0,
            isLoading: false,
            isSubstitutesLoaded: false,
            substitutes: [],
            selected: String(id),
        };

        this.setQuantity(defaultQuantity);
    }

    containerProps() {
        const {
            arrangementImageSrc,
            isHiddenElement,
            required,
            quantity,
            substitutes: substitutesToLoad,
            setArrangementBuiltProduct,
            isSwapActive,
        } = this.props;
        const { isIncluded, isLoading, isSubstitutesLoaded, selected } = this.state;

        return {
            arrangementImageSrc,
            itemCount: this._getCurrentQuantity(),
            isHiddenElement,
            required,
            isIncluded,
            quantity,
            isLoading,
            isSubstitutesLoaded,
            isSubstitutes: substitutesToLoad?.length > 0,
            product: this.getActiveProduct(),
            substitutes: this.getSubstituteProducts(),
            selected,
            setArrangementBuiltProduct,
            defaultQuantity: this._getDefaultQuantity(),
            isSwapActive,
        };
    }

    _getDefaultQuantity() {
        const { product, defaultQuantity } = this.props;

        return getProductQuantity(product, defaultQuantity);
    }

    /**
     * Get the selected quantity of grouped product
     * @return {Number} product quantity
     */
    _getCurrentQuantity() {
        const { quantity } = this.props;
        const { selected } = this.state || {};

        return quantity[selected] || 0;
    }

    setQuantity(itemCount) {
        const {
            setQuantity,
            product: { id },
        } = this.props;
        const { selected, isIncluded } = this.state || {};

        setQuantity(
            {
                [selected || id]: itemCount,
            },
            true
        );

        if (itemCount < 1 && isIncluded) {
            this.setState({ isIncluded: !isIncluded });
        }
    }

    setIncludingProduct() {
        const { isIncluded } = this.state;
        const defaultQuantity = this._getDefaultQuantity();

        this.setState({ isIncluded: !isIncluded }, () => {
            const { isIncluded, selected } = this.state;
            const { quantity } = this.props;

            /*
            This function is responsible for checkbox clicking
            If checkbox is not checked -> Set defaultQuantity and check it
            If checkbox is checked -> Set quantity to 0 and uncheck it
            */

            this.setQuantity(isIncluded && quantity[selected] < 1 ? defaultQuantity : 0);
        });
    }

    handleChangeProduct(_, { fields }) {
        const { product, quantity, setQuantity, substitutes, swapHandler } = this.props;
        const { selected: prevSelected } = this.state;
        const defaultQuantity = this._getDefaultQuantity();

        this.setState(
            {
                selected: fields.find((field) => field.value !== false)?.value,
                isSubstitutesLoaded: false,
            },
            () => {
                const { selected, isIncluded } = this.state;

                setQuantity(
                    {
                        ...Array.from([product, ...substitutes], ({ id } = {}) => id).reduce(
                            (quantity, id) => ({
                                ...quantity,
                                [id]: 0,
                            }),
                            {}
                        ),
                        [selected]: isIncluded ? quantity[prevSelected] || defaultQuantity : 0,
                    },
                    false
                );
                swapHandler(false);
            }
        );
    }

    async handleClickChangeProduct() {
        const { substitutes: substitutesToLoad, swapHandler } = this.props;

        if (!substitutesToLoad.length) {
            return;
        }

        this.setState({
            isSubstitutesLoaded: false,
            isLoading: this,
        });

        try {
            const { products: { items: substitutes = [] } = {} } = await executeGet(
                prepareQuery(
                    ProductListQuery.getQuery({
                        args: {
                            filter: {
                                productsIDArray: substitutesToLoad.map(({ id } = {}) => id),
                            },
                        },
                        getBuiltSubstitutes: true,
                    }),
                    'product_built_substitutes',
                    FIVE_MINUTES_IN_SECONDS
                )
            );

            this.setState({
                isSubstitutesLoaded: true,
                substitutes,
            });
        } finally {
            this.setState({
                isLoading: false,
            });
            swapHandler(true);
        }
    }

    getActiveProduct() {
        const { product } = this.props;
        const { substitutes, selected } = this.state;
        return substitutes.find((substitute) => String(substitute.id) === selected) || product;
    }

    getSubstituteProducts() {
        const { product } = this.props;
        const { substitutes } = this.state;

        return [product, ...substitutes];
    }

    render() {
        return <GroupedProductsItem {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupedProductsItemContainer);
