export const SUPPLY_OPTIONS = [
    {
        label: '0%',
        value: '0',
    },
    {
        label: '10%',
        value: '10',
    },
];

export const DEFAULT_WIDTH_VALUE = '0';
export const DEFAULT_LENGTH_VALUE = '0';
export const DEFAULT_SUPPLY_VALUE = '0';
export const DEFAULT_AREA_VALUE = '0';
export const DEFAULT_WEIGHT_VALUE = '0';

/** @namespace Pwa/Component/Product/Calculator/Config/getSideLengthMultiplier */
export const getSideLengthMultiplier = (sideLength) => {
    if (sideLength <= 10) {
        return 2;
    }

    if (sideLength > 10 && sideLength <= 15) {
        return 2.6;
    }

    if (sideLength > 15 && sideLength <= 25) {
        return 3.3;
    }

    if (sideLength > 25) {
        return 4;
    }

    return 0;
};
