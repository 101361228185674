import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/Breadcrumbs/Breadcrumbs.container';
import BreadcrumbsReducer from 'Store/Breadcrumbs/Breadcrumbs.reducer';
import CategoryReducer from 'Store/Category/Category.reducer';
import { withReducers } from 'Util/DynamicReducer';

import Breadcrumbs from './Breadcrumbs.component';

export { mapDispatchToProps };

/** @namespace Pwa/Component/Breadcrumbs/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    device: state.ConfigReducer.device,
    category: state.CategoryReducer.category,
    baseUrl: state.ConfigReducer.base_url,
});

export default withReducers({
    BreadcrumbsReducer,
    CategoryReducer,
})(connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs));
