import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';
import { getPageType } from '../util/Page';

const appendOpenGraphTags = (args, callback, ctx) => {
  const meta = callback(...args);
  const opengrap = ctx._generateMetaFromMetadata(
    ctx.getOpenGrapsTags(),
    'property'
  );

  return [...meta, ...opengrap];
};

const getOpenGrapsTags = (args, callback, ctx) => {
  return {
    'og:url': window.location.href,
    'og:description': ctx._getDescription(),
    'og:title': ctx._getTitle(),
    'og:image': ctx.getImage(),
    'og:type': 'website',
    'twitter:card': 'summary_large_image',
    'twitter:domain': window.location.host,
    'twitter:url': window.location.href,
    'twitter:title': ctx._getTitle(),
    'twitter:description': ctx._getDescription(),
    'twitter:image': ctx.getImage(),
  };
};

const getImage = (args, callback, ctx) => {
  return ctx.props?.image
    ? getPageType() !== 'pdp'
      ? media(ctx.props?.default_image, LOGO_MEDIA)
      : ctx.props?.image
    : media(ctx.props?.default_image, LOGO_MEDIA);
};

const appendImageToMapStateToProps = (args, callback) => {
  const initialState = callback(...args);
  const state = args[0];

  return {
    ...initialState,
    default_image: state.ConfigReducer.header_logo_src,
    image: state.MetaReducer.image,
  };
};

export default {
  'Component/Meta/Container': {
    'member-function': {
      _getMetadata: appendOpenGraphTags,
      getImage,
      getOpenGrapsTags,
    },
  },
  'Component/Meta/Container/mapStateToProps': {
    function: appendImageToMapStateToProps,
  },
};
