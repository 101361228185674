import { AddToCart as SourceAddToCart } from 'SourceComponent/AddToCart/AddToCart.component';

import './AddToCart.override.style';

/** @namespace Pwa/Component/AddToCart/Component/AddToCart */
export class AddToCart extends SourceAddToCart {
    render() {
        const {
            mix,
            addProductToCart,
            layout,
            isDisabled,
            isAdding,
            isBuyNowButton,
            isCrossSellButton,
            isShortButtonText,
            customTexts = {},
        } = this.props;

        return (
            <button
                onClick={(e) => addProductToCart(e, isBuyNowButton)}
                block="Button AddToCart"
                mix={mix}
                mods={{ layout }}
                disabled={isDisabled || isAdding}
            >
                {this.renderCartIcon()}
                {!isBuyNowButton && !isCrossSellButton && !isShortButtonText && (
                    <span>{isAdding ? __('Adding...') : customTexts.add || __('Add to cart')}</span>
                )}
                {(isCrossSellButton || isShortButtonText) && (
                    <span>{isAdding ? __('Adding...') : customTexts.add || __('Add basket')}</span>
                )}
                {isBuyNowButton && <span>{isAdding ? __('Adding...') : customTexts.add || __('Buy now')}</span>}
            </button>
        );
    }
}

export default AddToCart;
