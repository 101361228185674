import React from 'react';

import Field from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import FieldGroup from 'Component/FieldGroup';
import Icons from 'Component/Icons';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { CALCULATOR_UNIT } from 'Component/ProductActions/ProductActions.config';
import ProductLabel from 'Component/ProductLabel';
import ProductPrice from 'Component/ProductPrice';
import ProductStockAvailability from 'Component/ProductStockAvailability';
import TextPlaceholder from 'Component/TextPlaceholder';
import { GroupedProductsItem as SourceGroupedProductsItem } from 'SourceComponent/GroupedProductsItem/GroupedProductsItem.component';
import withPopper from 'Util/Popper/Popper.hoc';
import { multiplyValues } from 'Util/Product/Calculator';
import { getMaxQuantity, getMinQuantity, getPrice, getProductInStock } from 'Util/Product/Extract';
import { VALIDATION_INPUT_TYPE_NUMBER } from 'Util/Validator/Config';

import './GroupedProductsItem.override.style';

/**
 * Product description
 * @class GroupedProductsItem
 * @namespace Pwa/Component/GroupedProductsItem/Component/GroupedProductsItem */
export class GroupedProductsItem extends SourceGroupedProductsItem {
    state = {
        referenceElement: null,
        hasInitialized: false,
    };

    handlePopperOpen({ target: referenceElement }) {
        this.setState({
            referenceElement,
        });
    }

    handlePopperClose() {
        this.setState({
            referenceElement: null,
        });
    }

    renderHover() {
        return (
            <div block="GroupedProductsTooltip" elem="Hover">
                <span />
            </div>
        );
    }

    renderPopper() {
        const { setPopperElement, setArrowElement, popper } = this.props;
        const { referenceElement } = this.state;
        const { styles, attributes } = popper ?? {};

        if (!referenceElement) {
            return null;
        }

        return (
            <div
                block="GroupedProductsTooltip"
                elem="Popper"
                style={styles.popper}
                ref={setPopperElement}
                {...attributes.popper}
            >
                <div block="GroupedProductsTooltip" elem="Content">
                    <div block="GroupedProductsTooltip" elem="Arrow" ref={setArrowElement} style={styles.arrow} />
                    <div block="GroupedProductsTooltip" elem="Description">
                        {__('You cannot deselect this product because it is an integral part of the arrangement.')}
                    </div>
                </div>
            </div>
        );
    }

    renderTitle() {
        const {
            isSubstitutes,
            product: { name, url },
            handleClickChangeProduct,
            arrangementImageSrc,
            setArrangementBuiltProduct,
            isSwapActive,
        } = this.props;

        return (
            <div block="GroupedProductsItem" elem="Title">
                <Link
                    to={url}
                    block="GroupedProductsItem"
                    elem="Link"
                    onClick={() => setArrangementBuiltProduct(true, arrangementImageSrc)}
                >
                    <TextPlaceholder content={name} />
                </Link>
                {isSubstitutes ? (
                    <button
                        block="GroupedProductsItem"
                        elem="ChangeProduct"
                        type="button"
                        onClick={handleClickChangeProduct}
                        disabled={isSwapActive}
                        title={isSwapActive ? __('Complete open swaps') : ''}
                    >
                        <span>{__('change')}</span> <Icons width="20" height="14" name="change_product" />
                    </button>
                ) : null}
            </div>
        );
    }

    renderLabel() {
        const {
            product: { labels },
        } = this.props;

        return <ProductLabel labels={labels} />;
    }

    renderDeliveryTime() {
        const {
            product: { stock_availability, deliverer_available },
        } = this.props;

        return (
            <div>
                {this.renderCalculator()}
                <ProductStockAvailability
                    stock_availability={stock_availability}
                    deliverer_available={deliverer_available}
                />
            </div>
        );
    }

    renderCalculator() {
        const {
            product: { product_unit, vp_items },
            itemCount = 0,
        } = this.props;

        if (product_unit !== CALCULATOR_UNIT || !vp_items) {
            return null;
        }

        return (
            <div block="GroupedProductsItem" elem="Calculator">
                <span>
                    {multiplyValues(vp_items, itemCount)} m<sup>2</sup>
                </span>
            </div>
        );
    }

    renderQuantity() {
        const {
            product = {},
            product: { id, vp_items, product_unit, stock_item: { qty_increments: qtyIncrement = 1 } = {} } = {},
            setQuantity,
            itemCount = 0,
            required,
        } = this.props;

        if (!getProductInStock(product)) {
            return (
                <div block="GroupedProductsItem" elem="OutOfStock">
                    {__('Out of stock')}
                </div>
            );
        }

        const min = getMinQuantity(product);
        const max = getMaxQuantity(product);

        const isDisabled =
            product_unit === CALCULATOR_UNIT ? Number(vp_items) === 0 || Number.isNaN(Number(vp_items)) : false;

        return (
            <div block="ProductActions" elem="Quantity" role="button" tabIndex="-1">
                <Field
                    type={FIELD_TYPE.number}
                    attr={{
                        id: `item_qty_${id}`,
                        name: `item_qty_${id}`,
                        defaultValue: itemCount,
                        value: itemCount,
                        min: !isDisabled ? (required ? 1 : 0) : 0,
                        step: qtyIncrement,
                        max,
                    }}
                    isDisabled={isDisabled}
                    validationRule={{
                        inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric,
                        isRequired: true,
                        match: this.getError.bind(this),
                        range: {
                            min: min === 1 ? 0 : 1,
                            max,
                        },
                    }}
                    events={{ onChange: setQuantity }}
                    validateOn={['onChange', 'onload']}
                    mix={{ block: 'ProductActions', elem: 'Qty' }}
                />
            </div>
        );
    }

    renderCheckbox() {
        const { product: { id } = {}, setIncludingProduct, setReferenceElement, required, itemCount = 0 } = this.props;

        if (required) {
            return (
                // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                <div
                    block="GroupedProductsTooltip"
                    onMouseEnter={this.handlePopperOpen.bind(this)}
                    onMouseLeave={this.handlePopperClose.bind(this)}
                    ref={setReferenceElement}
                >
                    <Field
                        type={FIELD_TYPE.checkbox}
                        attr={{
                            name: `arrangement_item_${id}`,
                            checked: true,
                            disabled: true,
                        }}
                        mix={{ block: 'GroupedProductsItem', elem: 'Checkbox' }}
                    />
                    {this.renderHover()}
                    {this.renderPopper()}
                </div>
            );
        }

        return (
            <Field
                type={FIELD_TYPE.checkbox}
                attr={{
                    name: `arrangement_item_${id}`,
                    checked: itemCount > 0,
                }}
                events={{
                    onChange: setIncludingProduct,
                }}
                mix={{ block: 'GroupedProductsItem', elem: 'Checkbox' }}
            />
        );
    }

    renderContent() {
        const {
            product: { price_range: priceRange, type_id: type, dynamic_price: dynamicPrice, product_unit, vp_items },
            isSubstitutesLoaded,
            substitutes,
            selected,
            handleChangeProduct,
            itemCount,
        } = this.props;

        if (isSubstitutesLoaded) {
            return (
                <div block="GroupedProductsItem" elem="Substitutes">
                    <FieldGroup events={{ onClick: handleChangeProduct }}>
                        {substitutes.map(({ name, id }) => (
                            <Field
                                key={id}
                                attr={{ name: 'product', value: String(id), checked: selected === String(id) }}
                                label={name}
                                type={FIELD_TYPE.radio}
                            />
                        ))}
                    </FieldGroup>
                </div>
            );
        }

        return (
            <div block="GroupedProductsItem" elem="Content">
                {this.renderTitle()}
                <div block="GroupedProductsItem" elem="Details">
                    {this.renderDeliveryTime()}
                    {this.renderQuantity()}
                    {this.renderTierPrices()}
                    <ProductPrice
                        increment={product_unit === CALCULATOR_UNIT ? vp_items : undefined}
                        quantity={itemCount}
                        price={getPrice(priceRange, dynamicPrice, {}, type)}
                        mods={{ type: 'regular' }}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { isHiddenElement, isLoading } = this.props;

        return (
            <li block="GroupedProductsItem" aria-label="Product Item" mods={{ isHiddenElement }}>
                <Loader isLoading={isLoading} />
                {this.renderImage()}
                {this.renderLabel()}
                {this.renderCheckbox()}
                {this.renderContent()}
            </li>
        );
    }
}

export default withPopper(GroupedProductsItem, {
    strategy: 'fixed',
    placement: 'top',
    modifiers: [
        {
            name: 'offset',
            options: {
                offset: [0, 10],
            },
        },
    ],
});
