import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductWishlistButtonContainer as SourceProductWishlistButtonContainer,
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';

export { mapStateToProps, mapDispatchToProps };

/** @namespace Pwa/Component/ProductWishlistButton/Container/ProductWishlistButtonContainer */
export class ProductWishlistButtonContainer extends SourceProductWishlistButtonContainer {
    async toggleProductInWishlist(add = true) {
        const {
            magentoProduct,
            magentoProduct: [{ sku }] = [],
            isAddingWishlistItem,
            addProductToWishlist,
            removeProductFromWishlist,
            wishlistId,
        } = this.props;

        if (isAddingWishlistItem) {
            return null;
        }

        this.setWishlistButtonLoading(true);

        if (add) {
            await addProductToWishlist({
                items: magentoProduct,
                wishlistId,
            });

            return;
        }

        const wishlistItem = this.getWishlistItem(sku);
        if (!wishlistItem) {
            return;
        }

        const {
            wishlist: { id: itemId },
        } = wishlistItem;

        return removeProductFromWishlist({ item_id: itemId });
    }

    isDisabled() {
        const { isAddingWishlistItem } = this.props;
        return isAddingWishlistItem;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlistButtonContainer);
