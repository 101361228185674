import capitalize from 'lodash/capitalize';

import { PRODUCT_TYPE } from 'Component/Product/Product.config';

import { NOT_APPLICABLE } from '../component/GoogleTagManager/GoogleTagManager.config';
import { getBrand, getCategoriesLevel, getPrice, getProductName, getVariant } from './baseProduct.data';
import { getPageType } from './general.data';

/** @namespace Domneo/Gtm/EventData/ActionProduct/Data/getDimensionOne */
export const getDimensionOne = (type_id, product, configurableVariantIndex, mainSku) => {
    if (type_id === PRODUCT_TYPE.simple) {
        return NOT_APPLICABLE;
    }

    if (!configurableVariantIndex && mainSku && type_id === PRODUCT_TYPE.configurable) {
        return name;
    }

    if (!isNaN(configurableVariantIndex) && configurableVariantIndex !== false) {
        const { name = '' } = product.variants[configurableVariantIndex];

        return name;
    }

    return NOT_APPLICABLE;
};

/** @namespace Domneo/Gtm/EventData/ActionProduct/Data/getDimensionTwo */
export const getDimensionTwo = (type_id, product, configurableVariantIndex, inQtyChange = false, mainSku) => {
    if (type_id === PRODUCT_TYPE.simple) {
        return NOT_APPLICABLE;
    }

    if (!isNaN(configurableVariantIndex) && configurableVariantIndex !== false) {
        if (inQtyChange) {
            return mainSku;
        }
        const { sku } = product.variants[configurableVariantIndex];

        return sku;
    }

    return NOT_APPLICABLE;
};

/** @namespace Domneo/Gtm/EventData/ActionProduct/Data/productAddToCart */
export const productAddToCart = ({ product, quantity = 0, configurableVariantIndex = false }, store) => {
    const { type_id = PRODUCT_TYPE.simple, categories, sku, price = false } = product;

    return {
        item_name: getProductName(type_id, product),
        item_id: sku,
        price: getPrice(product, type_id, price),
        item_brand: getBrand(product),
        item_variant: getVariant(product, type_id, configurableVariantIndex),
        item_list_name: capitalize(getPageType(store).pageType),
        quantity,
        ...getCategoriesLevel(categories)
    };
};

/** @namespace Domneo/Gtm/EventData/ActionProduct/Data/productQtyChangeData */
export const productQtyChangeData = ({ product: item, configurableVariantIndex = false }, store) => {
    const {
        qty = false,
        price,
        product: { type_id = PRODUCT_TYPE.simple, categories, sku: parentSku, name: parentName, variants },
        product,
    } = item;

    return {
        item_name: parentName,
        item_id: parentSku,
        price:
            type_id === PRODUCT_TYPE.configurable
                ? getPrice(variants[0], type_id, price, false)
                : getPrice(product, type_id, price, false),
        item_brand: getBrand(product),
        item_variant: getVariant(product, type_id, configurableVariantIndex),
        item_list_name: capitalize(getPageType(store).pageType),
        quantity: Math.abs(qty),
        ...getCategoriesLevel(categories)
    };
};
