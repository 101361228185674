const _getDefaultFields = (args, callback, instance) => [
    ...callback.apply(instance, args),
    'additional_description'
];

export default {
    'Query/Category/Query': {
        'member-function': {
            _getDefaultFields
        }
    }
};