import PropTypes from 'prop-types';

import ArrowDownIcon from 'Component/ArrowDownIcon';
import ArrowRightIcon from 'Component/ArrowRightIcon';
import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, RIGHT, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ExpandableContent as SourceExpandableContent } from 'SourceComponent/ExpandableContent/ExpandableContent.component';

import './ExpandableContent.override.style';

/** @namespace Pwa/Component/ExpandableContent/Component/ExpandableContent */
export class ExpandableContent extends SourceExpandableContent {
    static propTypes = {
        ...SourceExpandableContent.propTypes,
        expandableDesktop: PropTypes.bool,
    };

    static defaultProps = {
        ...SourceExpandableContent.defaultProps,
        expandableDesktop: false,
    };

    renderButtonIcon() {
        const { isContentExpanded } = this.state;
        const { expandableDesktop } = this.props;
        const {
            isArrow,
            device: { isMobile },
        } = this.props;

        if (!isMobile && !expandableDesktop) {
            return null;
        }

        if (isArrow) {
            return <ChevronIcon direction={isContentExpanded ? TOP : BOTTOM} />;
        }

        return <ChevronIcon direction={isContentExpanded ? BOTTOM : RIGHT} />;
    }

    renderButton() {
        const { isContentExpanded } = this.state;
        const { heading, mix } = this.props;

        return (
            <div
                role="button"
                tabIndex={0}
                block="ExpandableContent"
                elem="Button"
                mods={{ isContentExpanded }}
                mix={{ ...mix, elem: 'ExpandableContentButton' }}
                onClick={this.toggleExpand}
                onKeyDown={this.toggleExpand}
            >
                <div block="ExpandableContent" elem="Heading" mix={{ ...mix, elem: 'ExpandableContentHeading' }}>
                    {typeof heading === 'string' ? <TextPlaceholder content={heading} length="medium" /> : heading}
                </div>
                <div block="ExpandableContent" elem="Icon">
                    {this.renderButtonIcon()}
                </div>
            </div>
        );
    }

    renderTogglePlusMinus() {
        const { isContentExpanded } = this.state;

        if (isContentExpanded) {
            return <ArrowDownIcon />;
        }

        return <ArrowRightIcon />;
    }

    render() {
        const { mix, mods, expandableDesktop } = this.props;

        return (
            <article
                block="ExpandableContent"
                mix={mix}
                mods={{ expandableDesktop, ...mods }}
                ref={this.expandableContentRef}
            >
                {this.renderButton()}
                {this.renderContent()}
            </article>
        );
    }
}

export default ExpandableContent;
