import { EVENT_GTM_VIEW_CART } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
    };
};

const componentDidMount = (args, callback, instance) => {
    const { event } = instance.props;

    event(EVENT_GTM_VIEW_CART);

    callback.apply(instance, args);
};

export default {
    'Route/CartPage/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
    'Route/CartPage/Container': {
        'member-function': {
            componentDidMount,
        },
    },
};
