import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import { roundPrice } from 'Util/Price';

import {
 getBrand, getCategories, getCategoriesLevel, getPrice, getVariant
} from './baseProduct.data';

/** @namespace Domneo/Gtm/EventData/Purchase/Data/productsPurchase */
export const productsPurchase = ({ products, configurableVariantIndex = false }) =>
    products?.map(
    (
        {
            qty = false,
            price,
            sku: mainSku = '',
            product: { type_id = PRODUCT_TYPE.simple, categories, sku: parentSku, name: parentName, variants },
            product,
        },
        key
    ) => ({
        item_name: parentName,
        item_id: parentSku,
        price:
                type_id === PRODUCT_TYPE.configurable
                    ? getPrice(variants[0], type_id, price, false)
                    : getPrice(product, type_id, price, false),
        category: getCategories(categories),
        item_brand: getBrand(product),
        item_variant: getVariant(product, type_id, configurableVariantIndex),
        quantity: Math.abs(qty),
        index: key,
        ...getCategoriesLevel(categories),
    })
);

/** @namespace Domneo/Gtm/EventData/Purchase/Data/getPurchaseData */
export const getPurchaseData = (
    {
 orderID, revenue, tax, shipping, email, totals: { items: products = [] } = {}
} = {},
    currency
) => ({
    currency,
    transaction_id: orderID,
    value: +roundPrice(revenue),
    tax: +roundPrice(tax),
    shipping: +roundPrice(shipping),
    items: productsPurchase({ products }),
    email
});
