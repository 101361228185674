import { PureComponent } from 'react';

import Icons from 'Component/Icons';

import './SliderNext.style.scss';

/** @namespace Pwa/Component/SliderNext/Component/SliderNextComponent */
export class SliderNextComponent extends PureComponent {
    render() {
        const { onClick, className } = this.props;
        const isDisabled = className ? className.search('disabled') !== -1 : false;

        return (
            <div block="SliderNext" mods={{ disabled: isDisabled }} onClick={onClick}>
                <Icons name="arrow_right" />
            </div>
        );
    }
}

export default SliderNextComponent;
