import { CALCULATOR_UNIT } from 'Component/ProductActions/ProductActions.config';

export * from 'SourceUtil/Cart/Cart';

/** @namespace Pwa/Util/Cart/getPreviewCartId */
export const getPreviewCartId = () => location?.pathname.substring(location?.pathname.lastIndexOf('/') + 1);

/** @namespace Pwa/Util/Cart/isBuiltProductRequired */
export const isBuiltProductRequired = (product, items) => {
    const products = items?.reduce((items, item) => [...items, item], []);
    const substitutes = items?.items?.reduce(
        (items, item) => [
            ...items,
            ...item?.substitutes?.reduce(
                (substitutes, substitute) => [...substitutes, { ...item, product: substitute }],
                []
            ),
        ],
        []
    );

    return (
        products
            ?.map((item) => ({
                ...item,
                ...(substitutes?.find((i) => i?.product?.id === item?.product?.id) || {}),
            }))
            ?.find((item) => item?.product?.id === product?.id)?.required || false
    );
};

/** @namespace Pwa/Util/Cart/getCartBuiltProductsTotals */
export const getCartBuiltProductsTotals = (group, items) => {
    const getDiscount = () =>
        Number(
            Object.values(group.attributes).find((element) => element.attribute_code === 'built_discount')
                ?.attribute_value
        ) / 100 ?? 0;

    const getAmount = (data, type) => {
        if (type) {
            return data?.reduce(
                (amount, { qty, product }) =>
                    amount + (product.price_range.maximum_price[type]?.value ?? 0) * 100 * qty,
                0
            );
        }

        return 0;
    };

    return {
        item_count: items.reduce(
            (count, { qty, product } = {}) => count + (product?.product_unit === CALCULATOR_UNIT ? 1 : qty),
            0
        ),
        regular_price: Math.round(getAmount(items, 'regular_price')) / 100,
        special_price: Math.round(getAmount(items, 'final_price')) / 100,
        final_price:
            Math.round(getAmount(items, 'final_price') - Math.round(getAmount(items, 'final_price') * getDiscount())) /
            100,
        discount_price:
            Math.round(
                getAmount(items, 'regular_price') -
                    Math.round(
                        getAmount(items, 'final_price') - Math.round(getAmount(items, 'final_price') * getDiscount())
                    )
            ) / 100,
        discount_value: getDiscount(),
    };
};
