import CheckoutInpost from '../component/CheckoutInpost';

const render = (args, callback, instance) => {
    const { option: { available, carrier_code } = {}, isSelected } = instance.props;
    return (
        <>
            {callback.apply(instance, args)}
            {isSelected && carrier_code === 'inpost' && <CheckoutInpost disabled={!available} />}
        </>
    );
};

export default {
    'Component/CheckoutDeliveryOption/Component': {
        'member-function': {
            render,
        },
    },
};
