import CatalogWidget from '@domneo/catalogWidget/src/components/CatalogWidget';

export const catalogWidgetRules = (originalMember, instance) => {
    const replaceCatalogWidget = ({ attribs }) => {
        const { 'data-catalog-widget': catalogWidget } = instance.attributesToProps(attribs);

        return <CatalogWidget code={catalogWidget} />;
    };

    return [
        ...originalMember,
        {
            query: { attribs: ['data-catalog-widget'] },
            replace: replaceCatalogWidget,
        },
    ];
};

export const config = {
    'Component/Html/Component': {
        'member-property': {
            rules: catalogWidgetRules
        },
    }
};

export default config;
