import { getStaticReducers as baseGetStaticReducers } from 'SourceStore/index';
import ArrangementBuiltProductReducer from 'Store/ArrangementBuiltProduct/ArrangementBuiltProduct.reducer';
import LayoutReducer from 'Store/Layout/Layout.reducer';

/** @namespace Pwa/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...baseGetStaticReducers(),
    ArrangementBuiltProductReducer,
    LayoutReducer,
});

export default function injectStaticReducers(store) {
    Object.entries(getStaticReducers()).forEach(([name, reducer]) => store.injectReducer(name, reducer));

    return store;
}
