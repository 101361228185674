import AddToCart from 'Component/AddToCart';
import Field from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import { CALCULATOR_UNIT } from 'Component/ProductActions/ProductActions.config';
import ProductPrice from 'Component/ProductPrice';
import ProductReviewRating from 'Component/ProductReviewRating';
import TextPlaceholder from 'Component/TextPlaceholder';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { Product as SourceProduct } from 'SourceComponent/Product/Product.component';
import { formatPrice } from 'Util/Price';
import { multiplyValues } from 'Util/Product/Calculator';
import { VALIDATION_INPUT_TYPE_NUMBER } from 'Util/Validator/Config';

import { SUPPLY_OPTIONS } from './Calculator.config';

/** @namespace Pwa/Component/Product/Component/Product */
export class Product extends SourceProduct {
    renderAddToCartButton(layout = GRID_LAYOUT) {
        const { addToCart, inStock, quantity, getActiveProduct } = this.props;

        const product = getActiveProduct();

        return (
            <AddToCart
                mix={{ block: this.className, elem: 'AddToCart' }}
                addToCart={addToCart}
                isDisabled={!inStock || Object.keys(product).length === 0}
                isIconEnabled={false}
                layout={layout}
                quantity={quantity}
                product={product}
            />
        );
    }

    renderBuyNowButton(layout = GRID_LAYOUT) {
        const { addToCart, inStock, quantity, getActiveProduct } = this.props;

        const product = getActiveProduct();

        return (
            <AddToCart
                block="Button"
                mix={{ block: 'Button', mods: { isHollow: true } }}
                addToCart={addToCart}
                isDisabled={!inStock || Object.keys(product).length === 0}
                isIconEnabled={false}
                layout={layout}
                quantity={quantity}
                product={product}
                isBuyNowButton
            />
        );
    }

    renderBrand(withMeta = false) {
        const {
            product: { attributes: { nazwa_producenta: { attribute_value: brand } = {} } = {} },
        } = this.props;

        if (!brand) {
            return null;
        }

        return (
            <>
                {withMeta && <meta itemProp="brand" content={brand} />}
                <h4 block={this.className} elem="Brand" itemProp="brand">
                    <TextPlaceholder content={brand} />
                </h4>
            </>
        );
    }

    renderRatingSummary() {
        const {
            product: { rating_summary, review_count },
        } = this.props;

        if (!rating_summary) {
            return null;
        }

        return <ProductReviewRating summary={rating_summary || 0} count={review_count} />;
    }

    renderPrice(isPreview = false) {
        const { getActiveProduct, productPrice } = this.props;
        const product = getActiveProduct();

        const {
            type_id: type,
            price_tiers: priceTiers,
            product_unit,
            is_virtual_design: isVirtualProduct = false,
        } = product;
        const isProductWithCalculator = product_unit === CALCULATOR_UNIT;

        if (!productPrice) {
            return null;
        }

        return (
            <div block={this.className} elem="PriceWrapper">
                <ProductPrice
                    isSchemaRequired
                    price={productPrice}
                    priceType={type}
                    tierPrices={priceTiers}
                    isPreview={isPreview}
                    isProductWithCalculator={isProductWithCalculator}
                    isVirtualProduct={isVirtualProduct}
                    mix={{ block: this.className, elem: 'Price' }}
                />
            </div>
        );
    }

    maxQuantityForCalculator() {
        const {
            maxQuantity,
            product: { vp_items },
        } = this.props;

        return Math.floor(maxQuantity / vp_items);
    }

    renderQuantity() {
        const {
            product: { product_unit },
        } = this.props;
        const quantityTitle = product_unit === CALCULATOR_UNIT ? __('Packages') : __('Quantity');

        return (
            <div block="ProductActions" elem="Quantity">
                <span block="ProductActions" elem="QuantityTitle">
                    {quantityTitle}
                </span>
                {this.renderQuantityChanger()}
            </div>
        );
    }

    renderCalculatorGlue() {
        const {
            area,
            length,
            weight,
            quantity,
            product: { glue_volume },
            handleCalculationGlue,
        } = this.props;

        if (!glue_volume) {
            return null;
        }

        return (
            <div block={this.className} elem="CalculatorWrapper">
                <div block={this.className} elem="CalculatorHeading">
                    {__('Calculate the number of glue needed')}
                </div>
                <div block={this.className} elem="Calculator" mods={{ glue: true }}>
                    <div block={this.className} elem="CalculatorBox" mods={{ value: true, field: true }}>
                        <Field
                            attr={{
                                name: 'area',
                                defaultValue: area,
                                noValidate: true,
                            }}
                            events={{ onChange: handleCalculationGlue }}
                            type={FIELD_TYPE.text}
                            validateOn={['onChange']}
                            validationRule={{ inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric }}
                        />
                    </div>
                    <div block={this.className} elem="CalculatorBox" mods={{ value: true, field: true }}>
                        <Field
                            attr={{
                                name: 'length',
                                defaultValue: length,
                                noValidate: true,
                            }}
                            events={{ onChange: handleCalculationGlue }}
                            type={FIELD_TYPE.text}
                            validateOn={['onChange']}
                            validationRule={{ inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric }}
                        />
                    </div>
                    <div
                        block={this.className}
                        elem="CalculatorBox"
                        mods={{ label: true, field: true, separate: true }}
                    >
                        <span>
                            {__('area')} (m<sup>2</sup>)
                        </span>
                    </div>
                    <div
                        block={this.className}
                        elem="CalculatorBox"
                        mods={{ label: true, field: true, separate: true }}
                    >
                        {__('side length')} (cm)
                    </div>
                    <div block={this.className} elem="CalculatorBox" mods={{ value: true }}>
                        {weight}
                    </div>
                    <div block={this.className} elem="CalculatorBox" mods={{ value: true, field: true }}>
                        {this.renderQuantityChanger()}
                    </div>
                    <div block={this.className} elem="CalculatorBox" mods={{ label: true }}>
                        {__('you need')} (kg)
                    </div>
                    <div block={this.className} elem="CalculatorBox" mods={{ label: true, field: true }}>
                        {quantity <= 1 ? __('Package') : quantity >= 5 ? __('Boxes') : __('Packages')}
                    </div>
                </div>
            </div>
        );
    }

    renderCalculatorArea() {
        const {
            area,
            width,
            length,
            product: { vp_items },
            handleCalculationArea,
        } = this.props;

        if (!vp_items) {
            return null;
        }

        return (
            <div block={this.className} elem="CalculatorWrapper">
                <div block={this.className} elem="CalculatorHeading">
                    {__('Calculate the quantity needed')}
                </div>
                <div block={this.className} elem="Calculator">
                    <div block={this.className} elem="CalculatorBox" mods={{ value: true, field: true, width: true }}>
                        <Field
                            attr={{
                                name: 'width',
                                defaultValue: width,
                                noValidate: true,
                            }}
                            events={{ onChange: handleCalculationArea }}
                            type={FIELD_TYPE.text}
                            validateOn={['onChange']}
                            validationRule={{ inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric }}
                        />
                    </div>
                    <div block={this.className} elem="CalculatorBox" mods={{ value: true, field: true, length: true }}>
                        <Field
                            attr={{
                                name: 'length',
                                defaultValue: length,
                                noValidate: true,
                            }}
                            events={{ onChange: handleCalculationArea }}
                            type={FIELD_TYPE.text}
                            validateOn={['onChange']}
                            validationRule={{ inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric }}
                        />
                    </div>
                    <div block={this.className} elem="CalculatorBox" mods={{ value: true, field: true, supply: true }}>
                        <Field
                            attr={{
                                name: 'supply',
                                noPlaceholder: true,
                                noValidate: true,
                            }}
                            options={SUPPLY_OPTIONS}
                            events={{ onChange: handleCalculationArea }}
                            type={FIELD_TYPE.select}
                            validateOn={['onChange']}
                            validationRule={{ isRequired: true }}
                        />
                    </div>
                    <div
                        block={this.className}
                        elem="CalculatorBox"
                        mods={{ label: true, field: true, widthName: true }}
                    >
                        <span>{__('width')} (m)</span>
                    </div>
                    <div
                        block={this.className}
                        elem="CalculatorBox"
                        mods={{ label: true, field: true, lengthName: true }}
                    >
                        <span>{__('length')} (m)</span>
                    </div>
                    <div
                        block={this.className}
                        elem="CalculatorBox"
                        mods={{ label: true, field: true, supplyName: true }}
                    >
                        {__('supply')}
                    </div>
                </div>
                <div block={this.className} elem="CalculatorResult">
                    {__('You need %s ', area)}m<sup>2</sup>
                </div>
            </div>
        );
    }

    renderCalculatorTiles() {
        const {
            quantity,
            product: { vp_items },
            productPrice: {
                price: {
                    finalPrice: { value, currency },
                },
            },
        } = this.props;

        if (!vp_items) {
            return null;
        }

        const currentQuantity = multiplyValues(vp_items, quantity);
        const calculatedValue = multiplyValues(value, currentQuantity);
        const calculatedValueFormatted = formatPrice(calculatedValue, currency);
        const area = multiplyValues(vp_items, quantity);

        return (
            <div block={this.className} elem="CalculatorWrapper">
                <div block={this.className} elem="Calculator">
                    <div block={this.className} elem="CalculatorBox" mods={{ value: true, area: true }}>
                        {area}
                    </div>
                    <div block={this.className} elem="CalculatorBox" mods={{ value: true, field: true, changer: true }}>
                        {this.renderQuantityChanger()}
                    </div>
                    <div block={this.className} elem="CalculatorBox" mods={{ value: true, calculated: true }}>
                        {calculatedValueFormatted}
                    </div>
                    <div block={this.className} elem="CalculatorBox" mods={{ label: true, meters: true }}>
                        <span>
                            m<sup>2</sup>
                        </span>
                    </div>
                    <div
                        block={this.className}
                        elem="CalculatorBox"
                        mods={{ label: true, field: true, quantity: true }}
                    >
                        {quantity <= 1 ? __('Package') : quantity >= 5 ? __('Boxes') : __('Packages')}
                    </div>
                    <div block={this.className} elem="CalculatorBox" mods={{ label: true, finalValue: true }}>
                        {__('Value')}
                    </div>
                </div>
            </div>
        );
    }

    renderQuantityChanger() {
        const {
            quantity,
            minQuantity,
            maxQuantity,
            setQuantity,
            inStock,
            product: { type_id },
        } = this.props;

        if (type_id === PRODUCT_TYPE.grouped || type_id === PRODUCT_TYPE.built) {
            return null;
        }

        return (
            <Field
                type={FIELD_TYPE.number}
                attr={{
                    id: 'item_qty',
                    name: 'item_qty',
                    defaultValue: quantity,
                    max: maxQuantity,
                    min: minQuantity,
                }}
                validationRule={{
                    inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric,
                    isRequired: true,
                    range: {
                        min: minQuantity,
                        max: maxQuantity,
                    },
                }}
                isDisabled={!inStock}
                mix={{ block: this.className, elem: 'Qty' }}
                events={{ onChange: setQuantity }}
                validateOn={['onChange']}
            />
        );
    }
}

export default Product;
