/* eslint-disable indent */
import React from 'react';
import { usePopper } from 'react-popper';

/** @namespace Pwa/Util/Popper/Hoc/withPopper */
export const withPopper =
    (Component, options = {}) =>
    (props) => {
        const [referenceElement, setReferenceElement] = React.useState(null);
        const [popperElement, setPopperElement] = React.useState(null);
        const [arrowElement, setArrowElement] = React.useState(null);

        const popper = usePopper(referenceElement, popperElement, {
            ...options,
            modifiers: [
                ...(options?.modifiers ?? []),
                {
                    name: 'arrow',
                    options: {
                        element: arrowElement,
                    },
                },
            ],
        });

        return (
            <Component
                {...props}
                popper={popper}
                referenceElement={referenceElement}
                setArrowElement={setArrowElement}
                setReferenceElement={setReferenceElement}
                setPopperElement={setPopperElement}
            />
        );
    };

export default withPopper;
