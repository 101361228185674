import { Fragment } from 'react';

import ArrangementProductCard from 'Component/ArrangementProductCard';
import ProductCard from 'Component/ProductCard';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';

import PromotionalBanner from '../component/PromotionalBanner';
import {
    PRODUCTS_IN_ROW_DESKTOP,
    PRODUCTS_IN_ROW_MOBILE,
    PRODUCTS_IN_ROW_TABLET,
} from '../component/PromotionalBanner/PromotionalBanner.config';

export class ProductListPageComponentPlugin {
    renderPageItemsBlock(args, callback, instance) {
        const {
            items,
            selectedFilters,
            mix: { mods: { layout = GRID_LAYOUT } = {} },
            promotionalBanners,
            device: { isTablet, isMobile },
            pageNumber,
            isArrangementCategory,
        } = instance.props;

        // eslint-disable-next-line no-nested-ternary
        const products_in_row = isTablet
            ? PRODUCTS_IN_ROW_TABLET
            : isMobile
            ? PRODUCTS_IN_ROW_MOBILE
            : PRODUCTS_IN_ROW_DESKTOP;

        const tileBanners = promotionalBanners.filter((banner) => banner.type === 'tile').sort((a, b) => a.row - b.row);
        const wideBanners = promotionalBanners.filter((banner) => banner.type === 'wide');
        const pageSize = items.length;

        // eslint-disable-next-line
        tileBanners.map((item) => {
            if (
                !items.find((element) => element.banner_id === item.banner_id) &&
                !(isMobile && item.variant === 'image')
            ) {
                // checking if the item is on the correct page
                if (item.row > pageSize * (pageNumber - 1) && item.row < pageSize * pageNumber) {
                    items.splice(item.row - pageSize * (pageNumber - 1) - 1, 0, item);
                }
            }
        });

        // eslint-disable-next-line
        wideBanners.map((item) => {
            if (
                !items.find((element) => element.banner_id === item.banner_id) &&
                !(isMobile && item.variant === 'image')
            ) {
                // checking if the item is on the correct page
                if (
                    item.row * products_in_row > pageSize * (pageNumber - 1) &&
                    item.row * products_in_row < pageSize * pageNumber
                ) {
                    items.splice((item.row - 1) * products_in_row - pageSize * (pageNumber - 1), 0, item);
                }
            }
        });

        if (isArrangementCategory) {
            return items.map((product, i) => (
                <ArrangementProductCard
                    product={product}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    selectedFilters={selectedFilters}
                    layout={layout}
                    isArrangementCategory={isArrangementCategory}
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                    {...instance.containerProps()}
                />
            ));
        }

        return items.map((product, i) => (
            /* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={`product${i}`}>
                {product.banner_id ? (
                    <PromotionalBanner banner={product} as="li" />
                ) : (
                    <ProductCard
                        product={product}
                        selectedFilters={selectedFilters}
                        layout={layout}
                        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                        {...instance.containerProps()}
                    />
                )}
            </Fragment>
        ));
    }
}

export const { renderPageItemsBlock } = new ProductListPageComponentPlugin();

export default {
    'Component/ProductListPage/Component': {
        'member-function': {
            renderPageItemsBlock,
        },
    },
};
