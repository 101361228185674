import SliderNextArrow from '@domneo/slider/src/components/SliderNextArrow';
import SliderPrevArrow from '@domneo/slider/src/components/SliderPrevArrow';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import Loader from 'Component/Loader';
import ProductCard from 'Component/ProductCard';
import { ProductType } from 'Type/ProductList.type';

import { DEFAULT_CATALOG_WIDGET_SLICK_SETTINGS } from './CatalogWidget.config';

import './CatalogWidget.style';

/** @namespace Domneo/CatalogWidget/Components/CatalogWidget/Component/CatalogWidgetComponent */
export class CatalogWidgetComponent extends PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool,
        items: PropTypes.arrayOf(ProductType),
        settings: PropTypes.object,
    };

    static defaultProps = {
        settings: {},
    };

    renderProductCard(item, i) {
        return (
            <div block="CatalogWidget" elem="Item" key={i}>
                <ProductCard product={item} sliderMode />
            </div>
        );
    }

    render() {
        const { isLoading, items, settings } = this.props;

        if (isLoading) {
            return (
                <div block="CatalogWidget" elem="Loader">
                    <Loader />
                </div>
            );
        }

        if (!items || !items.length) {
            return (
                <div block="CatalogWidget" elem="NoProducts">
                    {__('No products')}
                </div>
            );
        }

        return (
            <div block="CatalogWidget">
                <SlickSlider
                    prevArrow={<SliderPrevArrow />}
                    nextArrow={<SliderNextArrow />}
                    {...DEFAULT_CATALOG_WIDGET_SLICK_SETTINGS}
                    {...settings}
                >
                    {items.map(this.renderProductCard)}
                </SlickSlider>
            </div>
        );
    }
}

export default CatalogWidgetComponent;
