import { DEFAULT_ERROR_MESSAGE } from 'SourceUtil/Request/Error';

export * from 'SourceUtil/Request/Error';

export const INTERNAL_SERVER_ERROR = 'Internal Server Error';

export const GRAPHQL_AUTHORIZATION = 'graphql-authorization';

/** @namespace Pwa/Util/Request/Error/getErrorMessage */
export const getErrorMessage = (error, defaultMessage = DEFAULT_ERROR_MESSAGE) => {
    const { message = defaultMessage } = error?.length ? error[0] : error || {};
    return message === INTERNAL_SERVER_ERROR ? __('Internal Server Error') : message;
};

/** @namespace Pwa/Util/Request/Error/getErrorCategory */
export const getErrorCategory = (error) => {
    const { extensions } = error?.length ? error[0] : error || {};
    const { category } = extensions || {};

    return category;
};
