import ExpandableContentShowMore from 'Component/ExpandableContentShowMore';
import { ProductConfigurableAttributes as SourceProductConfigurableAttributes } from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

import './ProductConfigurableAttributes.override.style';

/** @namespace Pwa/Component/ProductConfigurableAttributes/Component/ProductConfigurableAttributes */
export class ProductConfigurableAttributes extends SourceProductConfigurableAttributes {
    renderSwatch(option) {
        const { attribute_code, attribute_options, facet_max_size } = option;

        return (
            <div block="ProductConfigurableAttributes" elem="SwatchList" key={attribute_code}>
                <ExpandableContentShowMore showElemCount={facet_max_size || undefined}>
                    {Object.entries(attribute_options).map((attribute_option) =>
                        this.renderConfigurableAttributeValue({
                            ...option,
                            attribute_value: attribute_option[1].value_string,
                            attribute_label: attribute_option[1].label,
                        })
                    )}
                </ExpandableContentShowMore>
            </div>
        );
    }
}

export default ProductConfigurableAttributes;
