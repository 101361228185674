import { cloneElement } from 'react';

import { EVENT_GTM_BEGIN_CHECKOUT } from '../component/GoogleTagManager/GoogleTagManager.events';
import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
    };
};

const renderDetailsStep = (args, callback, instance) => {
    const { event } = instance.props;

    return cloneElement(callback.apply(instance, args), { event });
};

const renderProcessingPaymentStep = (args, callback, instance) => {
    const { event } = instance.props;

    return cloneElement(callback.apply(instance, args), { event });
};

const containerProps = (args, callback, instance) => {
    const { event } = instance.props;

    return {
        ...callback(...args),
        event,
    };
};

const componentDidMount = (args, callback, instance) => {
    const { event } = instance.props;

    event(EVENT_GTM_BEGIN_CHECKOUT);

    callback.apply(instance, args);
};

export default {
    'Route/Checkout/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
    'Route/Checkout/Container': {
        'member-function': {
            containerProps,
            componentDidMount,
        },
    },
    'Route/Checkout/Component': {
        'member-function': {
            renderDetailsStep,
            renderProcessingPaymentStep,
        },
    },
};
