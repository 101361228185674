import Icons from 'Component/Icons';
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import { SearchItem as SourceSearchItem } from 'SourceComponent/SearchItem/SearchItem.component';

import './SearchItem.override.style';

/** @namespace Pwa/Component/SearchItem/Component/SearchItem */
export class SearchItem extends SourceSearchItem {
    renderSearchItemArrow() {
        return (
            <figcaption block="SearchItem" elem="Arrow">
                <Icons name="arrow_right" />
            </figcaption>
        );
    }

    renderLink() {
        const {
            linkTo: { pathname = '' },
            onClick,
            imgSrc,
            isCategories,
        } = this.props;

        return (
            <Link block="SearchItem" elem="Link" to={pathname} onClick={onClick}>
                <figure block="SearchItem" elem="Wrapper" mods={{ isImage: !!imgSrc }}>
                    {!!imgSrc && this.renderImage()}
                    {this.renderContent()}
                    {isCategories && this.renderSearchItemArrow()}
                </figure>
            </Link>
        );
    }

    renderContent() {
        const {
            product: { name, type_id, listing_name },
        } = this.props;

        const searchItemName = type_id === 'built' && !!listing_name ? listing_name : name;

        return (
            <figcaption block="SearchItem" elem="Content">
                {this.renderCustomAttribute()}
                <h4 block="SearchItem" elem="Title" mods={{ isLoaded: !!name }}>
                    <TextPlaceholder content={searchItemName} length="short" />
                </h4>
            </figcaption>
        );
    }
}

export default SearchItem;
