/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const UPDATE_POSTS = 'UPDATE_POSTS';
export const CLEAR_POSTS = 'CLEAR_POSTS';
export const NO_POSTS = 'NO_POSTS';

/**
 * Update posts
 * @param posts
 * @returns {{type: *, posts: *}}
 * @namespace Domneo/Blog/Store/Posts/Action/updatePosts */
export const updatePosts = (posts) => ({
    type: UPDATE_POSTS,
    posts,
    isLoaded: true,
});

/**
 * Update posts
 * @param posts
 * @returns {{type: *, posts: *}}
 * @namespace Domneo/Blog/Store/Posts/Action/clearPosts */
export const clearPosts = () => ({
    type: CLEAR_POSTS,
    isLoaded: false,
});

/**
 * @param message
 * @returns {{type: *, posts: *}}
 * @namespace Domneo/Blog/Store/Posts/Action/postsException */
export const postsException = (message) => ({
    type: NO_POSTS,
    message,
});
