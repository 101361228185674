import { Field } from 'Util/Query';

/** @namespace Domneo/Promotionalbanner/Query/PromotionalBanner/Query/PromotionalBannerQuery */
export class PromotionalBannerQuery {
    getPromotionalBannersQuery(category_id) {
        return new Field('banners').addArgument('category_id', 'Int!', category_id).addField(this._getItemsField());
    }

    _getItemsField() {
        return new Field('items').addFieldList(this._getItemsFieldList());
    }

    _getItemsFieldList() {
        return [
            'banner_id',
            'text',
            'row',
            'text_color',
            'background_color',
            'background_url',
            'background_url_tab',
            'button_url',
            'button_url_tab',
            'button_text_color',
            'button_border_color',
            'active_from',
            'active_to',
            'creation_time',
            'update_time',
            'type',
            'variant',
            'image',
        ];
    }
}

export default new PromotionalBannerQuery();
