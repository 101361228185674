import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import Link from 'Component/Link';
import media from 'Util/Media';

import Countdown from '../Countdown';
import SliderNextArrow from '../SliderNextArrow';
import SliderPrevArrow from '../SliderPrevArrow';
import { DEFAULT_SLICK_SETTINGS } from './Slider.config';

import '../../style/main';
import './Slider.style';

/** @namespace Domneo/Slider/Components/Slider/Component/SliderComponent */
export class SliderComponent extends PureComponent {
    static propTypes = {
        slider: PropTypes.object.isRequired,
        settings: PropTypes.object,
    };

    static defaultProps = {
        settings: {},
    };

    renderImage(slide) {
        const { header, image, tablet_image: tabletImage, mobile_image: mobileImage } = slide;

        if (!image) {
            return null;
        }

        return (
            <picture block="Slider" elem="Image">
                {mobileImage ? <source media="(max-width: 767px)" srcSet={media(mobileImage)} /> : null}
                {tabletImage ? <source media="(max-width: 1023px)" srcSet={media(tabletImage)} /> : null}
                <img src={media(image)} alt={header} />
            </picture>
        );
    }

    renderContent(slide) {
        const {
            body,
            header,
            cta_text: ctaText,
            cta_link: ctaLink,
            cta_color: ctaColor,
            counter_date: counterDate,
            counter_enable: counterEnable,
        } = slide;

        if (!body && !ctaText && !counterEnable && !header) {
            return null;
        }

        return (
            <div block="Slider" elem="Content">
                {!!counterEnable && !!counterDate && <Countdown date={counterDate} />}
                {body ? <h5>{body}</h5> : null}
                {header ? <h2>{header}</h2> : null}
                {ctaText ? (
                    <Link
                        block="Slider"
                        elem="Button"
                        mix={{ block: 'Button' }}
                        style={{ backgroundColor: ctaColor, borderColor: ctaColor }}
                        to={ctaLink}
                    >
                        {ctaText}
                    </Link>
                ) : null}
            </div>
        );
    }

    renderSlide(slide) {
        const {
            body,
            header,
            image,
            tablet_image: tabletImage,
            mobile_image: mobileImage,
            cta_text: ctaText,
            cta_link: ctaLink,
            cta_color: ctaColor,
            counter_date: counterDate,
            counter_enable: counterEnable,
        } = slide;

        return (
            <>
                {this.renderImage(slide)}
                {this.renderContent(slide)}
            </>
        );
    }

    renderSliderSlides(slider) {
        return slider.slides.map((slide, index) => <div key={`slider${index}`}>{this.renderSlide(slide)}</div>);
    }

    renderCustomPaging(i) {
        const { slider } = this.props;
        const slide = slider.slides[i];

        return (
            <div block="Slider" elem="Page">
                {slide.name || slide.header || `${__('slide')}${i + 1}`}
            </div>
        );
    }

    render() {
        const { slider, settings } = this.props;

        if (!slider || !slider?.slides) {
            return null;
        }

        return (
            <div block="Slider">
                <SlickSlider
                    customPaging={this.renderCustomPaging.bind(this)}
                    prevArrow={<SliderPrevArrow />}
                    nextArrow={<SliderNextArrow />}
                    {...DEFAULT_SLICK_SETTINGS}
                    {...settings}
                >
                    {this.renderSliderSlides(slider)}
                </SlickSlider>
            </div>
        );
    }
}

export default SliderComponent;
