import capitalize from 'lodash/capitalize';

import {
    CATEGORY_PRODUCT_LIST,
    HOMEPAGE,
    ONE,
    PRODUCT_LIST_WIDGET,
    SEARCH
} from '../component/GoogleTagManager/GoogleTagManager.config';
import { getActionField } from './actionField.data';
import { getCategoriesLevel, getPrice } from './baseProduct.data';
import { getPageType } from './general.data';

/** @namespace Domneo/Gtm/EventData/Impression/Data/getAction */
export const getAction = (list = '', store) => {
    switch (list) {
        case CATEGORY_PRODUCT_LIST:
            return store.CategoryReducer.category.name;
        case PRODUCT_LIST_WIDGET:
            return HOMEPAGE;
        case SEARCH:
            return SEARCH;
        default:
            return getActionField(store, window.location.pathname);
    }
};

/** @namespace Domneo/Gtm/EventData/Impression/Data/getItem */
export const getItem = (item, position, list) => {
    const { sku, name, type_id, price, categories = [] } = item;

    return {
        item_name: name,
        item_id: sku,
        price: getPrice(item, type_id, price),
        item_list_name: list,
        index: position,
        quantity: ONE,
        ...getCategoriesLevel(categories)
    };
};

/** @namespace Domneo/Gtm/EventData/Impression/Data/getImpressionsData */
export const getImpressionsData = (data = [], store) => {
    const { products } = data;
    const impressions = [];

    products.map((product, i) => impressions.push(getItem(product, i + 1, capitalize(getPageType(store).pageType))));

    return impressions;
};
