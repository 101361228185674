import { PureComponent } from 'react';

import './SliderNextArrow.style.scss';

/** @namespace Domneo/Slider/Components/SliderNextArrow/Component/SliderNextArrowComponent */
export class SliderNextArrowComponent extends PureComponent {
    render() {
        const { onClick, className } = this.props;
        const isDisabled = className.search('disabled') !== -1;

        return (
            <div block="SliderNextArrow" mods={{ disabled: isDisabled }} onClick={onClick}>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                    clipRule="evenodd"
                >
                    <path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z" />
                </svg>
            </div>
        );
    }
}

export default SliderNextArrowComponent;
