export const CROSS_SELL_SLIDES_TO_SHOW_MOBILE = 2;
export const CROSS_SELL_SLIDES_TO_SHOW_TABLET = 3;
export const CROSS_SELL_SLIDES_TO_SHOW_DESKTOP = 4;
export const CROSS_SELL_MAX_SLIDES = 20;

export const CROSS_SELL_SLIDER_OPTIONS = {
    dots: false,
    arrows: false,
    autoplay: false,
    adaptiveWidth: true,
    slidesToShow: CROSS_SELL_SLIDES_TO_SHOW_DESKTOP,
    responsive: [
        {
            breakpoint: 1023,
            settings: {
                arrows: true,
                swipeToSlide: true,
                slidesToShow: CROSS_SELL_SLIDES_TO_SHOW_TABLET,
            },
        },
        {
            breakpoint: 767,
            settings: {
                adaptiveWidth: false,
                slidesToShow: CROSS_SELL_SLIDES_TO_SHOW_MOBILE,
            },
        },
    ],
};
