import { FieldForm as SourceFieldForm } from 'SourceComponent/FieldForm/FieldForm.component';

/** @namespace Pwa/Component/FieldForm/Component/FieldForm */
export class FieldForm extends SourceFieldForm {
    renderGusApply() {
        const { fetchGusData, isBilling } = this.props;

        if (!isBilling) {
            return null;
        }

        return (
            <div block="CheckoutBilling" elem="Gus">
                <button block="Button" type="button" onClick={fetchGusData}>
                    {__('Apply company details')}
                </button>
            </div>
        );
    }

    renderFormBody() {
        return (
            <div block="FieldForm" elem="Body">
                <div block="FieldForm" elem="Fields">
                    {this.fieldMap.map(this.renderSection.bind(this))}
                    {this.renderGusApply()}
                </div>
                {this.renderActions()}
            </div>
        );
    }
}

export default FieldForm;
