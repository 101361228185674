import { ProductWishlistButton as SourceProductWishlistButton } from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.component';

import './ProductWishlistButton.override.style';

/** @namespace Pwa/Component/ProductWishlistButton/Component/ProductWishlistButton */
export class ProductWishlistButton extends SourceProductWishlistButton {
    getTitle() {
        const { isInWishlist } = this.props;

        if (isInWishlist) {
            return __('Remove from Wishlist');
        }

        return __('Add to Wishlist');
    }
}

export default ProductWishlistButton;
