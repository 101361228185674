import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer as SourceProductCardContainer,
} from 'Component/ProductCard/ProductCard.container';

import ArrangementProductCard from './ArrangementProductCard.component';

export { mapStateToProps, mapDispatchToProps };

/** @namespace Pwa/Component/ArrangementProductCard/Container/ArrangementProductCardContainer */
export class ArrangementProductCardContainer extends SourceProductCardContainer {
    render() {
        return <ArrangementProductCard {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArrangementProductCardContainer);
