/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
import isEmpty from 'lodash/isEmpty';

import Icons from 'Component/Icons';
import { showPopup } from 'Store/Popup/Popup.action';

import { PRODUCT_CROSS_SELL_POPUP_ID } from '../component/CrossSellPopup/CrossSellPopup.config';

const mapDispatchToProps = (args, callback, instance) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        showPopup: (payload) => dispatch(showPopup(PRODUCT_CROSS_SELL_POPUP_ID, payload)),
    };
};

const addToCart = async (args, callback, instance) => {
    const { product, showPopup } = instance.props;
    await callback.apply(instance, args);
    if (!isEmpty(product.crosssell_products)) {
        showPopup({
            title: (
                <span>
                    <Icons name="tick" width="28" height="28" />
                    {__('Product added to cart!')}
                </span>
            ),
            product,
        });
    }
};

export class ProductContainerPlugin {
    containerProps(args, callback, instance) {
        const { enabledPopup } = instance.state;
        return {
            ...callback.apply(instance, args),
            enabledPopup,
        };
    }
}

const { containerProps } = new ProductContainerPlugin();

export default {
    'Component/Product/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
    'Component/Product/Container': {
        'member-function': {
            containerProps,
            addToCart,
        },
    },
};
