import CatalogWidgetQuery from '@domneo/catalogWidget/src/query/CatalogWidget.query';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX } from 'Component/ProductPrice/ProductPrice.config';
import { fetchQuery } from 'Util/Request';

import CatalogWidget from './CatalogWidget.component';

/** @namespace Domneo/CatalogWidget/Components/CatalogWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    displayTaxInPrice: state.ConfigReducer.priceTaxDisplay?.product_price_display_type,
    productUrlSuffix: state.ConfigReducer.product_url_suffix,
});

/** @namespace Domneo/CatalogWidget/Components/CatalogWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Domneo/CatalogWidget/Components/CatalogWidget/Container/CatalogWidgetContainer */
export class CatalogWidgetContainer extends PureComponent {
    static propTypes = {
        code: PropTypes.string.isRequired,
        displayTaxInPrice: PropTypes.string,
        productUrlSuffix: PropTypes.string,
    };

    static defaultProps = {
        displayTaxInPrice: DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX,
        productUrlSuffix: '',
    };

    state = {
        items: [],
        title: null,
        isLoading: false,
    };

    containerFunctions = {};

    componentDidMount() {
        this.getCatalogWidgetData();
    }

    performDataTransformations(data) {
        const { productUrlSuffix } = this.props;

        return data.map((item) => ({
            ...item,
            id: item.entity_id,
            url: `${item.url_key}${productUrlSuffix}`,
            image: {
                url: item.image,
            },
            small_image: {
                url: item.image,
            },
            variants: !item.variants ? [] : item.variants,
        }));
    }

    getCatalogWidgetData() {
        const { code } = this.props;

        this.setState({ isLoading: true });

        fetchQuery(CatalogWidgetQuery.getCatalogWidgetQuery(code))
            .then(
                /** @namespace Domneo/CatalogWidget/Components/CatalogWidget/Container/fetchQuery/then */
                ({ catalogWidget: { items, title } }) => {
                    this.setState({
                        items: this.performDataTransformations(items),
                        title,
                    });
                },
                /** @namespace Domneo/CatalogWidget/Components/CatalogWidget/Container/fetchQuery/then */
                () => {
                    this.setState({
                        items: [],
                        title: null,
                    });
                }
            )
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    containerProps = () => {
        const { items, title, isLoading } = this.state;

        return {
            items,
            title,
            isLoading,
        };
    };

    render() {
        return <CatalogWidget {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogWidgetContainer);
