import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';

import { ANNOUNCEMENT_LOADING_TIME, ANNOUNCEMENT_MIN_HEIGHT } from './Announcement.config';

import './Announcement.style';

/** @namespace Domneo/Announcement/Component/Announcement/Component/AnnouncementComponent */
export class AnnouncementComponent extends PureComponent {
    static propTypes = {
        announcement: PropTypes.shape({
            background_color: PropTypes.string,
            can_close: PropTypes.bool,
            content: PropTypes.string,
            display_from: PropTypes.string,
            display_to: PropTypes.string,
            places: PropTypes.string,
            text_color: PropTypes.string,
        }),
        hideAnnouncementNotice: PropTypes.func,
    };

    static defaultProps = {
        announcement: {
            background_color: '',
            can_close: true,
            content: '',
            display_from: '',
            display_to: '',
            places: '',
            text_color: '',
        },
        hideAnnouncementNotice: () => {},
    };

    componentDidMount() {
        this.setAnnouncementHeightToOffsetHeight();
    }

    recalculateAnnouncementHeight() {
        const announcementNotice = document.getElementById('AnnouncementNotice');

        setTimeout(() => {
            document.documentElement.style.setProperty('--demo-notice-height', `${announcementNotice?.offsetHeight}px`);
        }, ANNOUNCEMENT_LOADING_TIME);
    }

    setAnnouncementHeightToOffsetHeight() {
        const announcementNotice = document.getElementById('AnnouncementNotice');
        announcementNotice?.style.setProperty('--demo-notice-height', `${announcementNotice?.offsetHeight}px`);

        if (announcementNotice?.offsetHeight > 0) {
            document.documentElement.style.setProperty('--demo-notice-height', `${announcementNotice?.offsetHeight}px`);
            return;
        }
        document.documentElement.style.setProperty('--demo-notice-height', `${ANNOUNCEMENT_MIN_HEIGHT}px`);
        this.recalculateAnnouncementHeight();
    }

    renderContent() {
        const { announcement } = this.props;
        const { background_color, content } = announcement || {};

        if (!content) {
            return null;
        }

        return (
            <div id="AnnouncementNotice" block="Announcement" style={{ backgroundColor: background_color }}>
                {this.renderHtmlContent()}
                {this.renderHideNotice()}
            </div>
        );
    }

    renderHtmlContent() {
        const { announcement } = this.props;
        const { content, text_color } = announcement || {};

        return (
            <div
                block="Announcement"
                elem="Content"
                dangerouslySetInnerHTML={{ __html: content }}
                style={{ color: text_color }}
            />
        );
    }

    renderHideNotice() {
        const { announcement, hideAnnouncementNotice } = this.props;
        const { can_close } = announcement || {};

        if (!can_close) {
            return null;
        }

        return (
            <div block="Announcement" elem="CloseIcon" onClick={hideAnnouncementNotice}>
                <CloseIcon />
            </div>
        );
    }

    render() {
        return <>{this.renderContent()}</>;
    }
}

export default AnnouncementComponent;
