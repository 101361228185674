import { PRODUCT_CROSS_SELL_POPUP_ID } from '../component/CrossSellPopup/CrossSellPopup.config';

const mapStateToProps = (args, callback, instance) => {
    const [state] = args;
    return {
        ...callback(...args),
        popupProduct: state.PopupReducer.popupPayload[PRODUCT_CROSS_SELL_POPUP_ID]?.product,
    };
};

const containerProps = (args, callback, instance) => {
    const { popupProduct } = instance.props;
    return {
        ...callback.apply(instance, args),
        popupProduct,
    };
};

export default {
    'Route/CategoryPage/Container/mapStateToProps': {
        function: mapStateToProps,
    },
    'Route/CategoryPage/Container': {
        'member-function': {
            containerProps,
        },
    },
};
