import {
    ACCOUNT_FORGOT_PASSWORD,
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    BREADCRUMBS,
    CART,
    CHANGE_PASSWORD,
    CHECKOUT,
    CMS_PAGE,
    COMPARE,
    CONFIRM_ACCOUNT,
    CONTACT_PAGE,
    COOKIE_POPUP,
    CREATE_ACCOUNT,
    DEMO_NOTICE,
    FOOTER,
    FORGOT_PASSWORD,
    HEADER,
    HOME,
    LOGIN,
    MENU,
    MY_ACCOUNT,
    MY_ACCOUNT_ADDRESS,
    MY_ACCOUNT_DOWNLOADABLE,
    MY_ACCOUNT_NEWSLETTER,
    MY_ACCOUNT_ORDER,
    MY_ACCOUNT_ORDERS,
    MY_ACCOUNT_WISHLIST,
    NAVIGATION_TABS,
    NEW_VERSION_POPUP,
    NOTIFICATION_LIST,
    SEARCH,
    SHARED_WISHLIST,
    STYLE_GUIDE,
    SWITCH_ITEMS_TYPE,
    URL_REWRITES,
} from 'SourceComponent/Router/Router.config';

export {
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    SWITCH_ITEMS_TYPE,
    NOTIFICATION_LIST,
    NAVIGATION_TABS,
    DEMO_NOTICE,
    HEADER,
    BREADCRUMBS,
    NEW_VERSION_POPUP,
    HOME,
    SEARCH,
    CMS_PAGE,
    CART,
    CHECKOUT,
    CHANGE_PASSWORD,
    CREATE_ACCOUNT,
    LOGIN,
    ACCOUNT_FORGOT_PASSWORD,
    FORGOT_PASSWORD,
    CONFIRM_ACCOUNT,
    MY_ACCOUNT,
    MY_ACCOUNT_ORDER,
    MY_ACCOUNT_ORDERS,
    MY_ACCOUNT_DOWNLOADABLE,
    MY_ACCOUNT_WISHLIST,
    MY_ACCOUNT_ADDRESS,
    MY_ACCOUNT_NEWSLETTER,
    MENU,
    SHARED_WISHLIST,
    CONTACT_PAGE,
    COMPARE,
    STYLE_GUIDE,
    URL_REWRITES,
    FOOTER,
    COOKIE_POPUP,
};

export const CART_PREVIEW = 'CART_PREVIEW';
export const CMS_PREVIEW = 'CMS_PREVIEW';
export const MY_ACCOUNT_RMA_ORDER_LIST = 'MY_ACCOUNT_RMA_ORDER_LIST';
export const MY_ACCOUNT_RMA_STATUS_LIST = 'MY_ACCOUNT_RMA_STATUS_LIST';
export const SEARCHING_PAGE = 'SEARCHING_PAGE';
export const THANK_YOU_PAGE = 'THANK_YOU_PAGE';
export const GDPR_POPUP = 'GDPR_POPUP';
export const HEADING_BANNER = 'HEADING_BANNER';
export const MY_ACCOUNT_GDPR = 'MY_ACCOUNT_GDPR';
export const NEWSLETTER_SUBSCRIPTION_PAGE = 'NEWSLETTER_SUBSCRIPTION_PAGE';
export const SOCIAL_PROOF_LIST = 'SOCIAL_PROOF_LIST';
export const CONFIRM_POPUP = 'CONFIRM_POPUP';
