import { AnnouncementReducer } from '../store/Announcement/Announcement.reducer';

export class AnnouncementReducerPlugin {
    getStaticReducers = (args, callback, instance) => ({
        ...callback.apply(instance, args),
        AnnouncementReducer
    });
}

const { getStaticReducers } = new AnnouncementReducerPlugin();

export const config = {
    'Store/Index/getStaticReducers': {
        function: getStaticReducers
    }
};

export default config;
