import Consents from 'Component/Consents';
import { CONSENTS_CONTACT } from 'Component/Consents/Consents.config';
import { ContactForm as SourceContactForm } from 'SourceComponent/ContactForm/ContactForm.component';
import browserHistory from 'Util/History';

import { CONTACT_PAGE_URL } from './ContactForm.config';
import contactForm from './ContactForm.form';

import './ContactForm.override.style';

/** @namespace Pwa/Component/ContactForm/Component/ContactForm */
export class ContactForm extends SourceContactForm {
    static defaultProps = {
        isLoading: false,
    };

    get fieldMap() {
        return contactForm();
    }

    renderAgreements() {
        return <Consents type={CONSENTS_CONTACT} />;
    }

    renderFormBody() {
        return (
            <div block="FieldForm" elem="Body">
                <div block="FieldForm" elem="Fields">
                    {this.fieldMap.map(this.renderSection.bind(this))}
                </div>
                {this.renderAgreements()}
                {this.renderActions()}
            </div>
        );
    }

    render() {
        const {
            location: { pathname },
        } = browserHistory;

        const isContactPage = pathname.includes(CONTACT_PAGE_URL);

        return (
            <div block="ContactForm" mods={{ isContactPage }}>
                {super.render()}
            </div>
        );
    }
}

export default ContactForm;
