import { PromotionalBannerComponent as SourcePromotionalBanner } from '@domneo/promotionalbanner/src/component/PromotionalBanner/PromotionalBanner.component';
import { IMAGE_WITH_TEXT } from '@domneo/promotionalbanner/src/component/PromotionalBanner/PromotionalBanner.config';

import {
    DEFAULT_DESKTOP_WINDOW_WIDTH,
    INITIAL_PROMO_BANNER_DESKTOP_HEIGHT,
    INITIAL_PROMO_BANNER_DESKTOP_WIDTH,
    PROMO_BANNER_TITLE,
} from 'Component/Image/Image.config';
import Link from 'Component/Link';
import { DeviceType } from 'Type/Device';

import './PromotionalBanner.override.style';

/** @namespace Pwa/@Domneo/Promotionalbanner/Component/PromotionalBanner/Component/PromotionalBanner */
export class PromotionalBanner extends SourcePromotionalBanner {
    static propTypes = {
        ...SourcePromotionalBanner.propTypes,
        device: DeviceType.isRequired,
    };

    calculateWindowWidth() {
        const windowWidth = window.innerWidth;
        const calculatedWidth = windowWidth > DEFAULT_DESKTOP_WINDOW_WIDTH ? DEFAULT_DESKTOP_WINDOW_WIDTH : windowWidth;

        return calculatedWidth;
    }

    calculateBannerDimensions() {
        const currentWindowWidth = this.calculateWindowWidth();
        const defaultWindowWidth = DEFAULT_DESKTOP_WINDOW_WIDTH;
        const initialBannerHeight = INITIAL_PROMO_BANNER_DESKTOP_HEIGHT;
        const initialBannerWidth = INITIAL_PROMO_BANNER_DESKTOP_WIDTH;

        const height = Math.round((initialBannerHeight * currentWindowWidth) / defaultWindowWidth);
        const width = Math.round((initialBannerWidth * currentWindowWidth) / defaultWindowWidth);

        const headerBannerDimensions = {
            height,
            width,
        };

        return headerBannerDimensions;
    }

    renderBannerContent(banner) {
        const { text, text_color, button_url, image = null, variant, type } = banner;
        const { device: { isMobile = false } = {} } = this.props;
        const isImageWithTextVariant = variant === IMAGE_WITH_TEXT;
        const isHeaderDesktopBanner = type === PROMO_BANNER_TITLE && !isMobile;
        const style = isHeaderDesktopBanner ? this.calculateBannerDimensions() : {};

        return (
            <>
                {isImageWithTextVariant && (
                    <span block="PromotionalBanner" elem="Text" style={{ color: text_color }}>
                        {text}
                    </span>
                )}
                <div>
                    {image && <img src={image} alt={text || ''} style={style} />}
                    {button_url && this.renderButton(banner)}
                </div>
            </>
        );
    }

    renderButton(banner) {
        const { button_text_color, button_border_color, button_url, button_url_tab } = banner;

        return (
            <Link
                to={button_url || '#'}
                block="PromotionalBanner"
                isOpenInNewTab={button_url_tab}
                elem="Button"
                mix={{ block: 'Button', mods: { isHollow: true } }}
                style={{
                    color: button_text_color,
                    borderColor: button_border_color,
                }}
            >
                {__('Buy now')}
            </Link>
        );
    }

    renderBanner(banner) {
        const { gridColumn } = this.props;

        if (!banner) {
            return null;
        }

        const { background_url, background_color, type, background_url_tab, variant, text } = banner;
        const wideStyles = type === 'wide' ? gridColumn : '';

        return background_url ? (
            <Link
                to={background_url}
                block="PromotionalBanner"
                mods={{ variant }}
                aria-label={text || __('Ad banner')}
                isOpenInNewTab={background_url_tab}
                mix={{ block: type }}
                style={{
                    gridColumn: wideStyles,
                    backgroundColor: background_color,
                }}
            >
                {this.renderBannerContent(banner)}
            </Link>
        ) : (
            <div
                block="PromotionalBanner"
                mods={{ variant }}
                mix={{ block: type }}
                style={{
                    gridColumn: wideStyles,
                    backgroundColor: background_color,
                }}
            >
                {this.renderBannerContent(banner)}
            </div>
        );
    }
}

export default PromotionalBanner;
