import { ROOT, SEARCH } from '../component/GoogleTagManager/GoogleTagManager.config';
import {
    EVENT_GTM_IMPRESSIONS_PLP,
    EVENT_GTM_IMPRESSIONS_SEARCH,
} from '../component/GoogleTagManager/GoogleTagManager.events';

const state = (original) => ({
    ...original,
    lastSearch: '',
});

const renderItems = (args, callback, instance) => {
    const {
        items,
        event,
        location: { pathname },
        isLoading,
    } = instance.props;

    const { fired = false } = instance.state;

    const isSearch = pathname.includes(SEARCH);

    if (isSearch && !fired && !isLoading) {
        instance.setState({ fired: true });
        const search = pathname.split(ROOT).pop();

        event(EVENT_GTM_IMPRESSIONS_SEARCH, {
            products: items,
            list: SEARCH,
            search,
        });
    }

    return callback.apply(instance, args);
};

const renderPageItems = (args, callback, instance) => {
    const {
        items,
        event,
        location: { pathname, search }
    } = instance.props;
    const { fired = false } = instance.state;

    const isSearch = pathname.includes(SEARCH);

    if (!isSearch && event) {
        if (!(Object.keys(items).length === 0) && !fired) {
            instance.setState({ fired: true });
            event(EVENT_GTM_IMPRESSIONS_PLP, {
                products: items,
                pathname,
                search
            });
        }
    }

    return callback.apply(instance, args);
};

const renderPageItemsBlock = (args, callback, instance) => {
    const {
        items,
        event,
        location: { pathname, search }
    } = instance.props;
    const { fired = false } = instance.state;

    const isSearch = pathname.includes(SEARCH);

    if (!isSearch && event) {
        if (!(Object.keys(items).length === 0) && !fired) {
            instance.setState({ fired: true });
            event(EVENT_GTM_IMPRESSIONS_PLP, {
                products: items,
                pathname,
                search
            });
        }
    }

    return callback.apply(instance, args);
};

export default {
    'Pwa/Component/ProductListPage/Component/ProductListPage': {
        'member-function': {
            renderPageItemsBlock,
            renderPageItems,
            renderItems,
        },
        'member-property': {
            state,
        },
    },
};
