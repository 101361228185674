/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { BILLING_STEP } from 'Route/Checkout/Checkout.config';
import { formatPrice } from 'Util/Price';

import { extraFeeType } from '../../type/ExtraFee';

/** @namespace Domneo/Extrafee/Component/ExtraFee/Component/ExtraFeeComponent */
export class ExtraFeeComponent extends PureComponent {
    static propTypes = {
        isEmptyCart: PropTypes.bool,
        extraFee: extraFeeType.isRequired,
        currency: PropTypes.string.isRequired,
        checkoutStep: PropTypes.string.isRequired
    };

    static defaultProps = {
        isEmptyCart: false
    };

    renderFeeForCheckout(title, extraFee, description) {
        const { currency } = this.props;

        const isAppendedContent = !!description;

        return (
            <li block="CheckoutOrderSummary" elem="SummaryItem" mods={ { withAppendedContent: isAppendedContent } }>
                <strong block="CheckoutOrderSummary" elem="Text">
                    { `${title}:` }
                </strong>
                <strong block="CheckoutOrderSummary" elem="Text">
                    { formatPrice(extraFee, currency) }
                </strong>
                { isAppendedContent && (
                    <div block="CheckoutOrderSummary" elem="AppendedContent">
                        { description }
                    </div>
                ) }
            </li>
        );
    }

    renderFeeForCart(title, extraFee) {
        const { currency } = this.props;

        return (
            <>
                <dt>{ `${title}:` }</dt>
                <dd>{ formatPrice(extraFee, currency) }</dd>
            </>
        );
    }

    render() {
        const {
            extraFee: {
 isEnabled, isTaxEnabled, displaySuffix, title, description, extraFee, extraFeeTax 
} = {},
            checkoutStep,
            isEmptyCart
        } = this.props;

        if (!isEnabled || extraFee === 0 || isEmptyCart) {
            return null;
        }

        const extraFeeWithTax = extraFee + extraFeeTax;
        const isCheckout = checkoutStep === BILLING_STEP;
        const isCart = checkoutStep === '';

        if (displaySuffix && isTaxEnabled) {
            if (isCheckout) {
                return (
                    <>
                        { this.renderFeeForCheckout(__(`${title} Excl. tax`), extraFee, description) }
                        { this.renderFeeForCheckout(__(`${title} Incl. tax`), extraFeeWithTax, description) }
                    </>
                );
            }
            if (isCart) {
                return (
                    <>
                        { this.renderFeeForCart(__(`${title} Excl. tax`), extraFee) }
                        { this.renderFeeForCart(__(`${title} Incl. tax`), extraFeeWithTax) }
                    </>
                );
            }
        }

        if (isCheckout) {
            return <>{ this.renderFeeForCheckout(title, isTaxEnabled ? extraFeeWithTax : extraFee, description) }</>;
        }

        if (isCart) {
            return <>{ this.renderFeeForCart(title, isTaxEnabled ? extraFeeWithTax : extraFee, description) }</>;
        }

        return null;
    }
}

export default ExtraFeeComponent;
