import PropTypes from 'prop-types';

import { ItemShape as SourceItemShape } from 'SourceType/ProductList.type';

export * from 'SourceType/ProductList.type';

export const ItemShape = {
    ...SourceItemShape,
    alternative_hover_image: PropTypes.string,
};
