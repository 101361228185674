export const aroundGetProductInterfaceFields = (args, callback, instance) => {
    const fields = callback.apply(instance, args);

    fields.push(instance._getCategoriesField(), instance._getPriceRangeField());

    return fields;
};

export default {
    'Query/ProductList/Query': {
        'member-function': {
            _getProductInterfaceFields: aroundGetProductInterfaceFields,
        },
    },
};
