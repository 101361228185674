Mosaic.setPlugins([require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/CartItemChangeQuantity.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/CartPageContainer.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/CheckoutBillingContainer.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/CheckoutProcessingPaymentComponent.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/CheckoutPurchasePrepare.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/CheckoutShippingContainer.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/CheckoutSuccessComponent.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/HandleCustomerDataOnInit.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/MyAccountQuery.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/NoMatch.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/ProductClick.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/ProductList.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/ProductListPageComponent.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/ProductListQuery.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/ProductPageContainer.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/RouterComponentPlugin.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/RouterContainerMDTP.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/SearchFieldComponent.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/SearchItemContainer.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/SearchOverlayComponent.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/StoreReducer.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/WishlistItemComponent.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/addToCart.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/myAccountLoginRegister.plugin.js'),
require('./../packages/@domneo/@scandipwa/gtm/src/plugin/wishlistItemAddToCart.plugin.js'),
require('./../packages/@domneo/@advox/inpost/src/plugin/CheckoutDeliveryOptionComponent.plugin.js'),
require('./../packages/@domneo/@advox/inpost/src/plugin/CheckoutRouteContainer.plugin.js'),
require('./../packages/@domneo/@advox/promotionalbanner/src/plugin/CategoryPageComponent.plugin.js'),
require('./../packages/@domneo/@advox/promotionalbanner/src/plugin/CategoryPageContainer.plugin.js'),
require('./../packages/@domneo/@advox/promotionalbanner/src/plugin/ProductListComponent.plugin.js'),
require('./../packages/@domneo/@advox/promotionalbanner/src/plugin/ProductListContainer.plugin.js'),
require('./../packages/@domneo/@advox/promotionalbanner/src/plugin/ProductListPageComponent.plugin.js'),
require('./../packages/@domneo/@advox/promotionalbanner/src/plugin/PromotionalBannersReducer.plugin.js'),
require('./../packages/@domneo/@advox/announcement/src/plugin/AnnouncementReducer.plugin.js'),
require('./../packages/@domneo/@advox/announcement/src/plugin/RouterComponent.plugin.js'),
require('./../packages/@domneo/@advox/categoryadditionaldescription/src/plugin/CategoryPageRouteComponent.plugin.js'),
require('./../packages/@domneo/@advox/categoryadditionaldescription/src/plugin/CategoryPageRouteContainer.plugin.js'),
require('./../packages/@domneo/@advox/categoryadditionaldescription/src/plugin/CategoryQuery.plugin.js'),
require('./../packages/@domneo/@advox/catalogWidget/src/plugin/HtmlComponent.plugin.js'),
require('./../packages/@domneo/@advox/slider/src/plugin/HtmlComponent.plugin.js'),
require('./../packages/@domneo/@scandipwa/sociallogin/src/plugin/MyAccount.container.plugin.js'),
require('./../packages/@domneo/@scandipwa/sociallogin/src/plugin/MyAccount.dispatcher.plugin.js'),
require('./../packages/@domneo/@scandipwa/sociallogin/src/plugin/MyAccount.state.plugin.js'),
require('./../packages/@domneo/@scandipwa/sociallogin/src/plugin/MyAccountCreateAccount.component.plugin.js'),
require('./../packages/@domneo/@scandipwa/sociallogin/src/plugin/MyAccountCreateAccount.state.plugin.js'),
require('./../packages/@domneo/@scandipwa/sociallogin/src/plugin/MyAccountSignIn.component.plugin.js'),
require('./../packages/@domneo/@scandipwa/sociallogin/src/plugin/MyAccountSignIn.state.plugin.js'),
require('./../packages/@domneo/@scandipwa/sociallogin/src/plugin/Router.container.plugin.js'),
require('./../packages/@domneo/@scandipwa/sociallogin/src/plugin/SocialLoginReducer.plugin.js'),
require('./../packages/@domneo/@scandipwa/sociallogin/src/plugin/UrlHandler.plugin.js'),
require('./../packages/@domneo/@scandipwa/extrafee/src/plugin/CartPageComponent.plugin.js'),
require('./../packages/@domneo/@scandipwa/extrafee/src/plugin/CheckoutMapDispatchToProps.plugin.js'),
require('./../packages/@domneo/@scandipwa/extrafee/src/plugin/CheckoutOrderSummaryPriceLineComponent.plugin.js'),
require('./../packages/@domneo/@scandipwa/extrafee/src/plugin/StoreReducer.plugin.js'),
require('./../packages/@domneo/@scandipwa/blog/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/@domneo/@scandipwa/blog/src/plugin/HeaderComponent.plugin.js'),
require('./../packages/@domneo/@scandipwa/blog/src/plugin/Image.plugin.js'),
require('./../packages/@domneo/@scandipwa/blog/src/plugin/StoreIndex.plugin.js'),
require('./../packages/@domneo/@scandipwa/blog/src/plugin/UrlRewrites.plugin.js'),
require('./../packages/@domneo/@advox/opengraph/src/plugin/MetaComponent.plugin.js'),
require('./../packages/@domneo/@advox/opengraph/src/plugin/MetaStore.plugin.js'),
require('./../packages/@domneo/@advox/crosssellpopup/src/plugin/CategoryPageComponent.plugin.js'),
require('./../packages/@domneo/@advox/crosssellpopup/src/plugin/CategoryPageContainer.plugin.js'),
require('./../packages/@domneo/@advox/crosssellpopup/src/plugin/CmsPageComponent.plugin.js'),
require('./../packages/@domneo/@advox/crosssellpopup/src/plugin/CmsPageContainer.plugin.js'),
require('./../packages/@domneo/@advox/crosssellpopup/src/plugin/ProductContainer.plugin.js'),
require('./../packages/@domneo/@advox/crosssellpopup/src/plugin/ProductListQuery.plugin.js'),
require('./../packages/@domneo/@advox/crosssellpopup/src/plugin/ProductPageComponent.plugin.js'),
require('./../packages/@domneo/@advox/crosssellpopup/src/plugin/ProductPageContainer.plugin.js')]);
import { render } from 'react-dom';

import App from 'Component/App';

import 'Util/Polyfill';
import 'Util/Sentry';
import 'Style/main';

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker.register(swUrl, { scope: '/' });
    });
}

render(<App />, document.getElementById('root'));
