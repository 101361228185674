import PropTypes from 'prop-types';

import { Loader as SourceLoader } from 'SourceComponent/Loader/Loader.component';

/** @namespace Pwa/Component/Loader/Component/Loader */
export class Loader extends SourceLoader {
    static propTypes = {
        ...SourceLoader.propTypes,
        isFullscreen: PropTypes.bool,
    };

    static defaultProps = {
        ...SourceLoader.defaultProps,
        isFullscreen: false,
    };

    renderMain() {
        return (
            <div block="Loader" elem="Main">
                <span />
            </div>
        );
    }

    render() {
        const { isLoading, isFullscreen } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="Loader" mods={{ isFullscreen }}>
                <div block="Loader" elem="Scale">
                    {this.renderMain()}
                </div>
            </div>
        );
    }
}

export default Loader;
