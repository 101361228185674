import {
    VALIDATION_MESSAGES as SOURCE_VALIDATION_MESSAGES,
    VALIDATION_RULES as SOURCE_VALIDATION_RULES,
} from 'SourceUtil/Validator/Config.js';
import { VALIDATION_INPUT_TYPE_TEXT } from 'Util/Validator/Config';

export * from 'SourceUtil/Validator/Config.js';

export const VALIDATION_INPUT_TYPE_NUMBER = {
    numeric: 'numeric',
    numericDash: 'numericDash',
    integer: 'integer',
    decimal: 'decimal',
    natural: 'natural',
    naturalNoZero: 'naturalNoZero',
    vatId: 'vatId',
};

export const VALIDATION_INPUT_TYPE_INPUT = {
    email: 'email',
    password: 'password',
    date: 'date',
    phone: 'phone',
    emailList: 'emailList',
    newPassword: 'newPassword',
};

export const VALIDATION_INPUT_TYPE = {
    ...VALIDATION_INPUT_TYPE_TEXT,
    ...VALIDATION_INPUT_TYPE_NUMBER,
    ...VALIDATION_INPUT_TYPE_INPUT,
};

export const VALIDATION_MESSAGES = {
    ...SOURCE_VALIDATION_MESSAGES,
    [VALIDATION_INPUT_TYPE.phone]: __('Please enter phone number in the correct format, e.g. 123456789'),
    [VALIDATION_INPUT_TYPE.vatId]: __('Please enter vat id in the correct format, e.g. 1234567890'),
    [VALIDATION_INPUT_TYPE.newPassword]: __(
        'The password needs at least %1 characters. Create a new password and try again.'
    ),
};

export const VALIDATION_RULES = {
    ...SOURCE_VALIDATION_RULES,
    [VALIDATION_INPUT_TYPE.alphaSpace]: /^[\s\p{L}]+$/u,
    [VALIDATION_INPUT_TYPE.phone]: /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{3,7}$/im,
    [VALIDATION_INPUT_TYPE.vatId]: /^\d{10}$/,
    [VALIDATION_INPUT_TYPE.newPassword]:
        /^(?=.*\d)(?=.*[@#\-_$%^&+=§!?])(?=.*[a-z])(?=.*[A-Z])[0-9A-Za-z@#\-_$%^&+=§!?]{8,20}$/,
};
