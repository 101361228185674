/* eslint-disable react/no-did-update-set-state */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { BILLING_STEP } from 'Route/Checkout/Checkout.config';
import { CustomerType } from 'Type/Account.type';
import { TotalsType } from 'Type/MiniCart.type';
import { isSignedIn } from 'Util/Auth';

import { extraFeeType } from '../../type/ExtraFee';
import { ExtraFeeComponent } from './ExtraFee.component';

export const ExtraFeeDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    '../../store/ExtraFee/ExtraFee.dispatcher'
);

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Extrafee/Component/ExtraFee/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer,
    extraFee: state.ExtraFeeReducer.extraFee,
    currency: state.ConfigReducer.currencyData.current_currency_code
});

/** @namespace Extrafee/Component/ExtraFee/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateExtraFee: (customerId) => ExtraFeeDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch, customerId)
    ),
    requestCustomerData: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestCustomerData(dispatch)
    )
});

/** @namespace Extrafee/Component/ExtraFee/Container/ExtraFeeContainer */
export class ExtraFeeContainer extends PureComponent {
    static propTypes = {
        totals: TotalsType.isRequired,
        customer: CustomerType.isRequired,
        extraFee: extraFeeType.isRequired,
        currency: PropTypes.string.isRequired,
        checkoutStep: PropTypes.string,
        updateExtraFee: PropTypes.func.isRequired
    };

    static defaultProps = {
        checkoutStep: ''
    };

    state = {
        isEmptyCart: false
    };

    componentDidMount() {
        const {
            updateExtraFee,
            customer: {
                id: customerId
            } = {}
        } = this.props;

        updateExtraFee(customerId);
    }

    componentDidUpdate(prevProps) {
        const {
            checkoutStep,
            updateExtraFee,
            customer: { id: customerId } = {},
            totals: { grand_total: grandTotal = 0 } = {}
        } = this.props;
        const {
            totals: { grand_total: prevGrandTotal = 0 } = {}
        } = prevProps;
        const { isEmptyCart } = this.state;

        if (grandTotal === 0 && checkoutStep !== BILLING_STEP && !isEmptyCart) {
            this.setState({ isEmptyCart: true });
        }

        if (checkoutStep === BILLING_STEP && isEmptyCart) {
            this.setState({ isEmptyCart: false });
        }

        if (grandTotal !== prevGrandTotal) {
            updateExtraFee(customerId);
        }
    }

    __construct(props) {
        const {
            requestCustomerData,
            customer
        } = props;

        if (isSignedIn() && !Object.keys(customer).length) {
            requestCustomerData();
        }
    }

    render() {
        const {
            totals,
            extraFee,
            currency,
            checkoutStep
        } = this.props;
        const { isEmptyCart } = this.state;

        return (
            <ExtraFeeComponent
              totals={ totals }
              isEmptyCart={ isEmptyCart }
              extraFee={ extraFee.extraFee }
              currency={ currency }
              checkoutStep={ checkoutStep }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtraFeeContainer);
