/* eslint-disable array-callback-return */
import React, { PureComponent } from 'react';

import AddIcon from 'Component/AddIcon';
import Link from 'Component/Link';
import MinusIcon from 'Component/MinusIcon';

import { DEFAULT_VISIBLE_TAGS } from './ArrangementProductTags.config';

import './ArrangementProductTags.style';

/** @namespace Pwa/Component/ArrangementProductTags/Component/ArrangementProductTags */
export class ArrangementProductTags extends PureComponent {
    static defaultProps = {
        visibleTagsAmount: DEFAULT_VISIBLE_TAGS,
    };

    componentDidMount() {
        this.toggleClickListener();
    }

    componentWillUnmount() {
        this.toggleClickListener('remove');
    }

    __construct(props) {
        super.__construct(props);
        this.tagsSectionRef = React.createRef();
        this.showButtonRef = React.createRef();
        this.hideButtonRef = React.createRef();
    }

    toggleClickListener(type = 'add') {
        if (type === 'remove') {
            document.removeEventListener('click', this.handleToggleTagsClick.bind(this));
            return;
        }

        document.addEventListener('click', this.handleToggleTagsClick.bind(this));
    }

    handleToggleTagsClick(e) {
        const { target } = e;
        const tags = this.tagsSectionRef.current;
        const showButton = this.showButtonRef.current;
        const hideButton = this.hideButtonRef.current;

        if (target === showButton) {
            tags.classList?.add('isActive');
        }

        if (target === hideButton) {
            tags.classList?.remove('isActive');
        }
    }

    renderToggleTagsButton() {
        const { block } = this.props;

        return (
            <button
                type="button"
                block={block}
                elem="ToggleButton"
                aria-label={__('expand')}
                onClick={(e) => e.preventDefault()}
                mix={{ block: 'ArrangementProductTags', elem: 'ToggleButton' }}
            >
                <span
                    block={block}
                    elem="ShowTags"
                    ref={this.showButtonRef}
                    mix={{ block: 'ArrangementProductTags', elem: 'ShowTags' }}
                >
                    <AddIcon />
                </span>
                <span
                    block={block}
                    elem="HideTags"
                    ref={this.hideButtonRef}
                    mix={{ block: 'ArrangementProductTags', elem: 'HideTags' }}
                >
                    <MinusIcon />
                </span>
            </button>
        );
    }

    getAvailableTags() {
        const { attributes, isProductWithHotspotsWidget, tags } = this.props;

        if (!tags.length) {
            return null;
        }
        const availableTags = [];

        if (isProductWithHotspotsWidget) {
            attributes?.map((attribute) => {
                tags.map((tag) => {
                    if (attribute.attribute_code === tag.attribute_code && attribute.attribute_value) {
                        availableTags.push(attribute);
                    }
                });
            });

            return availableTags;
        }

        tags.map((tag) => {
            if (
                tag &&
                attributes?.length > 0 &&
                attributes[tag.attribute_code] &&
                attributes[tag.attribute_code].attribute_value
            ) {
                availableTags.push(attributes[tag.attribute_code]);
            }
        });

        return availableTags;
    }

    renderArrangementTag(tagName, tagLink, isHidden) {
        const { block } = this.props;

        if (!tagName) {
            return null;
        }

        return (
            <Link
                to={tagLink}
                block={block}
                elem="Tag"
                mods={{ isHidden }}
                mix={{ block: 'ArrangementProductTags', elem: 'Tag', mods: { isHidden } }}
            >
                <span block={block} elem="Hashtag" mix={{ block: 'ArrangementProductTags', elem: 'Hashtag' }}>
                    #
                </span>
                {tagName}
            </Link>
        );
    }

    renderArrangementTags() {
        const { block, categories, isProductWithHotspotsWidget, tags = [], visibleTagsAmount } = this.props;

        if (!tags?.length) {
            return null;
        }

        const availableTags = this.getAvailableTags();
        const arrangementCategoryUrl = categories[0]?.url;
        const isExpandable = availableTags?.length > visibleTagsAmount;

        return (
            <div
                block={block}
                elem="Tags"
                mods={{ isExpandable }}
                ref={this.tagsSectionRef}
                mix={{ block: 'ArrangementProductTags', elem: 'Tags', mods: { isExpandable } }}
            >
                {availableTags.map((tag, index) => {
                    if (tag) {
                        if (Object.keys(tag.attribute_options).length) {
                            const tagValue = isProductWithHotspotsWidget
                                ? tag.attribute_options[0]?.label
                                : tag.attribute_options[tag.attribute_value]?.label;

                            return this.renderArrangementTag(
                                tagValue,
                                `${arrangementCategoryUrl}?customFilters=${tag?.attribute_code}:${tagValue}`,
                                index >= visibleTagsAmount
                            );
                        }

                        return this.renderArrangementTag(
                            tag?.attribute_value,
                            `${arrangementCategoryUrl}?customFilters=${tag?.attribute_code}:${tag?.attribute_value}`,
                            index >= visibleTagsAmount
                        );
                    }
                })}
                {isExpandable && this.renderToggleTagsButton()}
            </div>
        );
    }

    render() {
        return <>{this.renderArrangementTags()}</>;
    }
}

export default ArrangementProductTags;
