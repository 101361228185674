/** @namespace Domneo/Promotionalbanner/Store/PromotionalBanner/Reducer/getInitialState */
import { UPDATE_PROMOTIONAL_BANNERS } from './PromotionalBanner.action';

/** @namespace Domneo/Promotionalbanner/Store/PromotionalBanner/Reducer/getInitialState */
export const getInitialState = () => ({
    promotional_banners: [],
});

/** @namespace Domneo/Promotionalbanner/Store/PromotionalBanner/Reducer/PromotionalBannersReducer */
export const PromotionalBannersReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case UPDATE_PROMOTIONAL_BANNERS:
            const { promotional_banners } = action;

            return {
                ...state,
                promotional_banners,
            };

        default:
            return state;
    }
};
