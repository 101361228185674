import ArrangementProductTags from 'Component/ArrangementProductTags';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { ProductCard as SourceProductCard } from 'Component/ProductCard/ProductCard.component';
import { NO_SELECTION } from 'Component/ProductCard/ProductCard.config';
import ProductDiscountLabel from 'Component/ProductDiscountLabel';
import { formatPrice } from 'Util/Price';

import { PLP_VISIBLE_TAGS_AMOUNT } from './ArrangementProductCard.config';

import './ArrangementProductCard.style';
/** @namespace Pwa/Component/ArrangementProductCard/Component/ArrangementProductCard */
export class ArrangementProductCard extends SourceProductCard {
    renderArrangementProductActions() {
        const {
            hiddenActions,
            device: { isMobile },
        } = this.props;

        if (hiddenActions || isMobile) {
            return null;
        }

        return (
            <div block="ProductCard" elem="ProductActions">
                {this.renderProductCardWishlistButton()}
            </div>
        );
    }

    renderArrangementVisibleOnHover() {
        const {
            device: { isMobile },
            hiddenHoverSection,
            product: { url },
        } = this.props;

        if (isMobile || hiddenHoverSection) {
            return null;
        }

        return (
            <div block="ProductCard" elem="VisibleOnHover">
                <div block="ProductCard" elem="Footer">
                    <Link to={url} block="Button" mix={{ block: 'ProductCard', elem: 'ArrangementHoverButton' }}>
                        {__('See')}
                    </Link>
                </div>
            </div>
        );
    }

    renderArrangementRegularPrice() {
        const { product: { price_range: { maximum_price: { regular_price } = {} } = {} } = {} } = this.props;

        if (!regular_price?.value) {
            return null;
        }

        return <>{formatPrice(regular_price?.value, regular_price?.currency)}</>;
    }

    renderArrangementFinalPrice() {
        const { product: { price_range: { minimum_price: { final_price = {} } = {} } = {} } = {} } = this.props;

        if (!final_price?.value) {
            return null;
        }

        return <>{formatPrice(final_price?.value, final_price?.currency)}</>;
    }

    renderArrangementPrice() {
        const {
            productPrice,
            product: { price_range: { maximum_price: { discount: { amount_off = 0 } = {} } = {} } = {} } = {},
        } = this.props;

        const arrangementDiscountAvailable = amount_off > 0;

        if (!productPrice) {
            return null;
        }

        if (arrangementDiscountAvailable) {
            return (
                <div block="ProductCard" elem="PriceWrapper">
                    <div block="ProductPrice" mix={{ block: 'ProductCard', elem: 'Price' }}>
                        <ins block="ProductPrice" elem="Price">
                            <span block="ProductPrice" elem="PriceValue">
                                {this.renderArrangementFinalPrice()}
                            </span>
                        </ins>
                        <del block="ProductPrice" elem="HighPrice">
                            {this.renderArrangementRegularPrice()}
                        </del>
                    </div>
                </div>
            );
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">
                <div block="ProductPrice" mix={{ block: 'ProductCard', elem: 'Price' }}>
                    <span block="ProductPrice" elem="Price">
                        <span block="ProductPrice" elem="PriceValue">
                            {this.renderArrangementFinalPrice()}
                        </span>
                    </span>
                </div>
            </div>
        );
    }

    renderArrangementDiscount() {
        const {
            product: {
                price_range: { maximum_price: { regular_price = {}, discount: { amount_off = 0 } = {} } = {} } = {},
            } = {},
        } = this.props;

        if (amount_off === 0) {
            return null;
        }

        return (
            <div block="ProductCard" elem="Discount">
                <ProductDiscountLabel
                    discountType="fixed"
                    fixedDiscount={formatPrice(amount_off, regular_price?.currency)}
                    displayWithMinus
                />
            </div>
        );
    }

    renderArrangementLabelsAndTagsWrapper() {
        return (
            <div block="ArrangementProductCard" elem="LabelsAndTagsWrapper">
                {this.renderLabel()}
                {this.renderArrangementTags()}
            </div>
        );
    }

    renderArrangementTags() {
        const {
            product: { attributes, categories, tags = [] },
        } = this.props;

        if (!tags?.length) {
            return null;
        }

        return (
            <ArrangementProductTags
                attributes={attributes}
                block="ArrangementProductCard"
                categories={categories}
                tags={tags}
                visibleTagsAmount={PLP_VISIBLE_TAGS_AMOUNT}
            />
        );
    }

    renderArrangementCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return this.renderCardLinkWrapper(
            <div
                block="ProductCard"
                elem="ContentWrapper"
                mix={{ block: 'ArrangementProductCard', elem: 'ContentWrapper' }}
            >
                <div block="ProductCard" elem="FigureReview">
                    <figure block="ProductCard" elem="Figure">
                        {this.renderPicture()}
                        {this.renderArrangementDiscount()}
                        {this.renderArrangementLabelsAndTagsWrapper()}
                        {this.renderArrangementProductActions()}
                    </figure>
                </div>
                <div block="ProductCard" elem="Content">
                    {this.renderArrangementPrice()}
                    {this.renderProductName()}
                    {this.renderReviews()}
                </div>
                {this.renderArrangementVisibleOnHover()}
            </div>
        );
    }

    registerSharedElement() {
        const { registerSharedElement } = this.props;

        if (!registerSharedElement) {
            return null;
        }

        registerSharedElement(this.imageRef);
    }

    renderPicture(mix = {}) {
        const {
            product: { id, name, alternative_hover_image },
            thumbnail,
        } = this.props;
        const { url: alternativeHoverImageUrl, path: alternativeHoverImagePath } = alternative_hover_image || {};

        this.sharedComponent = (
            <Image
                imageRef={this.imageRef}
                src={thumbnail}
                alt={name}
                ratio="custom"
                mix={{ block: 'ProductCard', elem: 'Picture', mix }}
                isPlaceholder={!id}
            />
        );
        this.hoverImage = (
            <Image
                src={alternativeHoverImageUrl}
                alt={name}
                ratio="custom"
                mix={{ block: 'ProductCard', elem: 'PictureHover', mix }}
                isPlaceholder={!id}
            />
        );

        return (
            <>
                {this.sharedComponent}
                {alternativeHoverImageUrl && alternativeHoverImagePath !== NO_SELECTION && this.hoverImage}
                <img style={{ display: 'none' }} alt={name} src={thumbnail} />
            </>
        );
    }

    render() {
        const { children, mix, isLoading, layout } = this.props;

        return (
            <li block="ProductCard" mods={{ layout }} mix={mix}>
                <Loader isLoading={isLoading} />
                {this.renderArrangementCardContent()}
                <div block="ProductCard" elem="AdditionalContent">
                    {children}
                </div>
            </li>
        );
    }
}

export default ArrangementProductCard;
