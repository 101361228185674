/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/scandipwa-gtm
 * @author  Ainars Sondors <info@scandiweb.com>
 */
import { isSignedIn } from 'Util/Auth';
import { fetchMutation } from 'Util/Request';

import MyAccountQuery from '../query/MyAccount.query';

/** @namespace Domneo/SocialLogin/Plugin/MyAccount/Dispatcher/MyAccountDispatcher */
export class MyAccountDispatcher {
    // eslint-disable-next-line no-unused-vars
    logout = (args, callback, _instance) => {
        const [authTokenExpired] = args;

        if (!authTokenExpired && isSignedIn()) {
            const query = MyAccountQuery.getLogoutMutation();
            fetchMutation(query);
        } else {
            const isWithNotification = args[1];
            if (isWithNotification) {
                args[1] = false;
            }
        }
        callback(...args);
    };
}

const { logout } = new MyAccountDispatcher();

export default {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            logout,
        },
    },
};
