import { MyAccountQuery as SourceMyAccountQuery } from 'SourceQuery/MyAccount.query';

/** @namespace Pwa/Query/MyAccount/Query/MyAccountQuery */
export class MyAccountQuery extends SourceMyAccountQuery {
    _getAddressFields() {
        return [...super._getAddressFields(), 'company'];
    }
}

export default new MyAccountQuery();
