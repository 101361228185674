import PropTypes from 'prop-types';

const mapStateToProps = (args, callback) => {
    const [state] = args;

    return {
        ...callback(...args),
        promotional_banners: state.PromotionalBannersReducer.promotional_banners,
    };
};

const propTypes = (originalMember) => ({
    ...originalMember,
    promotional_banners: PropTypes.instanceOf(Array).isRequired,
});

export class ProductListContainerPlugin {
    containerProps(args, callback, instance) {
        const { promotional_banners } = instance.props;
        return {
            ...callback.apply(instance, args),
            promotional_banners,
        };
    }
}

const { containerProps } = new ProductListContainerPlugin();

export default {
    'Component/ProductList/Container/mapStateToProps': {
        function: mapStateToProps,
    },
    'Component/ProductList/Container': {
        'member-function': {
            containerProps,
        },
        'static-member': {
            propTypes,
        },
    },
};
