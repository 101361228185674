import PropTypes from 'prop-types';

import FIELD_TYPE from 'Component/Field/Field.config';
import { FormContainer as SourceFormContainer } from 'SourceComponent/Form/Form.container';
import getFieldsData from 'Util/Form/Extract';
import { validateGroup } from 'Util/Validator';

/** @namespace Pwa/Component/Form/Container/FormContainer */
export class FormContainer extends SourceFormContainer {
    static propTypes = {
        ...SourceFormContainer.propTypes,
        ignoreFields: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        ...SourceFormContainer.defaultProps,
        ignoreFields: [FIELD_TYPE.number, FIELD_TYPE.button],
    };

    setRef(elem) {
        const { validationRule, elemRef } = this.props;

        if (elem && this.formRef !== elem) {
            this.formRef = elem;

            if (elemRef) {
                if (typeof elemRef === 'function') {
                    elemRef(elem);
                } else {
                    elemRef.current = elem;
                }
            }

            elem.addEventListener('reset', this.resetField.bind(this));

            if (validationRule && Object.keys(validationRule).length > 0) {
                elem.addEventListener('validate', this.validate.bind(this));
            }
        }
    }

    async onSubmit(e) {
        e.preventDefault();

        const { onSubmit, onError, returnAsObject = false, validationRule, ignoreFields } = this.props;

        const fields = getFieldsData(this.formRef, false, ignoreFields, returnAsObject);
        const isValid = validateGroup(this.formRef, validationRule);

        if (isValid !== true) {
            if (typeof onError === 'function') {
                onError(this.formRef, fields, isValid);
            }

            return;
        }

        if (typeof onSubmit === 'function') {
            onSubmit(this.formRef, fields);
        }
    }
}

export default FormContainer;
