import { SET_ARRANGEMENT_BUILT_PRODUCT } from './ArrangementBuiltProduct.action';

/** @namespace Pwa/Store/ArrangementBuiltProduct/Reducer/getInitialState */
export const getInitialState = () => ({
    isArrangementBuiltProduct: '',
    arrangementSrc: '',
});

/** @namespace Pwa/Store/ArrangementBuiltProduct/Reducer/ArrangementBuiltProductReducer */
export const ArrangementBuiltProductReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case SET_ARRANGEMENT_BUILT_PRODUCT:
            const { isArrangementBuiltProduct, arrangementSrc } = action;

            return {
                ...state,
                isArrangementBuiltProduct,
                arrangementSrc,
            };

        default:
            return state;
    }
};

export default ArrangementBuiltProductReducer;
