// import PropTypes from 'prop-types';
import { connect } from "react-redux";
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer,
} from "Component/ProductCard/ProductCard.container";
import CrossSellProductCard from "./CrossSellProductCard.component";

/** @namespace Crosssellpopup/Component/CrossSellProductCard/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    ...sourceMapStateToProps(_state),
});

/** @namespace Crosssellpopup/Component/CrossSellProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    ...sourceMapDispatchToProps(_dispatch),
});

/** @namespace Crosssellpopup/Component/CrossSellProductCard/Container/CrossSellProductCardContainer */
export class CrossSellProductCardContainer extends ProductCardContainer {
    static defaultProps = {
        ...ProductCardContainer.defaultProps,
    };

    static propTypes = {
        ...ProductCardContainer.propTypes,
    };

    containerFunctions = {
        ...this.containerFunctions,
    };

    containerProps() {
        return {
            ...super.containerProps(),
        };
    }

    render() {
        return (
            <CrossSellProductCard
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CrossSellProductCardContainer);
