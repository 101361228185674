import { PureComponent } from 'react';

import Icons from 'Component/Icons';

import './SliderPrev.style.scss';

/** @namespace Pwa/Component/SliderPrev/Component/SliderPrevComponent */
export class SliderPrevComponent extends PureComponent {
    render() {
        const { onClick, className } = this.props;
        const isDisabled = className ? className.search('disabled') !== -1 : false;

        return (
            <div block="SliderPrev" mods={{ disabled: isDisabled }} onClick={onClick}>
                <Icons name="arrow_left" />
            </div>
        );
    }
}

export default SliderPrevComponent;
