import { ProductPrice as SourceProductPrice } from 'SourceComponent/ProductPrice/ProductPrice.component';
import { getProductPriceWithQty } from 'Util/Product/Extract';

import './ProductPrice.override.style';

/** @namespace Pwa/Component/ProductPrice/Component/ProductPrice */
export class ProductPrice extends SourceProductPrice {
    renderDefaultPrice(defaultLabel = null) {
        const { price: { finalPrice = {}, finalPriceExclTax = {}, originalPrice = {} } = {}, label } = this.props;

        return (
            <>
                {this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, defaultLabel || label)}
                {this.renderSchema()}
                {this.renderOldPrice(originalPrice)}
            </>
        );
    }

    renderSquareMeters() {
        const { isProductWithCalculator } = this.props;

        if (!isProductWithCalculator) {
            return null;
        }

        return (
            <span block="ProductPrice" elem="Qmeters">
                /m<sup>2</sup>
            </span>
        );
    }

    renderPrice(price, label) {
        const {
            mix: { block },
            quantity,
            increment,
            isVirtualProduct,
        } = this.props;

        const { itemProp = null, content = null } = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = 'ins';

        if (block === 'ProductActions') {
            return (
                <div block="ProductActions" elem="PromoPrice">
                    <span block="ProductActions" elem="PromoPrice">
                        {isVirtualProduct ? __('Promo price') : __('Domneo price')}
                    </span>
                    <PriceSemanticElementName block="ProductPrice" elem="Price">
                        {this.renderPriceBadge(label)}
                        <span itemProp={itemProp} content={content} block="ProductPrice" elem="PriceValue">
                            {getProductPriceWithQty(price, quantity, { increment })}
                            {this.renderSquareMeters()}
                        </span>
                    </PriceSemanticElementName>
                </div>
            );
        }

        return (
            <PriceSemanticElementName block="ProductPrice" elem="Price">
                {this.renderPriceBadge(label)}
                <span itemProp={itemProp} content={content} block="ProductPrice" elem="PriceValue">
                    {getProductPriceWithQty(price, quantity, { increment })}
                    {this.renderSquareMeters()}
                </span>
            </PriceSemanticElementName>
        );
    }

    renderOldPrice(price) {
        const {
            discountPercentage,
            isSchemaRequired,
            variantsCount,
            mix: { block },
            quantity,
            increment,
            percentageDiscountTreshold,
            isVirtualProduct,
        } = this.props;

        if (discountPercentage === 0 || discountPercentage < percentageDiscountTreshold || price.value === 0) {
            return null;
        }

        if (block === 'ProductActions') {
            return (
                <div block="ProductActions" elem="RegularPrice">
                    <span block="ProductActions" elem="RegularPrice">
                        {isVirtualProduct ? __('Regular price') : __('Catalog price')}
                    </span>
                    <del
                        block="ProductPrice"
                        elem="HighPrice"
                        aria-label={__('Old product price')}
                        itemProp={isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null}
                    >
                        {getProductPriceWithQty(price, quantity, { increment })}
                        {this.renderSquareMeters()}
                    </del>
                </div>
            );
        }

        return (
            <del
                block="ProductPrice"
                elem="HighPrice"
                aria-label={__('Old product price')}
                itemProp={isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null}
            >
                {getProductPriceWithQty(price, quantity, { increment })}
                {this.renderSquareMeters()}
            </del>
        );
    }
}

export default ProductPrice;
