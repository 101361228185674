export const UPDATE_GMT_CONFIG = 'UPDATE_GMT_CONFIG';
export const EVENT_EXECUTED = 'EVENT_EXECUTED';
export const EXECUTE_EVENT = 'EXECUTE_EVENT';
export const GTM_INITIALIZED = 'GTM_INITIALIZED';

/** @namespace Domneo/Gtm/Store/GoogleTagManager/Action/updateConfig */
export const updateConfig = (payload) => ({
    type: UPDATE_GMT_CONFIG,
    payload,
});

/** @namespace Domneo/Gtm/Store/GoogleTagManager/Action/setExecuted */
export const setExecuted = () => ({
    type: EVENT_EXECUTED,
});

/** @namespace Domneo/Gtm/Store/GoogleTagManager/Action/event */
export const event = (event = '', custom = {}) => ({
    type: EXECUTE_EVENT,
    event,
    custom,
});
