import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { zeroBasedValue } from 'Util/Form/Extract';

import Countdown from './Countdown.component';
/** @namespace Domneo/Slider/Components/Countdown/Container/CountdownContainer */
export class CountdownContainer extends PureComponent {
    static propTypes = {
        date: PropTypes.string,
    };

    state = {
        days: '',
        hours: '',
        minutes: '',
        seconds: '',
        expired: false,
    };

    countDownId = null;

    containerProps() {
        const { days, hours, minutes, seconds, expired } = this.state;

        return {
            days,
            hours,
            minutes,
            seconds,
            expired,
        };
    }

    componentDidMount() {
        this._getCountdown();
    }

    componentWillUnmount() {
        if (this.countDownId) {
            clearInterval(this.countDownId);
        }
    }

    _getCountdown() {
        const { date } = this.props;
        const formattedDate = date.replaceAll('-', '/');

        const transformedDate = new Date(formattedDate).getTime();
        const duration = transformedDate - Date.now();

        let daysCount = Math.floor(duration / (1000 * 60 * 60 * 24));
        let hoursCount = Math.floor((duration / (1000 * 60 * 60)) % 24);
        let minutesCount = Math.floor((duration / (1000 * 60)) % 60);
        let secondsCount = Math.floor((duration / 1000) % 60);

        // For countdown is finished
        if (duration < 0) {
            clearInterval(this.countDownId);
            this.setState({
                days: '0',
                hours: '0',
                minutes: '0',
                seconds: '0',
                expired: true,
            });

            return;
        }
        this.setState({
            seconds: `${zeroBasedValue(secondsCount)}`,
            minutes: `${zeroBasedValue(minutesCount)}`,
            hours: `${zeroBasedValue(hoursCount)}`,
            days: `${zeroBasedValue(daysCount)}`,
            expired: false,
        });

        this.countDownId = setInterval(() => {
            if (secondsCount === 0) {
                if (minutesCount === 0) {
                    if (hoursCount === 0) {
                        if (daysCount === 0) {
                            clearInterval(this.countDownId);
                            this.setState({ expired: true });
                        } else {
                            daysCount -= 1;
                            hoursCount = 24;
                            this.setState({ hours: `${zeroBasedValue(daysCount)}` });
                        }
                    } else {
                        hoursCount -= 1;
                        minutesCount = 59;
                        this.setState({ hours: `${zeroBasedValue(hoursCount)}` });
                    }
                } else {
                    minutesCount -= 1;
                    secondsCount = 59;
                    this.setState({ minutes: `${zeroBasedValue(minutesCount)}` });
                }
            } else {
                secondsCount -= 1;
                this.setState({ seconds: `${zeroBasedValue(secondsCount)}` });
            }
        }, 1000);
    }

    render() {
        return <Countdown {...this.containerProps()} />;
    }
}

export default CountdownContainer;
