/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';

import { IMAGE_WITH_TEXT } from './PromotionalBanner.config';

import './PromotionalBanner.style';
/** @namespace Domneo/Promotionalbanner/Component/PromotionalBanner/Component/PromotionalBannerComponent */
export class PromotionalBannerComponent extends PureComponent {
    static propTypes = {
        as: PropTypes.string,
        gridColumn: PropTypes.string.isRequired,
        banner: PropTypes.instanceOf(Object),
        isBannerActive: PropTypes.func.isRequired,
    };

    static defaultProps = {
        as: null,
        banner: null,
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    _getGridColumn() {
        const {
            banner: { type },
            gridColumn,
        } = this.props;

        const wideStyles = type === 'wide' ? gridColumn : '';
        return wideStyles;
    }

    renderBannerContent() {
        const { banner } = this.props;

        const { text, text_color, background_url, button_url, image = null, variant, background_url_tab } = banner;
        const isImageWithTextVariant = variant === IMAGE_WITH_TEXT;

        return (
            <>
                {isImageWithTextVariant && (
                    <span block="PromotionalBanner" elem="Text" style={{ color: text_color }}>
                        {text}
                    </span>
                )}
                <div>
                    {image && <img src={image} alt={text} />}
                    {button_url && !background_url ? (
                        <Link isOpenInNewTab={background_url_tab} to={button_url}>
                            {this.renderButton(banner)}
                        </Link>
                    ) : (
                        this.renderButton(banner)
                    )}
                </div>
            </>
        );
    }

    renderButton() {
        const { banner } = this.props;

        const { button_text_color, button_border_color, button_url, button_url_tab } = banner;

        return (
            <Link
                to={button_url || '#'}
                block="PromotionalBanner"
                isOpenInNewTab={button_url_tab}
                elem="Button"
                mix={{ block: 'Button', mods: { isHollow: true } }}
                style={{ color: button_text_color, borderColor: button_border_color }}
            >
                {__('Buy now')}
            </Link>
        );
    }

    renderBanner() {
        const { banner } = this.props;

        const { gridColumn, isBannerActive } = this.props;

        if (!banner || !isBannerActive()) {
            return null;
        }

        const { background_url, background_color, type, background_url_tab } = banner;
        const wideStyles = type === 'wide' ? gridColumn : '';

        return background_url ? (
            <Link
                to={background_url}
                block="PromotionalBanner"
                isOpenInNewTab={background_url_tab}
                mix={{ block: type }}
                style={{ gridColumn: wideStyles, backgroundColor: background_color }}
            >
                {this.renderBannerContent(banner)}
            </Link>
        ) : (
            <div
                block="PromotionalBanner"
                mix={{ block: type }}
                style={{ gridColumn: wideStyles, backgroundColor: background_color }}
            >
                {this.renderBannerContent(banner)}
            </div>
        );
    }

    render() {
        const { as, banner } = this.props;

        if (!banner) return null;

        const content = this.renderBanner(banner);

        if (as) {
            const Component = as;
            return <Component style={{ gridColumn: this._getGridColumn() }}>{content}</Component>;
        }

        return content;
    }
}

export default PromotionalBannerComponent;
