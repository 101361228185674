import { cloneElement } from 'react';

import { event } from '../store/GoogleTagManager/GoogleTagManager.action';

const renderPage = (args, callback, instance) => {
    const { event } = instance.props;
    return cloneElement(callback.apply(instance, args), { event });
};

const mapDispatchToProps = (args, callback) => {
    const [dispatch] = args;

    return {
        ...callback(...args),
        event: (eventName = '', customData) => dispatch(event(eventName, customData)),
    };
};

const containerProps = (args, callback, instance) => {
    const { event } = instance.props;

    return {
        ...callback(...args),
        event,
    };
};

export default {
    'Component/ProductList/Container/mapDispatchToProps': {
        function: mapDispatchToProps,
    },
    'Component/ProductList/Container': {
        'member-function': {
            containerProps,
        },
    },
    'Pwa/Component/ArrangementProductList/Container/ArrangementProductListContainer': {
        'member-function': {
            containerProps,
        },
    },
    'Pwa/Component/ProductList/Component/ProductList': {
        'member-function': {
            renderPage,
        },
    },
    'Pwa/Component/ArrangementProductList/Component/ArrangementProductList': {
        'member-function': {
            renderPage,
        },
    },
};
