import { fetchQuery } from 'Util/Request';

import { EVENT_GTM_PURCHASE } from '../component/GoogleTagManager/GoogleTagManager.events';
import GtmPurchaseQuery from '../query/GtmPurchase.query';

const state = (original) => ({
    ...original,
    isFired: false
});

const getPurchase = ({ orderID, email, totals, isFired } = {}, event, instance) => {
    const query = GtmPurchaseQuery.getGTMPurchase(orderID);

    if (isFired) {
        return null;
    }

    fetchQuery(query).then(({ purchase }) => {
        event(EVENT_GTM_PURCHASE, {
            ...purchase,
            orderID,
            email,
            totals,
        });
        instance.setState({ isFired: true });
    });
};

const render = (args, callback, instance) => {
    const { orderID, email, event, totals } = instance.props;
    const { isFired } = instance.state;


    if (orderID && totals) {
        getPurchase({ orderID, email, totals, isFired }, event, instance);
    }

    return callback.apply(instance, args);
};

export default {
    'Pwa/Component/CheckoutProcessingPayment/Component/CheckoutProcessingPayment': {
        'member-function': {
            render,
        },
        'member-property': {
            state
        },
    },
};
