import GroupedProductsItem from 'Component/GroupedProductsItem';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import { GroupedProductList as SourceGroupedProductList } from 'SourceComponent/GroupedProductList/GroupedProductList.component';
import { getBuiltProductWithoutSubstitutes } from 'Util/Product/Transform';

import { DEFAULT_VISIBLE_ITEMS, SHOW_MORE_BACKGROUND_ITEM_COUNT } from './GroupedProductList.config';

import './GroupedProductList.override.style';

/**
 * Product description
 * @class GroupedProductList
 * @namespace Pwa/Component/GroupedProductList/Component/GroupedProductList */
export class GroupedProductList extends SourceGroupedProductList {
    state = {
        numberOfVisibleItems: DEFAULT_VISIBLE_ITEMS || null,
        isSwapActive: false,
    };

    swapHandler = (isActive) => {
        this.setState({ isSwapActive: isActive });
    };

    renderProductList(items) {
        const {
            quantity,
            setQuantity,
            product: {
                image: { url: arrangementImageSrc },
            },
        } = this.props;
        const { numberOfVisibleItems, isSwapActive } = this.state;

        const filteredItems = getBuiltProductWithoutSubstitutes(items);
        const sortedItems = filteredItems.sort(({ position }, { position: cmpPosition }) => position - cmpPosition);
        const itemsSize = sortedItems.length;
        const sliceArray = numberOfVisibleItems + SHOW_MORE_BACKGROUND_ITEM_COUNT;

        return (
            <ul>
                {sortedItems
                    .slice(0, sliceArray)
                    .map(({ product, product: { id } = {}, qty, required, substitutes = [] }, index) => (
                        <GroupedProductsItem
                            key={id}
                            product={product}
                            defaultQuantity={qty}
                            quantity={quantity}
                            setQuantity={setQuantity}
                            required={required}
                            isHiddenElement={index === numberOfVisibleItems}
                            substitutes={substitutes}
                            arrangementImageSrc={arrangementImageSrc}
                            isSwapActive={isSwapActive}
                            swapHandler={this.swapHandler}
                        />
                    ))}
                {numberOfVisibleItems < itemsSize && this.renderShowMore(itemsSize)}
            </ul>
        );
    }

    showMore() {
        const {
            product: { items },
        } = this.props;

        this.setState({ numberOfVisibleItems: items.length });
    }

    renderShowMore(count) {
        return (
            <li block="GroupedProductList" elem="showMore">
                <button onClick={this.showMore.bind(this)}>{`${__('Show all')} (${count})`}</button>
            </li>
        );
    }

    render() {
        const {
            product: { items, type_id },
        } = this.props;

        if ((type_id !== PRODUCT_TYPE.grouped && type_id !== PRODUCT_TYPE.built) || !items) {
            return null;
        }

        return this.renderProductList(items);
    }
}

export default GroupedProductList;
