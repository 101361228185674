import { showNotification } from 'Store/Notification/Notification.action';
import { prepareQuery } from 'Util/Query';
import { executeGet, getErrorMessage } from 'Util/Request';

import { ANNOUNCEMENT_LOCATIONS } from '../../component/Announcement/Announcement.config';
import AnnouncementQuery from '../../query/Announcement.query';
import { setAnnouncement, setCurrentLocation } from './Announcement.action';

/** @namespace Domneo/Announcement/Store/Announcement/Dispatcher/AnnouncementDispatcher */
export class AnnouncementDispatcher {
    getAnnouncement(date, store, place, url, dispatch) {
        let fallbackPlace; // because UrlResolver for /cart and /search is faulty

        if (!place) {
            if (url.includes('/search')) {
                fallbackPlace = 'search';
            } else if (url.includes('/cart')) {
                fallbackPlace = 'cart';
            } else {
                fallbackPlace = 'custom';
            }
        }

        return executeGet(
            prepareQuery(AnnouncementQuery.getAnnouncementQuery(date, store, place || fallbackPlace, url), true)
        ).then(
            /** @namespace Domneo/Announcement/Store/Announcement/Dispatcher/executeGet/then */
            (response) => dispatch(setAnnouncement(response?.announcement)),
            /** @namespace Domneo/Announcement/Store/Announcement/Dispatcher/executeGet/then */
            (error) => console.error('Error fetching announcement', getErrorMessage(error), error)
        );
    }

    getCurrentLocation(url, dispatch) {
        if (url.includes('/search')) {
            // because UrlResolver for /cart and /search is faulty
            dispatch(setCurrentLocation(ANNOUNCEMENT_LOCATIONS.SEARCH));
            return;
        }

        if (url.includes('/cart')) {
            dispatch(setCurrentLocation(ANNOUNCEMENT_LOCATIONS.CART));
            return;
        }

        if (url.includes('/checkout')) {
            dispatch(setCurrentLocation(ANNOUNCEMENT_LOCATIONS.CUSTOM));
            return;
        }

        return executeGet(prepareQuery(AnnouncementQuery.getCurrentLocation(url || '/'))).then(
            /** @namespace Domneo/Announcement/Store/Announcement/Dispatcher/executeGet/then */
            ({ urlResolver }) =>
                !urlResolver?.type ? dispatch(setCurrentLocation('')) : dispatch(setCurrentLocation(urlResolver?.type)),
            /** @namespace Domneo/Announcement/Store/Announcement/Dispatcher/executeGet/then */
            (error) => dispatch(showNotification('error', getErrorMessage(error), error))
        );
    }
}

export default new AnnouncementDispatcher();
