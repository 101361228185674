import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProductStockAvailability from './ProductStockAvailability.component';

/** @namespace Pwa/Component/ProductStockAvailability/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Pwa/Component/ProductStockAvailability/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/ProductStockAvailability/Container/ProductCardContainer */
export class ProductCardContainer extends PureComponent {
    static propTypes = {
        stock_availability: PropTypes.object,
        deliverer_available: PropTypes.number,
        isProductPage: PropTypes.bool,
    };

    state = {
        status: 'onrequest',
    };

    displayText() {
        const { stock_availability, deliverer_available } = this.props;
        if (!stock_availability) {
            this.setState({ status: deliverer_available });
            return deliverer_available;
        }

        const { label } = stock_availability;
        this.setState({ status: label.toLowerCase().replace(' ', '') });

        return label;
    }

    containerProps() {
        const { status } = this.state;
        const { isProductPage, stock_availability, isProductWithCalculator } = this.props;

        return {
            status,
            isProductPage,
            stock_availability,
            isProductWithCalculator,
        };
    }

    render() {
        return <ProductStockAvailability text={this.displayText()} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
