import React from 'react';

import { BEFORE_ITEMS_TYPE } from 'Component/Router/Router.config';

import Announcement from '../component/Announcement';

const beforeItemsType = (original) => [
    ...original,
    {
        component: <Announcement />,
        position: 40,
        name: 'announcement',
    },
];

export default {
    'Component/Router/Component': {
        'member-property': {
            [BEFORE_ITEMS_TYPE]: beforeItemsType,
        },
    },
};
