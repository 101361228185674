import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { DeviceType } from 'Type/Device.type';

import PromotionalBannerComponent from './PromotionalBanner.component';
import { PRODUCTS_IN_ROW_DESKTOP, PRODUCTS_IN_ROW_MOBILE, PRODUCTS_IN_ROW_TABLET } from './PromotionalBanner.config';

/** @namespace Domneo/Promotionalbanner/Component/PromotionalBanner/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Domneo/Promotionalbanner/Component/PromotionalBanner/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Domneo/Promotionalbanner/Component/PromotionalBanner/Container/PromotionalBannerContainer */
export class PromotionalBannerContainer extends PureComponent {
    static propTypes = {
        actualProductPosition: PropTypes.number.isRequired,
        as: PropTypes.string,
        device: DeviceType.isRequired,
        isHeader: PropTypes.bool,
        banner: PropTypes.instanceOf(Object).isRequired,
    };

    static defaultProps = {
        as: null,
        isHeader: false,
    };

    containerFunctions = {
        isBannerActive: this.isBannerActive.bind(this),
    };

    containerProps() {
        const { as, banner } = this.props;
        return {
            as,
            gridColumn: `span ${this.getGridColumn()}`,
            banner,
        };
    }

    getGridColumn() {
        const {
            device: { isMobile, isTablet },
        } = this.props;

        if (isMobile) {
            return PRODUCTS_IN_ROW_MOBILE;
        }

        if (isTablet) {
            return PRODUCTS_IN_ROW_TABLET;
        }

        return PRODUCTS_IN_ROW_DESKTOP;
    }

    isBannerActive() {
        const { banner } = this.props;
        const { active_from, active_to } = banner;

        const today = new Date();
        const date_from = new Date(active_from);
        const date_to = new Date(active_to);

        if (today > date_from && today < date_to) {
            return true;
        }

        return false;
    }

    render() {
        return <PromotionalBannerComponent {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PromotionalBannerContainer));
