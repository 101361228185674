import { ArrangementProductCard as SourceProductCard } from 'Component/ArrangementProductCard/ArrangementProductCard.component';
import Image from 'Component/Image';
import Loader from 'Component/Loader';
import { NO_SELECTION } from 'Component/ProductCard/ProductCard.config';

/** @namespace Pwa/Component/ProductSetProductCard/Component/ProductSetProductCard */
export class ProductSetProductCard extends SourceProductCard {
    renderPicture(mix = {}, isWishlistArrangementItem) {
        const {
            product: { id, name, alternative_hover_image, recommandation_image, image },
            thumbnail,
            extended,
            isArrangement,
        } = this.props;
        const { url: alternativeHoverImageUrl, path: alternativeHoverImagePath } = alternative_hover_image || {};
        const { url: recommendationImageUrl, path: recommendationImagePath } = recommandation_image || {};

        const thumbnailImg = isArrangement ? image?.url : thumbnail;

        this.sharedComponent = (
            <Image
                imageRef={this.imageRef}
                src={extended && recommendationImagePath !== NO_SELECTION ? recommendationImageUrl : thumbnailImg}
                alt={name}
                ratio="custom"
                mix={{ block: 'ProductCard', elem: 'Picture', mix }}
                isPlaceholder={!id}
                extended={extended}
            />
        );
        if (
            !isWishlistArrangementItem &&
            !isArrangement &&
            (!extended || (extended && alternativeHoverImagePath !== NO_SELECTION))
        ) {
            this.hoverImage = (
                <Image
                    src={alternativeHoverImageUrl}
                    alt={name}
                    ratio="custom"
                    mix={{ block: 'ProductCard', elem: 'PictureHover', mix }}
                    isPlaceholder={!id}
                />
            );
        }

        return (
            <>
                {this.sharedComponent}
                {alternativeHoverImagePath !== NO_SELECTION && this.hoverImage}
                <img style={{ display: 'none' }} alt={name} src={thumbnailImg} />
            </>
        );
    }

    renderProductSetCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return this.renderCardLinkWrapper(
            <div
                block="ProductCard"
                elem="ContentWrapper"
                mix={{ block: 'ProductSetProductCard', elem: 'ContentWrapper' }}
            >
                <div block="ProductCard" elem="FigureReview">
                    <figure block="ProductCard" elem="Figure">
                        {this.renderPicture()}
                        {this.renderArrangementDiscount()}
                        {this.renderArrangementLabelsAndTagsWrapper()}
                        {this.renderArrangementProductActions()}
                    </figure>
                </div>
                <div block="ProductCard" elem="Content">
                    {this.renderArrangementPrice()}
                    {this.renderProductName()}
                    {this.renderReviews()}
                </div>
                {this.renderArrangementVisibleOnHover()}
            </div>
        );
    }

    render() {
        const { children, mix, isLoading, layout } = this.props;

        return (
            <li block="ProductCard" mods={{ layout }} mix={mix}>
                <Loader isLoading={isLoading} />
                {this.renderProductSetCardContent()}
                <div block="ProductCard" elem="AdditionalContent">
                    {children}
                </div>
            </li>
        );
    }
}

export default ProductSetProductCard;
