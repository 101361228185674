/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { showNotification } from 'Store/Notification/Notification.action';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';

import BlogPostsQuery from '../../query/BlogPosts.query';
import BlogRecentPosts from './BlogRecentPosts.component';

/** @namespace Domneo/Blog/Component/BlogRecentPosts/Container/mapStateToProps */
// eslint-disable-next-line no-unused-vars
export const mapStateToProps = (state) => ({});

/** @namespace Domneo/Blog/Component/BlogRecentPosts/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, message) => dispatch(showNotification(type, message)),
});

/** @namespace Domneo/Blog/Component/BlogRecentPosts/Container/BlogRecentPostsContainer */
export class BlogRecentPostsContainer extends PureComponent {
    componentDidMount() {
        this._isMounted = true;
        this.requestPosts();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    __construct(props) {
        super.__construct(props);
        this.options = {
            recentPosts: {
                pageSize: 5,
                sort: 'DESC',
                getMedia: true,
            },
        };

        this.state = {
            isLoaded: false,
            posts: [],
        };
    }

    /**
     * Prepares the blog posts query with specified options
     * @returns {[[*]]}
     */
    getQuery() {
        const { recentPosts } = this.options;

        return [BlogPostsQuery.getQuery(recentPosts)];
    }

    requestPosts() {
        executeGet(prepareQuery(this.getQuery()), 'RecentPosts')
            .then(
                /** @namespace Domneo/Blog/Component/BlogRecentPosts/Container/executeGet/then */
                ({ posts: { items } }) => this.updatePostList(items, true)
            )
            .catch(
                /** @namespace Domneo/Blog/Component/BlogRecentPosts/Container/executeGet/then/catch */
                (err) => {
                    this.updatePostList([], false);
                    showNotification('error', err);
                }
            );
    }

    /**
     * Maps posts to state and sets isLoaded status
     * @param posts
     * @param isLoaded
     */
    updatePostList(posts, isLoaded) {
        if (!this._isMounted) {
            return;
        }

        this.setState({
            isLoaded,
            posts,
        });
    }

    render() {
        const { posts, isLoaded } = this.state;

        return <BlogRecentPosts posts={posts} isLoaded={isLoaded} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogRecentPostsContainer);
