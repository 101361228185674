import SourceCountdownComponent from '@domneo/slider/src/components/Countdown/Countdown.component';

import './Countdown.override.style';

/** @namespace Pwa/@Domneo/Slider/Components/Countdown/Component/CountdownComponent */
export class CountdownComponent extends SourceCountdownComponent {
    renderTitle() {
        return (
            <div block="Countdown" elem="Title">
                {__('Offer ends in')}:
            </div>
        );
    }

    render() {
        const { expired } = this.props;

        if (expired) {
            return this.renderFinish();
        }

        return (
            <div block="Countdown">
                {this.renderTitle()}
                {this.renderContent()}
            </div>
        );
    }
}

export default CountdownComponent;
