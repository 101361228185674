/** @namespace Pwa/Util/Sentry/Error/GraphqlError */
// eslint-disable-next-line max-classes-per-file
export class GraphqlError extends Error {
    __construct(message) {
        super.__construct(message);
        this.name = 'GraphQLError';
    }
}

/** @namespace Pwa/Util/Sentry/Error/AuthorizationError */
// eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-one-class
export class AuthorizationError extends Error {
    __construct(message) {
        super.__construct(message);
        this.name = 'AuthorizationError';
    }
}
