import { Link as RouterLink } from 'react-router-dom';
import { stringify } from 'rebem-classname';

import { Link as SourceLink } from 'SourceComponent/Link/Link.component';

/** @namespace Pwa/Component/Link/Component/Link */
export class Link extends SourceLink {
    render() {
        const { className, bemProps, children, to, isOpenInNewTab, ...props } = this.props;

        if (!to) {
            return (
                <div {...props} {...bemProps}>
                    {children}
                </div>
            );
        }

        if (/^#/.test(to)) {
            return this.renderRelativePathLink();
        }

        const classNameConverted = `${className} ${stringify(bemProps)}`;

        if (/^https?:\/\//.test(to)) {
            return this.renderAbsolutePathLink(classNameConverted);
        }

        const newTab = isOpenInNewTab ? '_blank' : '_self';

        return (
            <RouterLink
                {...props}
                to={to}
                target={newTab}
                // eslint-disable-next-line react/forbid-component-props
                className={classNameConverted}
            >
                {children}
            </RouterLink>
        );
    }
}

export default Link;
