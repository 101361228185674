export * from 'SourceStore/Cart/Cart.action';

export const GUEST_QUOTE_ID = 'guest_quote_id';
export const INACTIVE_COUPON_CODE = 'INACTIVE_COUPON_CODE';
export const PURCHASED_CART_TOTALS = 'PURCHASED_CART_TOTALS';

/** @namespace Pwa/Store/Cart/Action/applyInactiveCouponToCart */
export const applyInactiveCouponToCart = (couponCode) => ({
    type: INACTIVE_COUPON_CODE,
    couponCode,
});

/** @namespace Pwa/Store/Cart/Action/savePurchasedCartTotals */
export const savePurchasedCartTotals = () => ({
    type: PURCHASED_CART_TOTALS,
});
