import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request';

import PromotionalBannerQuery from '../../query/PromotionalBanner.query';
import { updatePromotionalBanners } from './PromotionalBanner.action';

/** @namespace Domneo/Promotionalbanner/Store/PromotionalBanner/Dispatcher/PromotionalBannerDispatcher */
export class PromotionalBannerDispatcher {
    getPromotionalBanners(category_id, dispatch) {
        return executeGet(prepareQuery(PromotionalBannerQuery.getPromotionalBannersQuery(category_id), true)).then(
            /** @namespace Domneo/Promotionalbanner/Store/PromotionalBanner/Dispatcher/executeGet/then */
            (response) => dispatch(updatePromotionalBanners(response?.banners?.items)),
            /** @namespace Domneo/Promotionalbanner/Store/PromotionalBanner/Dispatcher/executeGet/then */
            (error) => console.error('Error fetching promotional banners', error[0]?.message, error[0]?.debugMessage)
        );
    }
}

export default new PromotionalBannerDispatcher();
